@media (max-width: 480px){
  .zimbra-client_action-menu-item_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_action-menu-item_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_action-menu-item_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_action-menu-item_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu-item_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu-item_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_action-menu-item_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_action-menu-item_item{
  position:relative;
  display:flex;
  padding:var(--spacing-sm) var(--spacing-xl);
  line-height:1.3;
  font-family:var(--font-family-base);
  border:none;
  min-width:150px;
}
.zimbra-client_action-menu-item_item .zimbra-client_action-menu-item_icon{
  position:absolute;
  left:var(--spacing-xs);
}
.zimbra-client_action-menu-item_item.zimbra-client_action-menu-item_narrow{
  padding-left:var(--spacing-md);
  padding-right:var(--spacing-md);
}
.zimbra-client_action-menu-item_item.zimbra-client_action-menu-item_disabled:hover{
  background:transparent;
  border:none;
  cursor:default;
}
.zimbra-client_action-menu-item_item:not(.zimbra-client_action-menu-item_disabled):focus,
.zimbra-client_action-menu-item_item:not(.zimbra-client_action-menu-item_disabled):hover{
  border:none;
  background:var(--gray-lightest);
}
