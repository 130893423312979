@media (max-width: 480px){
  .zimbra-client_offline-label_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_offline-label_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_offline-label_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_offline-label_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_offline-label_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_offline-label_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_offline-label_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_offline-label_offline{
  position:absolute;
  top:50%;
  right:0;
  transform:translateY(-50%);
}
@media (max-width: 1024px){
  .zimbra-client_offline-label_offline{
    position:fixed;
    top:auto;
    right:10px;
    bottom:10px;
    z-index:1001;
  }
}
.zimbra-client_offline-label_offline .zimbra-client_offline-label_spinner{
  border-top-color:var(--brand-primary-500);
  border-right-color:var(--brand-primary-100);
  border-bottom-color:var(--brand-primary-200);
  font-size:var(--icon-size-md);
  vertical-align:middle;
}
.zimbra-client_offline-label_offlineBadge{
  color:var(--gray-darker);
  padding:var(--spacing-xs) var(--spacing-sm);
  background:var(--brand-warning-500);
  border:1px solid var(--body-bg);
  border-radius:15px;
  margin:0 10px;
}
