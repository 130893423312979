.zimbra-client_notifications_notifications{
  position:fixed;
  top:65px;
  left:50%;
  transform:translateX(-50%);
  background-color:var(--gray-darkest);
  color:white;
  box-shadow:0 2px 2px rgba(0, 0, 0, 0.24);
  text-align:left;
  border-radius:2px;
  display:flex;
  justify-content:space-between;
  border-left:solid 4px transparent;
  font-weight:500;
  border-color:var(--toast-success-color);
}
.zimbra-client_notifications_notifications.zimbra-client_notifications_warning{
  border-color:var(--toast-warning-color);
}
.zimbra-client_notifications_notifications.zimbra-client_notifications_failure{
  border-color:var(--toast-failure-color);
}
.zimbra-client_notifications_label{
  padding:var(--spacing-md) var(--spacing-lg);
}
.zimbra-client_notifications_undoButton{
  color:#f8e71c;
  background-color:transparent;
  border:none;
  font-weight:500;
  font-family:var(--branding-font-family);
  margin-left:48px;
  padding:var(--spacing-md) var(--spacing-lg);
  text-decoration:none;
  text-transform:uppercase;
}
.zimbra-client_notifications_undoButton:hover,
.zimbra-client_notifications_undoButton:focus{
  background-color:transparent !important;
  text-decoration:underline;
}
