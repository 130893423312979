@media (max-width: 480px){
  .zimbra-client_menu-item_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_menu-item_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_menu-item_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_menu-item_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_menu-item_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_menu-item_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_menu-item_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_menu-item_navItem{
  font-family:var(--branding-font-family-regular);
  display:flex;
  align-items:center;
  position:relative;
  box-sizing:border-box;
  padding:var(--spacing-sm) var(--spacing-md);
  text-decoration:none;
  color:var(--text-color);
  white-space:nowrap;
  -webkit-appearance:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer;
  overflow:hidden;
  border-bottom:4px solid transparent;
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_icon{
  position:relative;
  display:inline-block;
  line-height:1;
  font-size:var(--font-size-med);
  color:var(--gray-dark);
  margin-right:var(--spacing-sm);
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_icon path{
  fill:var(--brand-primary-500);
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_icon .fa{
  font-size:100%;
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_icon .blocks_icon{
  position:relative;
  top:-2px;
  display:inline-block;
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_icon .zimbra-client_menu-item_iconText{
  position:absolute;
  display:block;
  left:0;
  top:0;
  width:100%;
  text-align:center;
  font-size:62%;
  font-weight:bold;
  line-height:29px;
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_iconDisabled{
  opacity:0.5;
  cursor:default;
}
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_iconRight > .zimbra-client_menu-item_inner{
  margin-right:10px;
}
.zimbra-client_menu-item_navItem .zimbra-client_menu-item_inner{
  padding:0;
  font-weight:inherit;
  display:flex;
  align-items:center;
  width:100%;
}
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_sidebarEnable{
  border-left:4px solid transparent;
  border-bottom:none;
}
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_sidebarEnable:hover,
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_sidebarEnable:focus,
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_sidebarEnable.zimbra-client_menu-item_active{
  text-decoration:none;
  border-left-color:var(--brand-primary-500);
}
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_sidebarEnable.zimbra-client_menu-item_active{
  border-bottom:none;
}
a[href].zimbra-client_menu-item_navItem:hover,
a[href].zimbra-client_menu-item_navItem:focus,
a[href].zimbra-client_menu-item_navItem.zimbra-client_menu-item_active{
  color:var(--gray-base);
  text-decoration:none;
  border-bottom-color:var(--brand-primary-500);
}
a[href].zimbra-client_menu-item_navItem:hover .zimbra-client_menu-item_icon,
a[href].zimbra-client_menu-item_navItem:focus .zimbra-client_menu-item_icon,
a[href].zimbra-client_menu-item_navItem.zimbra-client_menu-item_active .zimbra-client_menu-item_icon{
  color:var(--brand-primary-500);
}
@media (max-width: 1024px){
  a[href].zimbra-client_menu-item_navItem{
    font-weight:700;
  }
  a[href].zimbra-client_menu-item_navItem:hover,
  a[href].zimbra-client_menu-item_navItem:focus,
  a[href].zimbra-client_menu-item_navItem.zimbra-client_menu-item_active{
    color:var(--brand-primary-500);
    border-bottom-color:transparent;
  }
}
.zimbra-client_menu-item_navItem.zimbra-client_menu-item_disabled{
  color:var(--gray-light);
  cursor:default;
}
