@media (max-width: 480px){
  .zimbra-client_settings_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_settings_settings{
  display:flex;
  height:100%;
  width:100%;
  flex-direction:column;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings{
    flex-direction:unset;
  }
}
.zimbra-client_settings_settings ul{
  list-style:none;
}
.zimbra-client_settings_settings input:required{
  box-shadow:none;
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebar{
  width:100%;
  padding:20px 0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_sidebar{
    width:300px;
    border-right:1px solid var(--gray-lighter);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebar.zimbra-client_settings_activePanel{
  display:none;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_sidebar.zimbra-client_settings_activePanel{
    display:block;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_datePickerWrapper{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}
.zimbra-client_settings_settings .zimbra-client_settings_datePickerWrapper .zimbra-client_settings_dateSelector{
  margin-right:var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_datePickerWrapper .zimbra-client_settings_dateSelector{
    margin-bottom:var(--spacing-sm);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_endDate{
  margin-top:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_alignLabel{
  display:block;
  align-self:baseline;
  padding-top:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_vactionSubField{
  max-width:574px;
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_vacationResponseTextArea{
  max-width:574px;
  width:100%;
  height:158px;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_settings_settings .zimbra-client_settings_vacationExternalMessageArea{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_buttonNoMargin{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_buttonNoMargin{
    margin-bottom:var(--spacing-md);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_buttonWithMargin{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
  margin-top:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_buttonWithMargin{
    margin-bottom:var(--spacing-md);
    margin-top:var(--spacing-md);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu{
  display:block;
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu .zimbra-client_settings_sidebarItem{
  display:block;
  padding:6px var(--spacing-lg);
  margin-bottom:var(--spacing-xs);
  cursor:pointer;
  color:var(--text-color);
  border-left:4px solid transparent;
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu .zimbra-client_settings_sidebarItem:last-child{
  margin-bottom:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu .zimbra-client_settings_sidebarItem.zimbra-client_settings_active{
  font-weight:bold;
  color:var(--gray-darkest);
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu .zimbra-client_settings_sidebarItem:focus,
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu .zimbra-client_settings_sidebarItem:hover{
  border-color:var(--brand-primary-500);
  text-decoration:none;
  outline:none;
}
.zimbra-client_settings_settings .zimbra-client_settings_sidebarMenu .zimbra-client_settings_sidebarItem.zimbra-client_settings_hide{
  display:none;
}
.zimbra-client_settings_settings .zimbra-client_settings_settingsWrapper{
  position:relative;
  display:none;
  height:100%;
  width:100%;
  overflow:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
  padding:20px;
}
.zimbra-client_settings_settings .zimbra-client_settings_settingsWrapper .zimbra-client_settings_error{
  margin:0 -20px var(--spacing-md);
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  padding:var(--spacing-sm) var(--spacing-lg);
  font-size:var(--font-size-base);
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_settingsWrapper{
    width:100%;
    display:block;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_settingsWrapper.zimbra-client_settings_activePanel{
  display:unset;
}
.zimbra-client_settings_settings .zimbra-client_settings_marginBottomMd{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsection{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsection{
    margin-bottom:var(--spacing-md);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_marginBottomLg{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsection{
  display:flex;
  font-size:var(--font-size-base);
  color:var(--text-color);
  flex-direction:column;
  align-items:baseline;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsection{
    flex-direction:unset;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_subsection:last-child{
  margin-bottom:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsection.zimbra-client_settings_compact{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle{
  min-width:220px;
  width:220px;
  margin-bottom:calc(var(--spacing-sm) + 2px);
  font-weight:500;
  padding:2px var(--spacing-md) 0 0;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle.zimbra-client_settings_verticallyCenteredLabel{
  display:flex;
  align-items:center;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle.zimbra-client_settings_forSelect{
  line-height:unset;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle.zimbra-client_settings_dateExample{
  width:100%;
  line-height:33px;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle{
    margin-bottom:0;
    font-weight:unset;
  }
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle.zimbra-client_settings_forSelect{
    padding-top:6px;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionTitle.zimbra-client_settings_plainTextSetting{
    width:auto;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersSubsectionTitle{
  min-width:210px;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_filtersSubsectionTitle{
    width:210px;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_sectionTitle{
  font-size:20px;
  color:var(--gray-darkest);
  padding-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_sectionTitle{
    font-size:var(--font-size-large);
    padding-bottom:calc(var(--spacing-sm) + 2px);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBodyOOO .zimbra-client_settings_list li{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody{
  flex-grow:1;
  max-width:375px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_half{
  width:150px;
  display:inline-block;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_half.zimbra-client_settings_inline{
  display:inline-block;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_half:first-child{
  margin-right:6px;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_half{
    width:184px;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_processAdditional{
  display:inline-block;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_emailAlarmAddrInput{
  width:100%;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody .zimbra-client_settings_emailAlarmAddrInput{
    width:260px;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection{
  max-width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_emailListContainer,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_emailListContainer{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList{
  position:relative;
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList:hover,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList:hover{
  padding-right:28px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList .zimbra-client_settings_emailAddress,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList .zimbra-client_settings_emailAddress{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_mailForwardAddress:hover span.zimbra-client_settings_closeForwardEmailAddress,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_mailForwardAddress:hover span.zimbra-client_settings_closeForwardEmailAddress{
  top:5px;
  right:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_description,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_description{
  font-size:13px;
  color:var(--gray-darkest);
  font-weight:normal;
  margin-top:8px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_description.zimbra-client_settings_fromDescription,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_description.zimbra-client_settings_fromDescription{
  margin-top:0;
  margin-bottom:16px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_alignedForm,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_alignedForm,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_formGroup,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_formGroup{
  margin-bottom:16px;
  align-items:baseline;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_formGroup p,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_formGroup p{
  width:100%;
  margin:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_formGroup p.zimbra-client_settings_marginTopMd,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_formGroup p.zimbra-client_settings_marginTopMd{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_personaName,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_personaName{
  max-width:375px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_comboWithAddBtn,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_comboWithAddBtn{
  width:100%;
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_comboWithAddBtn button,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_comboWithAddBtn button{
  margin:0 5px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_optionalInput,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_optionalInput{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_sectionHeading,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_sectionHeading{
  margin-bottom:0px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_expandedSubsection,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_expandedSubsection{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_expandedSubsection .zimbra-client_settings_subsectionBody,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_expandedSubsection .zimbra-client_settings_subsectionBody{
  max-width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_optionalLabel,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_optionalLabel{
  margin-right:20px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_optionalLabel.zimbra-client_settings_multiline,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_optionalLabel.zimbra-client_settings_multiline{
  line-height:normal;
}
@media (max-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_optionalLabel,
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_optionalLabel{
    margin-bottom:2px;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_folderList,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_folderList{
  width:100%;
  border:1px solid var(--gray-lighter);
  max-height:250px;
  overflow-y:auto;
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_comboTextSelect,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_comboTextSelect{
  display:flex;
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_comboTextSelect .zimbra-client_settings_optionalInput,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_comboTextSelect .zimbra-client_settings_optionalInput{
  padding-left:40px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_comboTextSelect .zimbra-client_settings_selectContainer,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_comboTextSelect .zimbra-client_settings_selectContainer{
  position:absolute;
  width:33px;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_comboTextSelect .zimbra-client_settings_selectContainer select,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_comboTextSelect .zimbra-client_settings_selectContainer select{
  width:0px;
  border:none;
  border-right:1px solid var(--gray-lighter);
  border-radius:unset;
  padding-right:21px;
}
@media (max-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_optionalInput,
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_optionalInput,
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_expandedSubsection,
  .zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_expandedSubsection{
    width:100%;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_personaSubsection .zimbra-client_settings_externalServerSettings,
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBody.zimbra-client_settings_editSubsection .zimbra-client_settings_externalServerSettings{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBodyButton{
  margin-top:0;
  margin-bottom:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBodyButton[disabled]{
  cursor:default;
}
.zimbra-client_settings_settings .zimbra-client_settings_subsectionBodyButton:first-child{
  margin-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_compactCheckboxSection{
  margin-bottom:var(--spacing-xs);
  display:flex;
  align-items:center;
}
.zimbra-client_settings_settings .zimbra-client_settings_compactCheckboxSection input{
  flex-shrink:0;
}
.zimbra-client_settings_settings ul.zimbra-client_settings_addressListDirectionBottom{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings ul.zimbra-client_settings_addressListDirectionBottom li:last-child{
  margin-bottom:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_label{
  display:flex;
  align-items:center;
}
.zimbra-client_settings_settings .zimbra-client_settings_label .zimbra-client_settings_labelText{
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_list{
  margin:0;
  padding-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_list li{
  margin-bottom:calc(var(--spacing-md) - 2px);
}
.zimbra-client_settings_settings .zimbra-client_settings_list li:last-child{
  margin-bottom:0;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_list li{
    margin-bottom:calc(var(--spacing-xs) + 2px);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_list.zimbra-client_settings_folderDownloadSection{
  padding-bottom:var(--spacing-md);
  margin-bottom:var(--spacing-md);
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect{
  padding:var(--spacing-sm) var(--spacing-xs);
  width:184px;
  border:solid 1px var(--border-color);
  border-radius:3px;
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect > ul{
  max-width:250px;
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOption{
  text-align:left;
  text-transform:none;
  background:var(--body-bg);
  color:var(--gray-base);
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOption:hover,
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOption:focus{
  background:var(--gray-lightest);
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOption:not(:hover){
  background:transparent;
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOption > div[class*="itemTitle"]{
  float:left;
  padding-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOptionItem{
  display:flex;
  align-items:center;
  font-weight:normal;
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOptionItem .zimbra-client_settings_calendarColor{
  margin-right:var(--spacing-sm);
  width:var(--icon-size-sm);
  height:var(--spacing-md);
  border:2px solid var(--body-bg);
}
.zimbra-client_settings_settings .zimbra-client_settings_calendarSelect .zimbra-client_settings_calendarOptionItem .zimbra-client_settings_calendarText{
  white-space:nowrap;
  overflow:hidden;
  text-transform:none;
  text-overflow:ellipsis;
  color:var(--gray-darkest);
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_toggleButtonClass{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_toggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_settings_settings .zimbra-client_settings_toggleButtonClass p{
  overflow:hidden;
  width:calc(100% - 10px);
}
.zimbra-client_settings_settings .zimbra-client_settings_richTextPreview{
  position:relative;
  margin-top:var(--spacing-sm);
  background-color:var(--gray-lightest);
  border:solid 1px var(--gray-lighter);
  min-height:80px;
}
.zimbra-client_settings_settings .zimbra-client_settings_richTextPreview span{
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
.zimbra-client_settings_settings .zimbra-client_settings_inputGroup{
  position:relative;
  display:flex;
  margin-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_settings_settings .zimbra-client_settings_inputGroup button{
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  white-space:nowrap;
}
.zimbra-client_settings_settings .zimbra-client_settings_textInput{
  flex-grow:1;
  padding:5px 12px 6px;
  height:var(--spacing-xl);
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  background-color:var(--body-bg);
  border:solid 1px var(--gray-lighter);
}
.zimbra-client_settings_settings .zimbra-client_settings_textInput::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_settings_settings .zimbra-client_settings_textInput:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_settings_settings .zimbra-client_settings_textInput::placeholder,
.zimbra-client_settings_settings .zimbra-client_settings_textInput[disabled]{
  color:var(--placeholder-color);
}
.zimbra-client_settings_settings .zimbra-client_settings_textInput[disabled]{
  background-color:var(--gray-lightest);
}
.zimbra-client_settings_settings .zimbra-client_settings_textInput.zimbra-client_settings_block{
  display:block;
  margin-top:6px;
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_helperText{
  font-size:var(--font-size-base);
  color:var(--gray-light);
  padding-top:3px;
}
.zimbra-client_settings_settings .zimbra-client_settings_helperText.zimbra-client_settings_helperTextDark{
  color:var(--text-color);
}
.zimbra-client_settings_settings .zimbra-client_settings_emailAddressList{
  padding-left:0;
  height:240px;
  margin:var(--spacing-sm) 0 12px;
  background-color:var(--body-bg);
  border:1px solid #ccc;
  border-radius:3px;
  overflow-y:auto;
}
.zimbra-client_settings_settings .zimbra-client_settings_emailAddressListEntry{
  overflow-x:hidden;
  word-wrap:break-word;
  width:100%;
  padding:7px 11px;
  cursor:pointer;
  position:relative;
}
.zimbra-client_settings_settings .zimbra-client_settings_emailAddressListEntry:hover{
  background-color:var(--gray-lightest);
}
.zimbra-client_settings_settings .zimbra-client_settings_emailAddressListEntry.zimbra-client_settings_selected{
  background-color:var(--brand-primary-50);
}
.zimbra-client_settings_settings .zimbra-client_settings_disabledElement{
  color:var(--placeholder-color);
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission{
  position:relative;
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_subsectionBody{
  min-width:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_emailListContainer{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList{
  position:relative;
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList:hover{
  padding-right:28px;
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_emailListContainer .zimbra-client_settings_emailList .zimbra-client_settings_emailAddress{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_mailForwardAddress:hover span.zimbra-client_settings_closeForwardEmailAddress{
  top:5px;
  right:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_inputGroup{
  position:relative;
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_freeBusyPermission .zimbra-client_settings_inputGroup .zimbra-client_settings_suggestionContainer{
  position:absolute;
  bottom:var(--spacing-xl);
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList{
  margin-top:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry{
  display:flex;
  flex-wrap:wrap;
  word-wrap:break-word;
  width:100%;
  overflow:hidden;
  border-top:1px solid var(--gray-lighter);
  background-color:var(--body-bg);
  transition:all 100ms linear;
  color:var(--gray-darkest);
  font-size:var(--font-size-base);
  padding-left:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_controls{
  display:flex;
  align-items:center;
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_controls .zimbra-client_settings_filterName{
  padding-left:var(--spacing-xs);
  font-weight:500;
  text-align:left;
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_controls .zimbra-client_settings_actionButton{
  height:calc(var(--spacing-xl) + var(--spacing-sm));
  padding:0;
  margin-right:var(--spacing-xs);
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_controls .zimbra-client_settings_dragHandle{
  cursor:-webkit-grab;
  cursor:grab;
  display:inline-block;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_controls .zimbra-client_settings_dragHandle:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails{
  padding-bottom:10px;
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails .zimbra-client_settings_filterProgressInfo{
  margin-bottom:10px;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails .zimbra-client_settings_filterProgressInfo .zimbra-client_settings_progressBar{
  min-width:calc(100% - 150px);
  height:var(--spacing-sm);
  border-radius:var(--spacing-xs);
  background-color:var(--gray-lighter);
  display:inline-block;
  margin-right:var(--spacing-sm);
  vertical-align:middle;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails .zimbra-client_settings_filterProgressInfo .zimbra-client_settings_progressBar .zimbra-client_settings_progressIndicator{
  height:var(--spacing-sm);
  border-radius:var(--spacing-xs);
  background-color:var(--brand-success-500);
  display:inline-block;
  vertical-align:top;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails .zimbra-client_settings_filterProgressInfo .zimbra-client_settings_progressValue{
  font-size:var(--font-size-small);
  color:var(--gray-light);
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails .zimbra-client_settings_filterProgressInfo .zimbra-client_settings_progressValue .zimbra-client_settings_cancelRun{
  margin-left:2px;
  cursor:pointer;
  color:var(--gray-light);
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_filtersListEntry .zimbra-client_settings_filterDetails .zimbra-client_settings_filterProgressInfo .zimbra-client_settings_progressValue .zimbra-client_settings_runSuccessIndicator{
  color:var(--brand-success-500);
  margin-right:3px;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .zimbra-client_settings_dummyDropZone{
  border-top:1px solid var(--gray-lighter);
  transition:all 100ms linear;
  height:var(--spacing-xl);
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .dragOver{
  border-top:4px solid #CCC;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersList .draggedItem{
  opacity:0.5;
}
.zimbra-client_settings_settings .zimbra-client_settings_unsupportedFilterRule{
  color:var(--brand-danger-500);
}
.zimbra-client_settings_settings .zimbra-client_settings_filterList{
  list-style-type:disc;
  padding-left:var(--spacing-xl);
}
.zimbra-client_settings_settings .zimbra-client_settings_filterList li{
  padding-left:12px;
  word-wrap:break-word;
}
.zimbra-client_settings_settings .zimbra-client_settings_ifThenText{
  margin:var(--spacing-sm) 0;
}
.zimbra-client_settings_settings .zimbra-client_settings_filterProcessLabel{
  margin:var(--spacing-xs) 0;
}
.zimbra-client_settings_settings .zimbra-client_settings_filterProcessLabel .zimbra-client_settings_processAdditional{
  text-transform:lowercase;
}
.zimbra-client_settings_settings .zimbra-client_settings_arrow{
  font-size:var(--icon-size-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_arrow:hover{
  cursor:pointer;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersControls{
  float:right;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersControls div{
  display:inline-block;
}
.zimbra-client_settings_settings .zimbra-client_settings_filtersControls:first-child{
  margin-right:6px;
}
.zimbra-client_settings_settings .zimbra-client_settings_flexContainer{
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_flexContainer .zimbra-client_settings_select{
  background-position:316px;
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox{
  display:flex;
  align-items:center;
  padding:var(--spacing-xs) var(--spacing-lg) var(--spacing-xs) 0;
  transition:all 200ms ease-in-out;
  cursor:pointer;
  position:relative;
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox .zimbra-client_settings_rightArrow{
  position:absolute;
  right:5px;
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox .zimbra-client_settings_mailAccountContent{
  text-align:left;
  padding:0;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox .zimbra-client_settings_mailAccountName{
  margin-right:var(--spacing-xs);
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox .zimbra-client_settings_mailAccountNote{
  color:var(--gray-light);
  margin-left:var(--spacing-xs);
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox .zimbra-client_settings_leftLogo{
  display:flex;
  align-items:center;
  flex-shrink:0;
  width:var(--spacing-xl);
  height:var(--spacing-xl);
  color:var(--brand-primary);
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountBox .zimbra-client_settings_leftLogoIcon{
  font-size:22px;
}
.zimbra-client_settings_settings .zimbra-client_settings_mailAccountFailing{
  padding-right:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountsList{
  padding:0;
  margin:0 0 10px 0;
  border-top:solid 1px var(--gray-lighter);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountsList .zimbra-client_settings_mailAccountItem{
  border-bottom:solid 1px var(--gray-lighter);
}
.zimbra-client_settings_settings .zimbra-client_settings_twoFactorAuthSetup{
  margin-top:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_twoFactorAuthSetup .zimbra-client_settings_enableTwoFactorAuthBtn,
.zimbra-client_settings_settings .zimbra-client_settings_twoFactorAuthSetup .zimbra-client_settings_disableTwoFactorAuthBtn{
  margin:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_addAccountSubsection,
.zimbra-client_settings_settings .zimbra-client_settings_addAccountLabel,
.zimbra-client_settings_settings .zimbra-client_settings_editAccountTitle,
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection,
.zimbra-client_settings_settings .zimbra-client_settings_primaryAccountSection,
.zimbra-client_settings_settings .zimbra-client_settings_twoFactorAuthSetup{
  padding-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_settings_settings .zimbra-client_settings_exportSection{
  margin-bottom:var(--spacing-xl);
}
.zimbra-client_settings_settings .zimbra-client_settings_exportSection p{
  margin:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_exportSection .zimbra-client_settings_exportButton{
  margin:var(--spacing-md) 0 0 0;
}
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection p{
  margin:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection .zimbra-client_settings_lineBreak,
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection .zimbra-client_settings_buttons{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection .zimbra-client_settings_buttons span{
  margin-left:6px;
}
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection .zimbra-client_settings_buttons.zimbra-client_settings_fileChooseBtn{
  background-color:#e4e4e4;
}
.zimbra-client_settings_settings .zimbra-client_settings_importPSTSection .zimbra-client_settings_buttons button{
  max-width:114px;
  min-width:114px;
  font-size:var(--font-size-base);
  padding:var(--spacing-sm) 11px;
  margin-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_mobileConfigSection ul{
  margin:0 var(--spacing-md);
  padding:0;
  list-style-type:disc;
}
.zimbra-client_settings_settings .zimbra-client_settings_mobileConfigSection p{
  margin:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_mobileConfigSection .zimbra-client_settings_optionText{
  margin:var(--spacing-lg) 0;
}
.zimbra-client_settings_settings .zimbra-client_settings_mobileConfigSection .zimbra-client_settings_optionText p{
  margin-bottom:11px;
}
.zimbra-client_settings_settings .zimbra-client_settings_mobileConfigSection .zimbra-client_settings_button{
  margin-top:var(--spacing-lg);
}
.zimbra-client_settings_settings .zimbra-client_settings_mobileConfigSection .zimbra-client_settings_buttonspan{
  margin-left:7px;
}
.zimbra-client_settings_settings .zimbra-client_settings_addAccountLabel{
  display:block;
  font-size:var(--font-size-large);
  text-align:left;
  color:var(--gray-darkest);
  padding-bottom:20px;
}
.zimbra-client_settings_settings .zimbra-client_settings_addAccountInput{
  display:block;
  width:100%;
  font-size:var(--font-size-base);
}
.zimbra-client_settings_settings .zimbra-client_settings_inlineList li{
  display:inline-block;
  margin-right:15px;
  line-height:32px;
}
.zimbra-client_settings_settings .zimbra-client_settings_infoInput{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_infoLabel{
  min-width:130px;
  width:130px;
  line-height:normal;
  margin-bottom:calc(var(--spacing-xs) + 3px);
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_infoLabel{
    line-height:32px;
    margin-bottom:0;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_portInput{
  width:90px;
  flex-grow:0;
  flex-shrink:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_rightHint{
  padding-left:12px;
}
@media (max-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_rightHint{
    padding:0 var(--spacing-sm);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_plainButton{
  margin-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_editAccountButton{
  margin:0 0 20px 0;
}
.zimbra-client_settings_settings .zimbra-client_settings_groupToggle{
  left:10px;
  top:7px;
  cursor:pointer;
  padding-right:var(--spacing-xs);
  z-index:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_groupToggleTextContainer{
  display:flex;
  font-size:var(--font-size-base);
  text-align:left;
  color:var(--brand-tertiary-500);
}
.zimbra-client_settings_settings .zimbra-client_settings_groupToggleTextContainer .zimbra-client_settings_groupToggleText{
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_groupToggleIcon{
  position:relative;
  line-height:15px;
  height:15px;
  width:15px;
  font-size:var(--font-size-base);
  color:var(--brand-tertiary-500);
}
.zimbra-client_settings_settings .zimbra-client_settings_additionalInfoContainer{
  display:block;
}
.zimbra-client_settings_settings .zimbra-client_settings_checkboxList li{
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_checkboxList .zimbra-client_settings_togglePort{
  flex:2;
  padding-top:5px;
}
.zimbra-client_settings_settings .zimbra-client_settings_checkboxList .zimbra-client_settings_togglePortInput{
  flex:1;
}
.zimbra-client_settings_settings .zimbra-client_settings_checkboxList .zimbra-client_settings_togglePortHint{
  flex:2;
  font-size:var(--font-size-base);
  text-align:left;
  color:var(--gray-light);
  margin:0;
  padding-top:5px;
  padding-left:12px;
}
.zimbra-client_settings_settings .zimbra-client_settings_confirmationMessage{
  font-size:var(--font-size-h3);
  margin-bottom:var(--spacing-xs);
  text-align:left;
  font-weight:300;
  color:var(--gray-darkest);
}
.zimbra-client_settings_settings .zimbra-client_settings_confirmationSpan{
  font-size:var(--font-size-base);
  text-align:left;
  color:var(--gray-darker);
  margin-top:0;
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_normalSelect{
  font-size:small;
}
@media (max-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_dateInput label{
    text-align:left;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_signatureSection{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_settings_settings .zimbra-client_settings_signatureSection label{
  display:block;
}
.zimbra-client_settings_settings .zimbra-client_settings_signatureSection .zimbra-client_settings_signatureBox{
  height:200px;
  display:flex;
  flex-direction:column;
  flex:1;
  border:1px solid var(--gray-lighter);
  padding-bottom:1px;
}
@media (min-width: 768px){
  .zimbra-client_settings_settings .zimbra-client_settings_signatureSection .zimbra-client_settings_signatureBox .tox.tox-tinymce{
    border-top:1px solid var(--gray-lighter);
  }
  .zimbra-client_settings_settings .zimbra-client_settings_signatureSection .zimbra-client_settings_signatureBox .tinymce-mobile-editor-socket{
    padding:var(--spacing-xs);
    border:1px solid var(--gray-lighter);
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_sectionTitle{
  font-size:var(--font-size-h3);
  font-weight:var(--headings-font-weight);
  padding-bottom:calc(var(--spacing-lg) - 2);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection{
  flex-direction:column;
  margin-bottom:var(--spacing-xl);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionTitle{
  font-size:var(--font-size-h4);
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_helperText{
  padding:var(--spacing-sm) 0 calc(var(--spacing-lg) - 4);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody{
  max-width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList .zimbra-client_settings_certificate{
  display:flex;
  align-items:center;
  margin:0;
  padding:calc(var(--spacing-sm) - 1) 0 var(--spacing-sm);
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList .zimbra-client_settings_certificate:last-child{
  border-bottom:1px solid var(--gray-lighter);
  padding-bottom:calc(var(--spacing-sm) - 1);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList .zimbra-client_settings_certificate .zimbra-client_settings_shieldIcon{
  width:var(--icon-size-md);
  height:var(--icon-size-md);
  margin-right:var(--spacing-sm);
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList .zimbra-client_settings_certificate .zimbra-client_settings_certAccount{
  flex-grow:1;
  padding-left:3px;
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList .zimbra-client_settings_certificate .zimbra-client_settings_viewCert{
  color:var(--brand-tertiary-500);
  cursor:pointer;
  padding-right:3px;
}
.zimbra-client_settings_settings .zimbra-client_settings_smimeSection .zimbra-client_settings_subsection .zimbra-client_settings_subsectionBody .zimbra-client_settings_smimeCertificatesList .zimbra-client_settings_certificate .zimbra-client_settings_viewCertError{
  color:var(--brand-danger-500);
  padding-right:3px;
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection .zimbra-client_settings_subsection{
  flex-wrap:wrap;
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection p{
  margin:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection .zimbra-client_settings_alignedRadioButton{
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection .zimbra-client_settings_alignedRadioButton:not(:last-child){
  margin-bottom:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection .zimbra-client_settings_alignedRadioButton .zimbra-client_settings_radioButtonWrapper{
  flex:1 0 auto;
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection .zimbra-client_settings_alignedRadioButton label{
  padding-top:1px;
}
.zimbra-client_settings_settings .zimbra-client_settings_offlineModeSection .zimbra-client_settings_bold{
  font-weight:700;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailAndStatus{
  max-width:474px;
  display:flex;
  flex-direction:row;
  margin-right:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailAndStatus .zimbra-client_settings_pending{
  color:var(--brand-danger-500);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailAndStatus .zimbra-client_settings_verified{
  color:var(--brand-success-500);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailAndStatus .zimbra-client_settings_recoveryEmail{
  flex:1 0 85%;
  font-size:var(--font-size-med);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailAndStatus .zimbra-client_settings_status{
  flex:1 0 15%;
  text-align:right;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailAndStatus .zimbra-client_settings_statusIcon{
  margin-right:5px;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inputError:focus{
  outline-color:var(--brand-danger-500);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inlineModalTitle{
  color:var(--gray-darkest);
  font-size:var(--font-size-large);
  font-weight:normal;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inlineModalWrapper{
  border-radius:5px;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inlineModalWrapper .zimbra-client_settings_inlineError{
  margin:0 calc(-1 * var(--spacing-lg)) var(--spacing-md);
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  padding:var(--spacing-sm) var(--spacing-lg);
  font-size:var(--font-size-base);
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inlineModalWrapper .zimbra-client_settings_inlineError.zimbra-client_settings_success{
  color:var(--brand-success-500);
  background-color:var(--brand-success-50);
}
@media (max-width: 769px){
  .zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inlineModalWrapper .zimbra-client_settings_inlineModal{
    height:200px;
  }
  .zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_inlineModalWrapper .zimbra-client_settings_inlineModalToolbar{
    position:absolute;
  }
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_verificationMessage{
  margin-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_noLeftSpace{
  padding-left:0;
  margin-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_noLeftMargin{
  margin-left:0;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_actionSection .zimbra-client_settings_modifyButtons{
  width:100%;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_emailInput{
  margin-bottom:var(--spacing-md);
  width:50%;
  min-width:250px;
}
.zimbra-client_settings_settings .zimbra-client_settings_accountRecovery .zimbra-client_settings_codeInput{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_settings_settings .zimbra-client_settings_zimletList li{
  border-bottom:1px solid var(--gray-lighter);
  display:block;
  color:var(--gray-darker);
  padding:var(--spacing-sm) 0;
  font-size:var(--font-size-base);
}
.zimbra-client_settings_settings .zimbra-client_settings_zimletList li:first-child{
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_settings_settings .zimbra-client_settings_zimletList li .zimbra-client_settings_radioButtonWrapper{
  display:flex;
}
.zimbra-client_settings_settings .zimbra-client_settings_zimletList li label{
  padding-top:1px;
}
.zimbra-client_settings_settings .zimbra-client_settings_zimletList li label .zimbra-client_settings_description{
  color:var(--gray);
}
.zimbra-client_settings_editAccountTitle{
  font-size:var(--font-size-h3);
  font-weight:300;
  -webkit-font-smoothing:subpixel-antialiased;
}
.zimbra-client_settings_testingDataSourceContainer{
  padding-top:100px;
  text-align:center;
}
.zimbra-client_settings_testingDataSourceSection{
  text-align:center;
  margin-bottom:12px;
}
.zimbra-client_settings_testingDataSourceSection .zimbra-client_settings_check{
  display:inline-block;
}
.zimbra-client_settings_spinner{
  font-size:27px;
}
.zimbra-client_settings_animatedCheckmark{
  height:32px;
}
.zimbra-client_settings_successMessage,
.zimbra-client_settings_errorMessage,
.zimbra-client_settings_helpTooltipTitle,
.zimbra-client_settings_textButtonBold{
  font-weight:bold;
}
.zimbra-client_settings_successMessage{
  color:var(--brand-success-500);
}
.zimbra-client_settings_warningIcon,
.zimbra-client_settings_errorMessage{
  color:var(--brand-danger-500);
}
.zimbra-client_settings_errorMessage{
  word-break:break-word;
}
.zimbra-client_settings_downloadFolderPlaceholder{
  color:var(--gray-placeholder);
}
.zimbra-client_settings_downloadFolderName{
  display:inline-block;
  margin-left:var(--spacing-xs);
}
.zimbra-client_settings_largeLabel{
  margin-bottom:var(--spacing-xs);
}
.zimbra-client_settings_inlineRadioButton{
  margin-right:var(--spacing-lg);
}
.zimbra-client_settings_inputWithRightHint{
  display:flex;
  flex-direction:row;
  align-items:center;
  flex:auto;
}
.zimbra-client_settings_inputWithRightHint .zimbra-client_settings_changePortLabel{
  padding-right:12px;
}
.zimbra-client_settings_advancedToggle{
  color:var(--brand-tertiary-500);
  cursor:pointer;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.zimbra-client_settings_externalPassword{
  display:block;
  width:100%;
  padding:10px;
  font-size:100%;
  border:1px solid var(--gray-lighter);
  border-radius:2px;
  outline:none;
}
.zimbra-client_settings_w100{
  width:100%;
}
.zimbra-client_settings_w100 .zimbra-client_settings_toggleFolder{
  padding-bottom:var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_settings_w100 .zimbra-client_settings_toggleFolder{
    padding-top:var(--spacing-sm);
  }
}
.zimbra-client_settings_defaultCalTooltip,
.zimbra-client_settings_defaultGeneralTooltip{
  width:275px;
}
@media (max-width: 480px){
  .zimbra-client_settings_defaultCalTooltip,
  .zimbra-client_settings_defaultGeneralTooltip{
    margin-left:-150px;
  }
}
@media (max-width: 360px){
  .zimbra-client_settings_defaultCalTooltip,
  .zimbra-client_settings_defaultGeneralTooltip{
    margin-left:-200px;
  }
}
.zimbra-client_settings_danger{
  color:var(--brand-danger-500);
}
.zimbra-client_settings_warning{
  color:var(--brand-warning-500);
}
.zimbra-client_settings_success{
  color:var(--brand-success-500);
}
.zimbra-client_settings_disabledText{
  color:var(--gray);
}
.zimbra-client_settings_notYetImplemented{
  display:none !important;
}
.zimbra-client_settings_signatureTitle{
  font-size:var(--font-size-large);
  padding-bottom:18px;
}
.zimbra-client_settings_blockSection div.zimbra-client_settings_addressErrorMessage{
  padding:7px 20px;
  margin:5px -20px 3px -20px;
}
.zimbra-client_settings_blockOrAllowTitle{
  font-size:var(--font-size-large);
  padding-bottom:7px;
}
.zimbra-client_settings_settingsSection{
  margin-top:20px;
}
.zimbra-client_settings_blockOrAllowSubSectionTitle{
  margin-top:10px;
}
.zimbra-client_settings_twoFactorAuthDescription{
  margin:0 0 10px 0;
}
.zimbra-client_settings_wrapTwoFactorAuthInfo{
  padding-top:var(--spacing-md);
}
.zimbra-client_settings_wrapTwoFactorAuthInfo .zimbra-client_settings_subsection{
  margin-bottom:var(--spacing-md);
}
@media (min-width: 1300px){
  .zimbra-client_settings_wrapTwoFactorAuthInfo .zimbra-client_settings_subsectionBody{
    margin-left:var(--spacing-md);
  }
}
.zimbra-client_settings_wrapTwoFactorAuthInfo .zimbra-client_settings_subsectionBody .zimbra-client_settings_nakedButton:not(:disabled){
  color:var(--brand-primary-500);
}
.zimbra-client_settings_wrapTwoFactorAuthInfo .zimbra-client_settings_subsectionBody .zimbra-client_settings_trustedDevicesActionContainer{
  margin-top:var(--spacing-sm);
}
.zimbra-client_settings_oneTimeCodeDialog{
  width:260px;
  height:354px;
}
.zimbra-client_settings_oneTimeCodeDialog .zimbra-client_settings_hideContent{
  display:none;
}
.zimbra-client_settings_loadingDialogContent div[class*="inner"]{
  background-color:var(--gray-lightest);
}
.zimbra-client_settings_oneTimeCodeContainer{
  width:100%;
  margin:var(--spacing-md) 0 0;
  display:flex;
  flex-wrap:wrap;
}
.zimbra-client_settings_oneTimeCodeContainer .zimbra-client_settings_oneTimeCode{
  width:50%;
  margin:var(--spacing-sm) 0;
  color:var(--gray-darker);
  font-weight:bold;
}
.zimbra-client_settings_copyToclipboard{
  margin:var(--spacing-sm) 0;
}
.zimbra-client_settings_copyToclipboard button{
  color:var(--gray-dark);
}
.zimbra-client_settings_generalSection select{
  min-width:196px;
  width:100%;
}
.zimbra-client_settings_generalSection .zimbra-client_settings_usageBar{
  background:var(--gray-lightest);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_usageBar .zimbra-client_settings_usage > div.zimbra-client_settings_animationBar{
  position:relative;
  width:100%;
  height:var(--spacing-xl);
  line-height:var(--spacing-xl);
  -webkit-animation-name:zimbra-client_settings_progress;
          animation-name:zimbra-client_settings_progress;
  -webkit-animation-duration:1.5s;
          animation-duration:1.5s;
}
.zimbra-client_settings_generalSection .zimbra-client_settings_usageBar .zimbra-client_settings_usage span{
  white-space:pre;
  min-width:var(--spacing-lg);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_usageBar .zimbra-client_settings_usage span.zimbra-client_settings_textWhite{
  color:#fff;
  position:absolute;
  right:var(--spacing-sm);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_usageBar .zimbra-client_settings_usage span:not(.zimbra-client_settings_textWhite){
  position:absolute;
  right:calc(-1 * var(--spacing-xl));
}
.zimbra-client_settings_generalSection .zimbra-client_settings_defaultMailAppEnabled{
  opacity:50%;
}
.zimbra-client_settings_generalSection .zimbra-client_settings_defaultMailAppNote{
  margin-top:var(--spacing-sm);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_autoArchiveSettings{
  margin-top:var(--spacing-md);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_autoArchiveSettings .zimbra-client_settings_note{
  margin-bottom:var(--spacing-md);
  padding:2px var(--spacing-md) 0 0;
}
.zimbra-client_settings_generalSection .zimbra-client_settings_autoArchiveSettings .zimbra-client_settings_note:first-child{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_autoArchiveSettings .zimbra-client_settings_frequencyInputContainer{
  margin-top:var(--spacing-sm);
}
.zimbra-client_settings_generalSection .zimbra-client_settings_autoArchiveSettings .zimbra-client_settings_frequencyInputContainer .zimbra-client_settings_frequencyInput{
  width:72px;
  margin:0 var(--spacing-xs);
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_infoSection{
  padding:var(--spacing-sm) 0;
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_alignSectionLabel{
  padding-top:7px;
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_syncInfo{
  padding:var(--spacing-sm) 0;
  position:relative;
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_syncInfo.zimbra-client_settings_success{
  color:var(--brand-success-500);
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_syncInfo.zimbra-client_settings_danger{
  color:var(--brand-danger-500);
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_syncInfo .zimbra-client_settings_syncStatusIcon{
  margin-right:7px;
}
.zimbra-client_settings_offlineDesktopSection .zimbra-client_settings_buttonNoMargin{
  margin:0;
}
.zimbra-client_settings_composerDefaultSection{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_settings_composerDefaultSection{
    margin-bottom:var(--spacing-md);
  }
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_composerDefaultValues{
  padding:12px;
  margin-top:var(--spacing-sm);
  border:1px solid var(--gray-lighter);
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_textColorpicker{
  margin-left:var(--spacing-sm);
  position:relative;
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_textColorpicker .zimbra-client_settings_colorPicker{
  opacity:0;
  position:absolute;
  width:90px;
  cursor:pointer;
  left:0;
  top:0;
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_textColorpicker .zimbra-client_settings_dropDownIcon{
  margin:0 var(--spacing-sm);
  color:var(--gray-base);
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton{
  margin-right:var(--spacing-sm);
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton li.zimbra-client_settings_dropdownOption{
  text-align:left;
  background:none;
  color:var(--gray-darker);
  padding:var(--spacing-sm) var(--spacing-md);
  font-weight:normal;
  text-transform:none;
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton li.zimbra-client_settings_dropdownOption:hover,
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton li.zimbra-client_settings_dropdownOption:focus{
  background:var(--gray-lightest);
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton li.zimbra-client_settings_dropdownOption > div[class*="itemTitle"]{
  float:none;
  padding-left:var(--spacing-lg);
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton .zimbra-client_settings_toggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton .zimbra-client_settings_toggleButtonClass p{
  font-weight:normal;
  color:var(--gray-darker);
  text-transform:none;
}
@media (max-width: 1024px){
  .zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton .zimbra-client_settings_toggleButtonClass p{
    padding-left:0;
    margin-top:10px;
  }
}
.zimbra-client_settings_composerDefaultSection .zimbra-client_settings_selectButton .zimbra-client_settings_toggleButtonClass span[role="img"]{
  vertical-align:middle;
  font-size:var(--icon-size-xs);
}
.zimbra-client_settings_sharing{
  width:100%;
}
.zimbra-client_settings_sharing .zimbra-client_settings_shareType{
  font-size:var(--font-size-large);
  color:var(--gray-darkest);
  padding-bottom:var(--spacing-md);
}
.zimbra-client_settings_sharing .zimbra-client_settings_shareType:not(:first-child){
  margin-top:var(--spacing-xl);
}
.zimbra-client_settings_sharing .zimbra-client_settings_shareInfo{
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_settings_sharing .zimbra-client_settings_shareInfo .zimbra-client_settings_sharingItem{
  border-top:1px solid var(--gray-lighter);
  padding:var(--spacing-sm) 0 var(--spacing-sm) 12px;
  color:var(--gray-darkest);
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing .zimbra-client_settings_shareInfo .zimbra-client_settings_sharingItem{
    padding-left:0;
  }
}
.zimbra-client_settings_sharing .zimbra-client_settings_nothingToShow{
  color:var(--gray);
}
@-webkit-keyframes zimbra-client_settings_progress{
  0%{
    width:0%;
  }
  33%{
    width:0%;
  }
  100%{
    width:100%;
  }
}
@keyframes zimbra-client_settings_progress{
  0%{
    width:0%;
  }
  33%{
    width:0%;
  }
  100%{
    width:100%;
  }
}
.zimbra-client_settings_domainAddressListError{
  margin-top:0;
}
