@media (max-width: 480px){
  .zimbra-client_action-menu-filter_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_action-menu-filter_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_action-menu-filter_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_action-menu-filter_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu-filter_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu-filter_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_action-menu-filter_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_action-menu-filter_actionMenuFilter{
  padding:8px 35px;
  border-bottom:1px solid var(--gray-lighter);
  display:flex;
  align-items:center;
}
.zimbra-client_action-menu-filter_actionMenuFilter.zimbra-client_action-menu-filter_withBack{
  padding-left:0;
}
.zimbra-client_action-menu-filter_input{
  flex:1;
  border:0;
  outline:none;
  line-height:2;
}
.zimbra-client_action-menu-filter_input::-moz-placeholder{
  color:var(--gray-placeholder);
}
.zimbra-client_action-menu-filter_input:-ms-input-placeholder{
  color:var(--gray-placeholder);
}
.zimbra-client_action-menu-filter_input::placeholder,
.zimbra-client_action-menu-filter_icon{
  color:var(--gray-placeholder);
}
.zimbra-client_action-menu-filter_icon{
  margin-right:6px;
}
.zimbra-client_action-menu-filter_backButton,
.zimbra-client_action-menu-filter_clear{
  color:var(--gray);
}
.zimbra-client_action-menu-filter_backButton{
  width:35px;
  height:24px;
  font-size:var(--font-size-med);
}
