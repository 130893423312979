@media (max-width: 480px){
  .zimbra-client_toolbar_sidebar-button_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_toolbar_sidebar-button_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_toolbar_sidebar-button_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_toolbar_sidebar-button_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_toolbar_sidebar-button_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_toolbar_sidebar-button_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_toolbar_sidebar-button_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_toolbar_sidebar-button_actionButton{
  margin:10px var(--spacing-xs);
  flex-shrink:0;
  width:36px;
  height:36px;
  padding:0;
}
