@media (max-width: 480px){
  .zimbra-client_collapsible-control_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_collapsible-control_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_collapsible-control_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_collapsible-control_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_collapsible-control_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_collapsible-control_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_collapsible-control_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_collapsible-control_collapsibleControl{
  font-size:var(--font-size-med);
  height:18px;
  line-height:1;
  margin-right:4px;
  padding-left:4px;
  cursor:pointer;
  transition:100ms all ease-in;
}
.zimbra-client_collapsible-control_collapsibleControl.zimbra-client_collapsible-control_open{
  transform:rotate(90deg);
}
