@media (max-width: 480px){
  .zimbra-client_address-field_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_address-field_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_address-field_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_address-field_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_address-field_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_address-field_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_address-field_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_address-field_addressField{
  display:flex;
  position:relative;
  overflow:visible;
  padding:10px 0 10px;
  align-items:baseline;
}
.zimbra-client_address-field_addressField button.zimbra-client_address-field_label{
  margin:var(--spacing-xs) 0 0;
  font-weight:inherit;
  font-style:inherit;
  font-family:inherit;
}
.zimbra-client_address-field_addressField button.zimbra-client_address-field_label:focus{
  color:var(--link-color);
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_input{
  width:100%;
  padding:0 12px;
  border:none;
  border-radius:0;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_input.zimbra-client_address-field_formSize{
  padding-bottom:1px;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_inputField{
  line-height:18px;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_tokenMidSpace{
  padding:0 2px;
  cursor:text;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token{
  max-width:100%;
  position:relative;
  overflow:visible;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_insertionPoint{
  padding-left:1px;
  border-left:black solid 2px;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel{
  padding:2px 2px;
  margin:0 1px 1px 0;
  border-radius:3px;
  background:none;
  border:none;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  display:inline-block;
  max-width:100%;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel button{
  padding:0;
  line-height:18px;
  text-align:left;
  max-width:100%;
  overflow:hidden;
  text-overflow:ellipsis;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel button::after{
  content:',';
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel:hover{
  background:var(--link-color);
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel:hover button,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel:hover .zimbra-client_address-field_smimePubCertShieldIcon{
  color:white;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_tokenLabel:hover button::after{
  visibility:hidden;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid button{
  color:var(--brand-danger-500);
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel{
  background:var(--brand-danger-500);
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel button,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel button,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel .zimbra-client_address-field_smimePubCertShieldIcon,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_invalid.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel .zimbra-client_address-field_smimePubCertShieldIcon{
  color:white;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel{
  background:var(--gray);
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel button,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel button,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel .zimbra-client_address-field_smimePubCertShieldIcon,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel .zimbra-client_address-field_smimePubCertShieldIcon{
  color:white;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_selected .zimbra-client_address-field_tokenLabel button::after,
.zimbra-client_address-field_addressField .zimbra-client_address-field_token.zimbra-client_address-field_active .zimbra-client_address-field_tokenLabel button::after{
  visibility:hidden;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_smimePubCertShieldIcon{
  color:var(--gray-base);
  padding-right:4px;
  transform:translateY(-2px);
  display:inline-block;
}
.zimbra-client_address-field_addressField .zimbra-client_address-field_token .zimbra-client_address-field_smimePubCertShieldIcon.zimbra-client_address-field_expiredCert{
  color:var(--brand-danger-500);
}
.zimbra-client_address-field_suggestions{
  position:absolute;
  left:0;
  top:100%;
  min-width:300px;
  max-width:100%;
  background:#fff;
  border:1px solid var(--gray-lighter);
  z-index:999;
}
.zimbra-client_address-field_suggestions.zimbra-client_address-field_up{
  position:relative;
}
@media (max-width: calc(480px - 1px)){
  .zimbra-client_address-field_addressField{
    position:static;
    position:initial;
  }
  .zimbra-client_address-field_addressField .zimbra-client_address-field_suggestions{
    left:-16px;
    right:-16px;
    max-width:none;
    max-width:initial;
  }
}
