button{
  cursor:pointer;
  background:none;
  border:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
a[role='button']{
  display:inline-block;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
a[role='button']:hover,
a[role='button']:focus{
  text-decoration:none;
}
.blocks_button_button{
  border:solid 1px transparent;
  border-radius:3px;
  font-size:100%;
  line-height:1;
  margin:5px;
  background-color:var(--gray-lightest);
  color:var(--text-color);
  box-shadow:0 0 0 0 rgba(0, 0, 0, 0);
  transition:all 0.2s ease;
}
.blocks_button_button:disabled{
  opacity:0.37;
  pointer-events:none;
}
.blocks_button_button .blocks_button_icon{
  display:inline-block;
  vertical-align:top;
}
.blocks_button_button .blocks_button_icon.blocks_button_left{
  margin-right:5px;
}
.blocks_button_button .blocks_button_icon.blocks_button_right{
  margin-left:5px;
}
.blocks_button_button:not(:disabled):hover{
  box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.37);
  cursor:pointer;
}
.blocks_button_button:not(:disabled):focus{
  box-shadow:none;
  cursor:pointer;
}
.blocks_button_button.blocks_button_iconOnly:hover,
.blocks_button_button.blocks_button_iconOnly:focus{
  box-shadow:none;
}
.blocks_button_button.blocks_button_primary{
  background-color:var(--link-color);
  color:var(--text-color-light);
}
.blocks_button_button.blocks_button_primary.blocks_button_brand-primary{
  background-color:var(--brand-primary-500);
}
.blocks_button_button.blocks_button_primary.blocks_button_brand-success{
  background-color:var(--brand-success-500);
}
.blocks_button_button.blocks_button_primary.blocks_button_brand-info{
  background-color:var(--brand-info-500);
}
.blocks_button_button.blocks_button_primary.blocks_button_brand-warning{
  background-color:var(--brand-warning-500);
}
.blocks_button_button.blocks_button_primary.blocks_button_brand-danger{
  background-color:var(--brand-danger-500);
}
.blocks_button_button.blocks_button_primary:not(:disabled):hover,
.blocks_button_button.blocks_button_primary:not(:disabled):focus{
  color:var(--text-color-light);
}
.blocks_button_button.blocks_button_secondary{
  background-color:transparent;
  border-color:var(--link-color);
  color:var(--link-color);
}
.blocks_button_button.blocks_button_secondary.blocks_button_brand-primary{
  color:var(--brand-primary-500);
  border-color:var(--brand-primary-500);
}
.blocks_button_button.blocks_button_secondary.blocks_button_brand-success{
  color:var(--brand-success-500);
  border-color:var(--brand-success-500);
}
.blocks_button_button.blocks_button_secondary.blocks_button_brand-info{
  color:var(--brand-info-500);
  border-color:var(--brand-info-500);
}
.blocks_button_button.blocks_button_secondary.blocks_button_brand-warning{
  color:var(--brand-warning-500);
  border-color:var(--brand-warning-500);
}
.blocks_button_button.blocks_button_secondary.blocks_button_brand-danger{
  color:var(--brand-danger-500);
  border-color:var(--brand-danger-500);
}
.blocks_button_button.blocks_button_secondary:not(:disabled):hover,
.blocks_button_button.blocks_button_secondary:not(:disabled):focus{
  background-color:var(--link-color);
  color:var(--text-color-light);
}
.blocks_button_button.blocks_button_secondary:not(:disabled):hover.blocks_button_brand-primary,
.blocks_button_button.blocks_button_secondary:not(:disabled):focus.blocks_button_brand-primary{
  background-color:var(--brand-primary-500);
}
.blocks_button_button.blocks_button_secondary:not(:disabled):hover.blocks_button_brand-success,
.blocks_button_button.blocks_button_secondary:not(:disabled):focus.blocks_button_brand-success{
  background-color:var(--brand-success-500);
}
.blocks_button_button.blocks_button_secondary:not(:disabled):hover.blocks_button_brand-info,
.blocks_button_button.blocks_button_secondary:not(:disabled):focus.blocks_button_brand-info{
  background-color:var(--brand-info-500);
}
.blocks_button_button.blocks_button_secondary:not(:disabled):hover.blocks_button_brand-warning,
.blocks_button_button.blocks_button_secondary:not(:disabled):focus.blocks_button_brand-warning{
  background-color:var(--brand-warning-500);
}
.blocks_button_button.blocks_button_secondary:not(:disabled):hover.blocks_button_brand-danger,
.blocks_button_button.blocks_button_secondary:not(:disabled):focus.blocks_button_brand-danger{
  background-color:var(--brand-danger-500);
}
.blocks_button_button.blocks_button_floating{
  background-color:transparent;
  border-color:transparent;
  color:var(--link-color);
}
.blocks_button_button.blocks_button_floating.blocks_button_brand-primary{
  color:var(--brand-primary-500);
}
.blocks_button_button.blocks_button_floating.blocks_button_brand-success{
  color:var(--brand-success-500);
}
.blocks_button_button.blocks_button_floating.blocks_button_brand-info{
  color:var(--brand-info-500);
}
.blocks_button_button.blocks_button_floating.blocks_button_brand-warning{
  color:var(--brand-warning-500);
}
.blocks_button_button.blocks_button_floating.blocks_button_brand-danger{
  color:var(--brand-danger-500);
}
.blocks_button_button.blocks_button_floating:not(:disabled):hover,
.blocks_button_button.blocks_button_floating:not(:disabled):focus{
  -webkit-text-decoration:var(--link-hover-decoration);
          text-decoration:var(--link-hover-decoration);
  box-shadow:none;
}
.blocks_button_button.blocks_button_floating:not(:disabled):hover .blocks_button_icon,
.blocks_button_button.blocks_button_floating:not(:disabled):focus .blocks_button_icon{
  text-decoration:none;
}
.blocks_button_button.blocks_button_tertiary{
  background-color:var(--body-bg);
  color:var(--gray-darker);
  border-color:var(--body-bg);
}
.blocks_button_button.blocks_button_tertiary:not(:disabled):hover,
.blocks_button_button.blocks_button_tertiary:not(:disabled):focus{
  background-color:var(--gray-lighter);
  border-color:var(--gray-lighter);
}
.blocks_button_button.blocks_button_text{
  border:0;
  background-color:inherit;
  color:var(--link-color);
}
.blocks_button_button.blocks_button_text:hover,
.blocks_button_button.blocks_button_text:focus{
  background-color:inherit;
  color:var(--link-hover-color);
  border:0;
  box-shadow:none;
  -webkit-text-decoration:var(--link-hover-decoration);
          text-decoration:var(--link-hover-decoration);
}
.blocks_button_button.blocks_button_coloredText{
  border:0;
  background-color:inherit;
  color:var(--link-color);
}
.blocks_button_button.blocks_button_coloredText.blocks_button_brand-primary{
  color:var(--brand-primary-500);
}
.blocks_button_button.blocks_button_coloredText.blocks_button_brand-success{
  color:var(--brand-success-500);
}
.blocks_button_button.blocks_button_coloredText.blocks_button_brand-info{
  color:var(--brand-info-500);
}
.blocks_button_button.blocks_button_coloredText.blocks_button_brand-warning{
  color:var(--brand-warning-500);
}
.blocks_button_button.blocks_button_coloredText.blocks_button_brand-danger{
  color:var(--brand-danger-500);
}
.blocks_button_button.blocks_button_coloredText:hover,
.blocks_button_button.blocks_button_coloredText:focus{
  background-color:inherit;
  color:inherit;
  border:0;
  box-shadow:none;
}
.blocks_button_button.blocks_button_alignLeft{
  margin-left:0;
}
.blocks_button_button.blocks_button_regular{
  padding:8px 23px;
}
.blocks_button_button.blocks_button_regular.blocks_button_floating{
  padding:8px;
}
.blocks_button_button.blocks_button_regular.blocks_button_text,
.blocks_button_button.blocks_button_regular.blocks_button_coloredText{
  padding:0;
}
.blocks_button_button.blocks_button_large{
  font-size:115%;
  padding:12px 44px;
}
.blocks_button_button.blocks_button_large.blocks_button_floating{
  padding:14px;
}
.blocks_button_button.blocks_button_large.blocks_button_text,
.blocks_button_button.blocks_button_large.blocks_button_coloredText{
  padding:0;
}
.blocks_button_button.blocks_button_small{
  font-size:85%;
  padding:var(--spacing-xs) 12px;
}
.blocks_button_button.blocks_button_small.blocks_button_floating{
  padding:var(--spacing-xs);
}
.blocks_button_button.blocks_button_small.blocks_button_text,
.blocks_button_button.blocks_button_small.blocks_button_coloredText{
  padding:0;
}
