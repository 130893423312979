@media (max-width: 480px){
  .zimbra-client_keyboard-shortcuts-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_keyboard-shortcuts-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_keyboard-shortcuts-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_keyboard-shortcuts-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_keyboard-shortcuts-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_keyboard-shortcuts-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_keyboard-shortcuts-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_keyboard-shortcuts-modal_context h2{
  text-transform:capitalize;
}
.zimbra-client_keyboard-shortcuts-modal_context .zimbra-client_keyboard-shortcuts-modal_commandListWrapper li{
  list-style:none;
}
.zimbra-client_keyboard-shortcuts-modal_context .zimbra-client_keyboard-shortcuts-modal_commandListWrapper li .zimbra-client_keyboard-shortcuts-modal_shortcut{
  font-weight:700;
  padding-right:10px;
}
