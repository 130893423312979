[zmEditorToolbar]{
  display:flex;
  align-items:center;
}
[zmEditorToolbar] [zmEditorToolbarLeft]:not(:empty){
  border-right:1px solid var(--gray-lighter);
  padding:var(--spacing-sm);
}
[zmEditorToolbar] [zmEditorToolbarRight]:not(:empty){
  border-left:1px solid var(--gray-lighter);
  padding:var(--spacing-sm);
}
[zmEditorToolbar] [zmEditorToolbarWrapper]{
  flex:1;
}
@media (min-width: 768px){
  [zmEditorToolbar] [zmEditorToolbarWrapper]{
    height:34px;
  }
}
@media (min-width: 768px) and (min-width: 1025px){
  [zmEditorToolbar] [zmEditorToolbarWrapper]{
    padding:0 var(--spacing-sm);
  }
}
@media (orientation: landscape) and (hover: none){
  html[class*=touch] [zmEditorToolbarWrapper]{
    height:auto;
    padding:0;
  }
}
