@media (max-width: 480px){
  .zimbra-client_about-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_about-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_about-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_about-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_about-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_about-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_about-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_about-modal_header{
  font-size:var(--font-size-h3);
  font-weight:var(--headings-font-weight);
  display:flex;
  justify-content:space-between;
  color:var(--gray-darkest);
  padding-bottom:var(--spacing-lg);
}
.zimbra-client_about-modal_inner{
  display:flex;
  flex-direction:column;
  max-width:448px;
  max-height:448px;
  border-radius:3px;
  background-color:var(--body-bg);
  box-shadow:0 4px 6px 0 rgba(0, 0, 0, 0.36);
  padding:var(--spacing-lg);
}
@media (max-width: 1024px){
  .zimbra-client_about-modal_inner{
    width:65vw;
    flex:1 0 auto;
  }
}
@media (max-width: 768px){
  .zimbra-client_about-modal_inner{
    width:95vw;
    flex:1 0 auto;
  }
}
.zimbra-client_about-modal_close{
  position:relative;
  top:-10px;
  right:-10px;
}
.zimbra-client_about-modal_contentWrapper{
  flex:1 1 auto;
  background-color:var(--body-bg);
  position:relative;
  display:flex;
  flex-direction:column;
  font-size:var(--font-size-base);
  color:var(--gray-darkest);
}
@media (max-width: 1024px){
  .zimbra-client_about-modal_contentWrapper{
    height:100%;
    min-width:auto;
    border-bottom:unset;
    border-top:unset;
  }
}
.zimbra-client_about-modal_contentWrapper .zimbra-client_about-modal_build > div{
  margin-bottom:var(--spacing-xs);
}
.zimbra-client_about-modal_contentWrapper .zimbra-client_about-modal_build .zimbra-client_about-modal_version{
  font-weight:bold;
  -webkit-user-select:all;
     -moz-user-select:all;
      -ms-user-select:all;
          user-select:all;
}
.zimbra-client_about-modal_contentWrapper .zimbra-client_about-modal_copyright{
  margin-top:var(--spacing-md);
  overflow:auto;
}
.zimbra-client_about-modal_contentWrapper .zimbra-client_about-modal_copyright .zimbra-client_about-modal_signature{
  margin-top:var(--spacing-md);
}
.zimbra-client_about-modal_contentWrapper .zimbra-client_about-modal_copyright .zimbra-client_about-modal_signature > div{
  flex-grow:1;
}
