@media (max-width: 480px){
  .zimbra-client_avatar_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_avatar_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_avatar_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_avatar_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_avatar_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_avatar_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_avatar_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_avatar_avatar{
  position:relative;
  background:var(--gray-dark);
  font-size:var(--font-size-med);
  border-radius:50%;
}
.zimbra-client_avatar_avatar .zimbra-client_avatar_initials,
.zimbra-client_avatar_avatar .zimbra-client_avatar_default{
  position:absolute;
  display:inline-block;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  color:var(--gray-lightest);
}
.zimbra-client_avatar_avatar .zimbra-client_avatar_default{
  font-size:100%;
}
.zimbra-client_avatar_avatar .zimbra-client_avatar_inner{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-size:cover;
  background-position:top center;
  background-repeat:no-repeat;
  border-radius:50%;
}
.zimbra-client_avatar_avatar.zimbra-client_avatar_contain .zimbra-client_avatar_inner{
  background-size:contain;
}
