@media (max-width: 480px){
  .zimbra-client_app-navigation_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_app-navigation_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_app-navigation_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_app-navigation_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_app-navigation_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_app-navigation_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_app-navigation_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_app-navigation_navList{
  list-style:none;
  display:flex;
  padding:var(--spacing-xs) var(--spacing-md);
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.zimbra-client_app-navigation_navList .zimbra-client_app-navigation_navListItem{
  padding:2px;
}
@media (max-width: 480px){
  .zimbra-client_app-navigation_navList .zimbra-client_app-navigation_navListItem{
    padding:var(--spacing-sm) 2px;
  }
}
.zimbra-client_app-navigation_navList .zimbra-client_app-navigation_navListItem .zimbra-client_app-navigation_link{
  color:var(--gray);
  text-decoration:none;
  position:relative;
  padding:var(--spacing-xs);
  width:34px;
  height:34px;
  display:block;
  border-radius:3px;
  border:1px solid transparent;
}
.zimbra-client_app-navigation_navList .zimbra-client_app-navigation_navListItem .zimbra-client_app-navigation_link .zimbra-client_app-navigation_iconText{
  position:absolute;
  display:block;
  left:0;
  top:7px;
  width:100%;
  text-align:center;
  font-size:10px;
  font-weight:bold;
  line-height:24px;
}
.zimbra-client_app-navigation_navList .zimbra-client_app-navigation_navListItem .zimbra-client_app-navigation_link:hover,
.zimbra-client_app-navigation_navList .zimbra-client_app-navigation_navListItem .zimbra-client_app-navigation_link.zimbra-client_app-navigation_active{
  color:var(--brand-primary-500);
  border-color:var(--border-color);
  background:#ffffff;
}
.zimbra-client_app-navigation_appMenu{
  width:100%;
  background-color:white;
  overflow:visible;
  border-bottom:solid 1px var(--border-color);
  position:relative;
}
@media (max-width: 1024px){
  .zimbra-client_app-navigation_appMenu{
    z-index:3;
    position:relative;
    border:none;
  }
}
.zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_nav{
  display:flex;
  flex-wrap:nowrap;
  align-items:stretch;
}
.zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_placeholderText{
  text-decoration:none;
  color:var(--gray-light);
  text-transform:lowercase;
}
.zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_placeholderText:hover{
  color:var(--link-hover-color);
  text-decoration:none;
}
.zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_mobileNav{
  position:relative;
  height:100%;
}
.zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_navMenuItem{
  display:flex;
  align-items:center;
  font-size:var(--font-size-base);
  font-weight:700;
  padding:var(--spacing-sm) var(--spacing-md);
  margin-top:var(--spacing-xs);
}
.zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_navMenuItem.zimbra-client_app-navigation_beta:after{
  content:"BETA";
  position:absolute;
  right:0;
  top:var(--spacing-xs);
  color:red;
  font-size:8px;
}
@media (max-width: 1024px){
  .zimbra-client_app-navigation_appMenu .zimbra-client_app-navigation_navMenuItem{
    padding:calc(var(--spacing-md) - var(--spacing-xs)) var(--spacing-md);
    margin-top:0;
    border-top:solid 1px var(--border-color);
  }
}
.zimbra-client_app-navigation_slot{
  display:flex;
  align-items:stretch;
  border:none;
}
.zimbra-client_app-navigation_slot > a{
  padding:12px var(--spacing-md) var(--spacing-sm);
}
.zimbra-client_app-navigation_slot > a:not(:last-child){
  box-shadow:1px 0 0 var(--gray-lighter);
  margin-right:1px;
}
.zimbra-client_app-navigation_slot:not(:empty){
  border-left:solid 1px var(--gray-lighter);
  border-right:solid 1px var(--gray-lighter);
}
.zimbra-client_app-navigation_slot:not(:empty) + .zimbra-client_app-navigation_slot{
  border-left:none;
}
.zimbra-client_app-navigation_progress{
  position:absolute;
  left:0;
  top:0;
  height:1px;
  width:100%;
  opacity:0;
  transform:scaleY(0);
  transform-origin:100% 100%;
  transition:all 150ms ease;
  z-index:10;
  contain:strict;
  overflow:hidden;
}
.zimbra-client_app-navigation_progress.zimbra-client_app-navigation_enabled{
  opacity:0.6;
  transform:scaleY(1);
  transition:opacity 150ms ease;
}
.zimbra-client_app-navigation_progress.zimbra-client_app-navigation_done{
  opacity:0 !important;
}
.zimbra-client_app-navigation_progress:not(.zimbra-client_app-navigation_enabled) .zimbra-client_app-navigation_inner{
  transition:none;
}
.zimbra-client_app-navigation_progress .zimbra-client_app-navigation_inner{
  position:absolute;
  top:0;
  left:-100%;
  height:100%;
  width:100%;
  background:var(--brand-tertiary-500);
  transition:transform 400ms ease;
}
.zimbra-client_app-navigation_tabMenuItemWrapper{
  display:flex;
  border-left:solid 1px var(--gray-lighter);
  border-right:solid 1px var(--gray-lighter);
  margin-left:-1px;
  width:142px;
}
.zimbra-client_app-navigation_tabs{
  display:flex;
  align-items:stretch;
  flex:auto;
  min-width:0;
}
a.zimbra-client_app-navigation_tabMenuItem:hover .zimbra-client_app-navigation_tabClose,
a.zimbra-client_app-navigation_tabDropdownMenuItem:hover .zimbra-client_app-navigation_tabClose{
  display:block;
}
a.zimbra-client_app-navigation_tabDropdownMenuItem{
  padding-right:var(--spacing-sm);
}
a.zimbra-client_app-navigation_tabDropdownMenuItem.zimbra-client_app-navigation_active{
  border-bottom:0;
}
.zimbra-client_app-navigation_defaultItem{
  display:flex;
  font-size:20px;
  padding:0 16px 0 16px;
  justify-content:space-between;
  align-items:center;
  height:calc(var(--toolbar-height) - 1px);
}
a.zimbra-client_app-navigation_tabMenuItem{
  flex:0 1 142px;
  padding:14px var(--spacing-md) var(--spacing-sm);
  height:100%;
  width:142px;
}
.zimbra-client_app-navigation_tab{
  display:flex;
  align-items:center;
  width:100%;
  height:100%;
  line-height:normal;
}
.zimbra-client_app-navigation_tabTitle{
  overflow:hidden;
  text-overflow:ellipsis;
  flex:auto;
  line-height:1.3;
}
.zimbra-client_app-navigation_tabClose{
  font-size:var(--font-size-large);
  outline:none;
  margin-left:2px;
  display:none;
}
.zimbra-client_app-navigation_tabClose:hover{
  display:block;
  color:var(--brand-primary-500);
}
.zimbra-client_app-navigation_tabClose,
.zimbra-client_app-navigation_tabsCloseAllIcon{
  font-size:var(--font-size-large);
  outline:none;
}
.zimbra-client_app-navigation_tabsCloseAllIcon{
  padding:0 var(--spacing-xs) 0 0;
  display:inline-block;
}
.zimbra-client_app-navigation_tabsDropdown .zimbra-client_app-navigation_tabsDropdownToggle{
  border-right:1px solid var(--gray-lighter);
  border-radius:0;
}
.zimbra-client_app-navigation_tabsDropdown .zimbra-client_app-navigation_tabsDropdownToggle button{
  padding:0 10px;
}
.zimbra-client_app-navigation_tabsDropdownPopover{
  max-width:250px;
}
.zimbra-client_app-navigation_dropdownTabList{
  max-height:345px;
  overflow:auto;
  padding:var(--spacing-sm);
}
.zimbra-client_app-navigation_sidebarNavWrapper{
  display:flex;
  flex-direction:column;
}
.zimbra-client_app-navigation_sidebarNavWrapper .zimbra-client_app-navigation_menuItemWrapper{
  position:relative;
}
.zimbra-client_app-navigation_sidebarNavWrapper .zimbra-client_app-navigation_contextMenuIcon{
  float:right;
  opacity:1;
  position:absolute;
  right:10px;
  top:20px;
}
.zimbra-client_app-navigation_sidebarNavWrapper .zimbra-client_app-navigation_contextMenuIcon:focus{
  opacity:1;
}
a[href].zimbra-client_app-navigation_tabLink{
  display:flex;
  align-items:center;
  padding:var(--spacing-sm) var(--spacing-md);
  color:var(--text-color);
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer;
  font-weight:700;
}
a[href].zimbra-client_app-navigation_tabLink:hover,
a[href].zimbra-client_app-navigation_tabLink:focus{
  text-decoration:none;
}
