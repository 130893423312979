.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_notificationModalContent{
  margin:0;
}
.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_notificationModalContent .zimbra-client_notification-modal_actions{
  padding-bottom:var(--spacing-lg);
}
.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_notificationModalContent .zimbra-client_notification-modal_actions button{
  margin-left:0;
  margin-right:20px;
}
.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_notificationModalContent .zimbra-client_notification-modal_actions button.zimbra-client_notification-modal_clipboardBtn{
  color:var(--gray-light);
}
.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_notificationModalContent.zimbra-client_notification-modal_showDetails{
  padding-top:var(--spacing-lg);
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_notificationModalContent.zimbra-client_notification-modal_showDetails .zimbra-client_notification-modal_actions{
  padding-bottom:0;
}
.zimbra-client_notification-modal_notificationModal .zimbra-client_notification-modal_message{
  padding:0 var(--spacing-lg);
  overflow:auto;
  word-break:break-word;
  margin:var(--spacing-md) calc(-1 * var(--spacing-lg)) 0;
  max-height:250px;
}
.zimbra-client_notification-modal_notificationModal footer{
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_notification-modal_notificationModal footer button{
  margin:0;
}
