.zimbra-client_attachment-badge_iconWrapper{
  width:80px;
  flex-shrink:0;
  display:flex;
  align-items:center;
  justify-content:center;
}
.zimbra-client_attachment-badge_iconWrapper .zimbra-client_attachment-badge_contentTypeIcon{
  font-size:25px;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  color:#FFF;
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_uploading{
  background-color:var(--gray-lighter);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_doc{
  background-color:var(--file-type-doc);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_ppt{
  background-color:var(--file-type-ppt);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_pdf{
  background-color:var(--file-type-pdf);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_xls{
  background-color:var(--file-type-xls);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_zip{
  background-color:var(--file-type-zip);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_media{
  background-color:var(--file-type-media);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_code{
  background-color:var(--file-type-code);
}
.zimbra-client_attachment-badge_iconWrapper.zimbra-client_attachment-badge_default{
  background-color:var(--file-type-default);
}
