@media (max-width: 480px){
  .zimbra-client_inline-modal-dialog_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_inline-modal-dialog_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_inline-modal-dialog_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_inline-modal-dialog_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_inline-modal-dialog_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_inline-modal-dialog_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_inline-modal-dialog_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_inline-modal-dialog_header,
.zimbra-client_inline-modal-dialog_footer{
  padding:14px 24px;
}
.zimbra-client_inline-modal-dialog_header{
  font-size:var(--font-size-h3);
  font-weight:300;
  color:var(--gray-darkest);
}
.zimbra-client_inline-modal-dialog_toolbar{
  position:fixed;
  height:var(--mobile-toolbar-height);
  display:flex;
  justify-content:flex-end;
}
.zimbra-client_inline-modal-dialog_close{
  position:absolute;
  top:10px;
  right:10px;
}
.zimbra-client_inline-modal-dialog_close:hover{
  cursor:pointer;
}
.zimbra-client_inline-modal-dialog_wrapper{
  display:block;
  position:fixed;
  left:0;
  top:0 !important;
  width:100%;
  height:100%;
  background:rgba(238, 238, 238, 0.8);
  z-index:100;
}
.zimbra-client_inline-modal-dialog_wrapper .zimbra-client_inline-modal-dialog_inner{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  border-radius:3px;
  background-color:#ffffff;
  box-shadow:0 4px 6px 0 rgba(0, 0, 0, 0.36);
  cursor:default;
  z-index:1;
}
.zimbra-client_inline-modal-dialog_wrapper .zimbra-client_inline-modal-dialog_contentWrapper{
  overflow-y:auto;
  background-color:#ffffff;
  padding:0 24px;
}
@media (max-width: 768px){
  .zimbra-client_inline-modal-dialog_wrapper{
    top:768px;
  }
}
@media (max-width: 480px){
  .zimbra-client_inline-modal-dialog_wrapper{
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
  }
  .zimbra-client_inline-modal-dialog_wrapper .zimbra-client_inline-modal-dialog_inner{
    position:relative;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:0;
    min-height:100%;
    border-radius:0;
    box-shadow:none;
    overflow:auto;
    -webkit-overflow-scrolling:none;
  }
}
.zimbra-client_inline-modal-dialog_noLeft{
  margin-left:0;
}
.zimbra-client_inline-modal-dialog_error{
  margin:0 0 12px;
  display:block;
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  padding:0 24px;
  line-height:40px;
  font-size:var(--font-size-base);
}
