@media (max-width: 480px){
  .zimbra-client_folder-select_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_folder-select_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_folder-select_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_folder-select_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder-select_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder-select_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_folder-select_hideMdUp{
    display:none !important;
  }
}
a.zimbra-client_folder-select_disableHover:not(.zimbra-client_folder-select_disabled):hover{
  background:none;
}
a.zimbra-client_folder-select_selected:not(.zimbra-client_folder-select_disabled),
a.zimbra-client_folder-select_selected:not(.zimbra-client_folder-select_disabled):hover{
  background:var(--brand-primary-50);
}
.zimbra-client_folder-select_folderGroup{
  overflow-y:auto;
  overflow-x:hidden;
  max-height:300px;
}
