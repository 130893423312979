@media (max-width: 480px){
  .zimbra-client_attachment-viewer_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_attachment-viewer_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_attachment-viewer_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_attachment-viewer_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_attachment-viewer_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_attachment-viewer_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_attachment-viewer_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_attachment-viewer_attachmentViewer{
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  overflow:visible;
  top:0;
  left:auto;
  transform:translate3d(100%, 0, 0);
  transition:all 400ms ease 20ms;
  background:var(--gray-dark);
  z-index:10;
  width:100%;
}
.zimbra-client_attachment-viewer_attachmentViewer:not(.zimbra-client_attachment-viewer_showing){
  width:0;
  overflow:hidden;
}
.zimbra-client_attachment-viewer_attachmentViewer.zimbra-client_attachment-viewer_showing{
  transform:translate3d(0, 0, 0);
}
.zimbra-client_attachment-viewer_controls{
  position:absolute;
  top:87.5%;
  left:50%;
  transform:translate(-50%, -50%);
  white-space:nowrap;
}
.zimbra-client_attachment-viewer_controls button[class]{
  background:var(--gray-base);
  border:none;
  opacity:0.7;
  padding:2px;
  width:var(--icon-size-lg);
  height:var(--icon-size-lg);
}
.zimbra-client_attachment-viewer_controls button[class]:nth-child(2){
  margin-right:0;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}
.zimbra-client_attachment-viewer_controls button[class]:nth-child(3){
  margin-left:0;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
}
.zimbra-client_attachment-viewer_toolbar{
  height:var(--toolbar-height);
  background:var(--read-pane-toolbar-color);
  padding:0 16px;
  color:var(--gray-base);
  display:flex;
}
@media (max-width: 1024px){
  .zimbra-client_attachment-viewer_toolbar{
    height:var(--mobile-toolbar-height);
  }
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_title{
  width:75%;
  font-size:var(--font-size-base);
  line-height:var(--toolbar-height);
  display:flex;
}
@media (max-width: 1024px){
  .zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_title{
    line-height:var(--mobile-toolbar-height);
  }
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_title .zimbra-client_attachment-viewer_filename{
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width:80%;
  overflow:hidden;
  font-weight:bold;
  margin-right:12px;
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_title .zimbra-client_attachment-viewer_pages{
  flex:1;
  font-weight:bold;
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_title .zimbra-client_attachment-viewer_pages > span{
  padding:1px 2px;
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_attachmentActions{
  text-align:right;
  right:0;
  top:0;
  flex:1;
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_attachmentActions .zimbra-client_attachment-viewer_actionButton{
  padding:7px 0;
  margin:5px 3px;
  vertical-align:middle;
}
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_attachmentActions .zimbra-client_attachment-viewer_actionButton:not(:disabled):focus,
.zimbra-client_attachment-viewer_toolbar .zimbra-client_attachment-viewer_attachmentActions .zimbra-client_attachment-viewer_actionButton:not(:disabled):hover{
  background:transparent;
}
.zimbra-client_attachment-viewer_inner{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  top:var(--toolbar-height);
  background:var(--gray-dark);
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
  overflow-x:auto;
}
@media (max-width: 1024px){
  .zimbra-client_attachment-viewer_inner{
    top:var(--mobile-toolbar-height);
  }
}
.zimbra-client_attachment-viewer_inner img{
  display:block;
  position:absolute;
  left:50%;
  top:50%;
  max-width:100%;
  max-height:100%;
  transform:translate(-50%, -50%);
}
.zimbra-client_attachment-viewer_inner img{
  transition:opacity 500ms ease;
  transform-origin:0 0;
}
.zimbra-client_attachment-viewer_inner .zimbra-client_attachment-viewer_hidden{
  transition:none;
  opacity:0;
}
.zimbra-client_attachment-viewer_inner .zimbra-client_attachment-viewer_textFileViewer{
  height:auto;
  width:auto;
  background:white;
  margin:10px 30px 10px 30px;
  padding:40px;
  white-space:pre-line;
  text-align:justify;
}
.zimbra-client_attachment-viewer_inner .zimbra-client_attachment-viewer_error{
  color:var(--gray-base);
  font-size:var(--font-size-h3);
  left:50%;
  position:absolute;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
}
@media (min-width: 1025px){
  .zimbra-client_attachment-viewer_attachmentViewer{
    width:calc(50% - calc(var(--sidebar-width)/2));
    top:calc(var(--toolbar-height) + var(--search-header-height));
  }
}
.zimbra-client_attachment-viewer_modal{
  width:100%;
  height:100%;
}
