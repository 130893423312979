@media (max-width: 480px){
  .zimbra-client_infinite-scroll_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_infinite-scroll_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_infinite-scroll_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_infinite-scroll_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_infinite-scroll_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_infinite-scroll_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_infinite-scroll_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_infinite-scroll_virtualList{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_infinite-scroll_virtualList.zimbra-client_infinite-scroll_isLoading{
  bottom:30px;
}
.zimbra-client_infinite-scroll_virtualList > div{
  margin:0 20px;
  max-width:calc(100% - 40px);
}
.zimbra-client_infinite-scroll_loading{
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  bottom:0;
}
