@media (max-width: 480px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_sharing-settings_grantee-list_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList{
  border-top:1px solid var(--gray-lighter);
  width:100%;
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList > .zimbra-client_settings_sharing-settings_grantee-list_expandedGrantee:last-child{
  border:none;
  padding-bottom:0;
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_grantee-list_granteeList{
    margin-top:var(--spacing-xs);
  }
  .zimbra-client_settings_sharing-settings_grantee-list_granteeList > .zimbra-client_settings_sharing-settings_grantee-list_granteeItem:last-child{
    border:none;
    padding-bottom:0;
  }
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem{
  border-bottom:1px solid var(--gray-lighter);
  width:100%;
  padding:var(--spacing-sm) 0;
}
@media (min-width: 1025px){
  .zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem:hover{
    background-color:var(--gray-lightest);
  }
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem.zimbra-client_settings_sharing-settings_grantee-list_expandedGrantee{
  background-color:transparent;
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem.zimbra-client_settings_sharing-settings_grantee-list_expandedGrantee > div:not(:last-child){
  padding-bottom:var(--spacing-sm);
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem .zimbra-client_settings_sharing-settings_grantee-list_aclHeader{
  display:flex;
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem .zimbra-client_settings_sharing-settings_grantee-list_aclHeader .zimbra-client_settings_sharing-settings_grantee-list_aclAddress{
  flex-grow:1;
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem .zimbra-client_settings_sharing-settings_grantee-list_permissionsSelect{
  margin-left:12px;
  width:100%;
}
.zimbra-client_settings_sharing-settings_grantee-list_granteeList .zimbra-client_settings_sharing-settings_grantee-list_granteeItem .zimbra-client_settings_sharing-settings_grantee-list_resendLink{
  margin-left:var(--spacing-lg);
  color:var(--brand-primary-500);
}
