@media (max-width: 480px){
  .zimbra-client_error-alert_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_error-alert_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_error-alert_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_error-alert_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_error-alert_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_error-alert_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_error-alert_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_error-alert_error{
  padding:12px var(--spacing-lg);
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  display:block;
  border-radius:3px;
}
