@media (max-width: 480px){
  .zimbra-client_folder_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_folder_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_folder_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_folder_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_folder_hideMdUp{
    display:none !important;
  }
}
a.zimbra-client_folder_disableHover:not(.zimbra-client_folder_disabled):hover,
div.zimbra-client_folder_disableHover:not(.zimbra-client_folder_disabled):hover{
  background:none;
}
a.zimbra-client_folder_selected:not(.zimbra-client_folder_disabled),
div.zimbra-client_folder_selected:not(.zimbra-client_folder_disabled),
a.zimbra-client_folder_selected:not(.zimbra-client_folder_disabled):hover,
div.zimbra-client_folder_selected:not(.zimbra-client_folder_disabled):hover{
  background:var(--brand-primary-50);
}
