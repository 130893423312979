@media (max-width: 480px){
  .zimbra-client_textarea_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_textarea_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_textarea_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_textarea_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_textarea_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_textarea_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_textarea_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_textarea_input{
  padding:6px 12px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  background-color:#fff;
  border:solid 1px var(--gray-lighter);
}
.zimbra-client_textarea_input.zimbra-client_textarea_wide{
  flex:1;
}
.zimbra-client_textarea_input::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_textarea_input:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_textarea_input::placeholder{
  color:var(--placeholder-color);
}
