@media (max-width: 480px){
  .zimbra-client_smart-list_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_smart-list_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_smart-list_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_smart-list_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_smart-list_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_smart-list_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_smart-list_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_smart-list_smartList{
  position:relative;
  height:100%;
}
.zimbra-client_smart-list_smartList .zimbra-client_smart-list_inner{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
  outline:none;
  list-style-type:none;
}
.zimbra-client_smart-list_smartList .zimbra-client_smart-list_inner:not(:first-child){
  top:var(--toolbar-height);
  top:calc(var(--toolbar-height) * 2);
}
@media (max-width: 1024px){
  .zimbra-client_smart-list_smartList .zimbra-client_smart-list_inner:not(:first-child){
    top:var(--mobile-toolbar-height);
  }
}
.zimbra-client_smart-list_smartList.zimbra-client_smart-list_headerSearch .zimbra-client_smart-list_inner:not(:first-child){
  top:var(--toolbar-height);
}
.zimbra-client_smart-list_smartList .zimbra-client_smart-list_progressBar{
  position:absolute;
  left:0;
  right:0;
  top:0;
}
.zimbra-client_smart-list_smartList .zimbra-client_smart-list_noItems{
  display:block;
  padding:50px 0 0;
  font-size:120%;
  text-align:center;
  font-weight:300;
  color:var(--placeholder-color);
}
.zimbra-client_smart-list_smartList .zimbra-client_smart-list_listTopper{
  max-height:var(--row-height-narrow);
  overflow:hidden;
  display:block;
}
