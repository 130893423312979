@media (max-width: 480px){
  .zimbra-client_combo-box_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_combo-box_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_combo-box_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_combo-box_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_combo-box_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_combo-box_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_combo-box_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_combo-box_comboTextSelect{
  display:flex;
  width:100%;
}
.zimbra-client_combo-box_comboTextSelect .zimbra-client_combo-box_input{
  padding-left:40px;
}
.zimbra-client_combo-box_comboTextSelect .zimbra-client_combo-box_selectContainer{
  position:absolute;
  width:33px;
}
.zimbra-client_combo-box_comboTextSelect .zimbra-client_combo-box_selectContainer select{
  width:0px;
  border:none;
  border-right:1px solid var(--gray-lighter);
  border-radius:unset;
  padding-right:21px;
}
