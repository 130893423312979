.blocks_tabs_tab{
  width:100%;
  display:none;
}
.blocks_tabs_tab.blocks_tabs_active{
  display:block;
}
.blocks_tabs_nav{
  width:100%;
}
.blocks_tabs_nav-item{
  color:#000;
  color:initial;
  cursor:pointer;
  display:inline-block;
}
.blocks_tabs_nav-item:before{
  content:'';
  display:inline-block;
  height:100%;
  vertical-align:middle;
}
.blocks_tabs_nav-item > a{
  color:#000;
  display:inline-block;
  vertical-align:middle;
  text-decoration:none;
}
