@media (max-width: 480px){
  .zimbra-client_mini-calendar-view_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_mini-calendar-view_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_mini-calendar-view_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_mini-calendar-view_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_mini-calendar-view_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_mini-calendar-view_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_mini-calendar-view_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_mini-calendar-view_miniCalView{
  border-top:solid 1px var(--border-color);
  display:flex;
  flex-direction:column;
  box-shadow:0 -2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow-y:auto;
}
.zimbra-client_mini-calendar-view_resizeControl{
  background-color:inherit;
}
.zimbra-client_mini-calendar-view_miniCal{
  display:flex;
  flex-direction:column;
  padding:var(--spacing-sm) var(--spacing-md);
  min-width:-webkit-fit-content;
  min-width:-moz-fit-content;
  min-width:fit-content;
}
.zimbra-client_mini-calendar-view_miniCalGrouping{
  margin-top:var(--spacing-sm);
  margin-bottom:var(--spacing-sm);
  list-style:none;
}
.zimbra-client_mini-calendar-view_miniCalSections{
  margin-bottom:2px;
}
.zimbra-client_mini-calendar-view_miniCalEvents{
  color:var(--link-color);
  margin-bottom:2px;
  cursor:pointer;
}
.zimbra-client_mini-calendar-view_miniCalHeader{
  display:inline-flex;
  align-items:center;
  justify-content:space-between;
}
.zimbra-client_mini-calendar-view_miniCalHeader .zimbra-client_mini-calendar-view_navButton{
  padding:var(--spacing-sm);
}
.zimbra-client_mini-calendar-view_addEvent{
  align-self:start;
  padding:0;
  color:var(--link-color);
}
.zimbra-client_mini-calendar-view_addEvent .zimbra-client_mini-calendar-view_title{
  vertical-align:middle;
}
.zimbra-client_mini-calendar-view_noAppointments{
  margin-top:var(--spacing-sm);
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_mini-calendar-view_spinner{
  align-self:center;
  left:50%;
  top:50%;
  margin-top:var(--spacing-sm);
  margin-bottom:var(--spacing-sm);
  transform:translate(-50%, -50%);
}
