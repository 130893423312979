.zimbra-client_nested-action-menu-item_chevron{
  font-size:var(--icon-size-xs);
  position:absolute;
  right:10px;
}
.zimbra-client_nested-action-menu-item_item{
  overflow:visible;
  position:relative;
}
.zimbra-client_nested-action-menu-item_nestedMenuItem,
.zimbra-client_nested-action-menu-item_nestedMenuItem .zimbra-client_nested-action-menu-item_nestedMenuToggle{
  display:block;
}
