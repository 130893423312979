.zimbra-client_password-input_container{
  position:relative;
  display:inline-block;
  width:100%;
}
.zimbra-client_password-input_container input{
  padding-right:4em !important;
}
.zimbra-client_password-input_container button{
  background:transparent;
  border:none;
  color:var(--gray-light);
  font-size:10px;
  padding:1.25em;
  position:absolute;
  right:10px;
  top:50%;
  text-transform:uppercase;
  transform:translateY(-50%);
}
.zimbra-client_password-input_container button:focus,
.zimbra-client_password-input_container button:hover{
  color:var(--brand-primary-500);
}
