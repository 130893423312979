@media (max-width: 480px){
  .zimbra-client_attachment-grid_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_attachment-grid_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_attachment-grid_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_attachment-grid_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_attachment-grid_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_attachment-grid_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_attachment-grid_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_attachment-grid_attachments{
  display:flex;
  flex-direction:flex-row;
  flex-wrap:wrap;
}
.zimbra-client_attachment-grid_attachments > div{
  margin-right:var(--spacing-sm);
}
.zimbra-client_attachment-grid_attachment{
  align-items:stretch;
  border:solid 1px var(--border-color);
  cursor:pointer;
  display:flex;
  flex-shrink:0;
  margin-bottom:8px;
  padding:0;
  width:260px;
  box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.15);
  align-items:center;
  flex-direction:row;
}
.zimbra-client_attachment-grid_attachment.zimbra-client_attachment-grid_uploading{
  background:var(--gray-lightest);
}
.zimbra-client_attachment-grid_attachment.zimbra-client_attachment-grid_uploading .zimbra-client_attachment-grid_nameContainer{
  color:var(--gray-light);
}
.zimbra-client_attachment-grid_attachment.zimbra-client_attachment-grid_uploading .zimbra-client_attachment-grid_nameContainer .zimbra-client_attachment-grid_fileSize{
  color:var(--gray-lighter);
}
.zimbra-client_attachment-grid_attachment:hover,
.zimbra-client_attachment-grid_attachment:focus{
  background:var(--gray-lightest);
}
.zimbra-client_attachment-grid_attachment *{
  min-width:auto !important;
}
.zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_attachmentDetails{
  display:flex;
  width:100%;
  align-items:center;
  overflow:hidden;
}
.zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_iconContainer{
  width:80px;
  min-width:auto!important;
}
.zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_actionIcon{
  margin-right:var(--spacing-xs);
  display:none;
  color:var(--gray-darker);
  text-decoration:none;
}
.zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_actionIcon:not([class*=zimbra-icon]):empty{
  display:block;
}
@media (max-width: 1024px){
  .zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_actionIcon{
    display:block;
  }
}
.zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_actionIcon:hover,
.zimbra-client_attachment-grid_attachment .zimbra-client_attachment-grid_actionIcon:focus{
  color:var(--gray-darker);
  text-decoration:none;
}
.zimbra-client_attachment-grid_attachment:hover .zimbra-client_attachment-grid_actionIcon{
  display:block;
}
@media (max-width: 480px){
  .zimbra-client_attachment-grid_attachment{
    width:100%;
  }
}
.zimbra-client_attachment-grid_icon{
  color:white;
}
.zimbra-client_attachment-grid_nameContainer{
  padding:8px;
  font-size:var(--font-size-small);
  margin-top:0;
  overflow:hidden;
}
.zimbra-client_attachment-grid_nameContainer .zimbra-client_attachment-grid_fileSize{
  color:var(--gray-light);
}
.zimbra-client_attachment-grid_nameContainer .zimbra-client_attachment-grid_fileInfo{
  width:100%;
}
.zimbra-client_attachment-grid_nameContainer .zimbra-client_attachment-grid_fileInfo .zimbra-client_attachment-grid_filename{
  display:flex;
  flex-direction:row;
  white-space:nowrap;
}
.zimbra-client_attachment-grid_nameContainer .zimbra-client_attachment-grid_fileInfo .zimbra-client_attachment-grid_filename .zimbra-client_attachment-grid_filenameText{
  text-overflow:ellipsis;
  overflow:hidden;
}
.zimbra-client_attachment-grid_fileOperation{
  display:flex;
  flex-direction:row;
}
.zimbra-client_attachment-grid_name{
  overflow-x:hidden;
  text-overflow:clip;
}
.zimbra-client_attachment-grid_buttonsContainer{
  display:flex;
  flex-direction:row;
  height:16px;
  margin-bottom:16px;
  margin-top:16px;
}
.zimbra-client_attachment-grid_buttonDivider{
  border-left:solid 1px #979799;
  margin-left:16px;
  padding-left:16px;
}
.zimbra-client_attachment-grid_button{
  font-size:var(--font-size-base);
  color:var(--brand-tertiary-500);
}
.zimbra-client_attachment-grid_button[disabled]{
  color:var(--gray-light);
}
.zimbra-client_attachment-grid_button[disabled]:hover{
  cursor:default;
}
.zimbra-client_attachment-grid_angleIcon{
  position:relative;
  top:-2px;
}
.zimbra-client_attachment-grid_modalInner{
  margin:auto;
  text-align:center;
}
.zimbra-client_attachment-grid_modalInner .zimbra-client_attachment-grid_buttonContainer{
  padding-top:8px;
}
.zimbra-client_attachment-grid_modalInner .zimbra-client_attachment-grid_buttonContainer .zimbra-client_attachment-grid_button{
  background-color:transparent;
  border-color:var(--gray-lighter);
  color:var(--gray-base);
  width:136px;
}
.zimbra-client_attachment-grid_modalInner .zimbra-client_attachment-grid_buttonContainer .zimbra-client_attachment-grid_button:hover,
.zimbra-client_attachment-grid_modalInner .zimbra-client_attachment-grid_buttonContainer .zimbra-client_attachment-grid_button:focus{
  background-color:var(--gray-lighter);
  text-decoration:none;
}
.zimbra-client_attachment-grid_modalInner .zimbra-client_attachment-grid_buttonContainer .zimbra-client_attachment-grid_button .zimbra-client_attachment-grid_icon{
  margin-right:10px;
}
