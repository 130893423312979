@media (max-width: 480px){
  .zimbra-client_time-picker_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_time-picker_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_time-picker_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_time-picker_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_time-picker_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_time-picker_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_time-picker_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_time-picker_fakeSelect{
  width:100%;
  padding:8px 34px 8px 12px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  color:var(--gray-darkest);
  background-color:#fff;
  border:solid 1px var(--gray-lighter);
  position:relative;
}
.zimbra-client_time-picker_fakeSelect:before{
  font-family:'zimbra-icons';
  content:var(--zimbra-icon-angle-down);
  position:absolute;
  right:11px;
  top:10px;
  font-size:10px;
}
html:not(.zimbra-client_time-picker_touch) .zimbra-client_time-picker_dropdown::-webkit-scrollbar{
  width:10px;
  height:10px;
}
html:not(.zimbra-client_time-picker_touch) .zimbra-client_time-picker_dropdown::-webkit-scrollbar-track{
  background:rgba(0, 0, 0, 0.1);
}
html:not(.zimbra-client_time-picker_touch) .zimbra-client_time-picker_dropdown::-webkit-scrollbar-thumb{
  border-radius:5px;
  background:rgba(0, 0, 0, 0.2);
}
.zimbra-client_time-picker_dropdown{
  position:absolute;
  width:100%;
  min-width:165px;
  left:0;
  margin-top:21px;
  top:15px;
  border:1px solid var(--gray-lighter);
  border-bottom-left-radius:3px;
  border-bottom-right-radius:3px;
  z-index:1;
  background-color:#ffffff;
  overflow-y:scroll;
  max-height:200px;
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem{
  padding:5px 10px;
  position:relative;
  transition:all 200ms ease-in-out;
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem:hover{
  color:var(--brand-tertiary-500);
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem .zimbra-client_time-picker_secondLevelDD{
  position:absolute;
  border-left:1px solid var(--gray-lighter);
  top:0;
  right:10px;
  padding-left:10px;
  margin-bottom:2px;
  opacity:0;
  transition:all 200ms ease-in-out;
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem .zimbra-client_time-picker_secondLevelDD li{
  padding:3px;
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem .zimbra-client_time-picker_secondLevelDD li:hover{
  color:var(--gray-base);
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem .zimbra-client_time-picker_secondLevelDD::after{
  content:'';
  width:0;
  height:0;
  border-style:solid;
  border-width:10px 10px 10px 0;
  border-color:transparent var(--gray-lighter) transparent transparent;
  transform:rotate(360deg);
  position:absolute;
  left:-10px;
  top:50%;
  transform:translateY(-12px);
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem span{
  transition:all 200ms ease-in-out;
  display:block;
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem:hover span{
  padding-top:25px;
  padding-bottom:25px;
}
.zimbra-client_time-picker_dropdown .zimbra-client_time-picker_dropdownItem:hover .zimbra-client_time-picker_secondLevelDD{
  opacity:1;
}
.zimbra-client_time-picker_invalid{
  border:solid 1px #cf2a2a !important;
  box-shadow:0 0 4px 0 rgba(0, 0, 0, 0.3);
}
