.zimbra-client_toolbar_title_container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  flex-shrink:1;
  min-width:0;
  margin-left:4px;
}
.zimbra-client_toolbar_title_title{
  font-size:20px;
  line-height:23px;
  text-transform:capitalize;
}
.zimbra-client_toolbar_title_subtitle{
  font-size:var(--font-size-small);
  color:#4a4a4a;
  white-space:nowrap;
  text-overflow:ellipsis;
  word-break:break-all;
  overflow-x:hidden;
}
