@media (max-width: 480px){
  .zimbra-client_action-button_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_action-button_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_action-button_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_action-button_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-button_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-button_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_action-button_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_action-button_button{
  font-family:var(--branding-font-family-regular);
  font-weight:regular;
  display:inline-block;
  position:relative;
  box-sizing:border-box;
  padding:0 14px;
  height:calc(var(--toolbar-height) - 1px);
  background:rgba(0, 0, 0, 0);
  border:0;
  text-decoration:none;
  font-size:100%;
  color:var(--text-color);
  white-space:nowrap;
  transition:all 200ms ease;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer;
  overflow:hidden;
}
.zimbra-client_action-button_button:hover,
.zimbra-client_action-button_button:focus{
  color:var(--brand-primary-500);
}
.zimbra-client_action-button_button:disabled{
  opacity:0.4;
  cursor:default;
}
.zimbra-client_action-button_button:disabled:hover{
  color:var(--text-color);
}
.zimbra-client_action-button_button > .zimbra-client_action-button_icon{
  color:var(--brand-primary-500);
  position:relative;
  vertical-align:middle;
  display:inline-block;
  line-height:1;
}
.zimbra-client_action-button_button.zimbra-client_action-button_monotone > .zimbra-client_action-button_icon{
  color:inherit;
}
.zimbra-client_action-button_button > .zimbra-client_action-button_icon + .zimbra-client_action-button_text{
  margin-left:6px;
  position:relative;
  top:2px;
}
