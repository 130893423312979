@media (max-width: 480px){
  .zimbra-client_settings_sharing-settings_options_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_sharing-settings_options_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_sharing-settings_options_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_sharing-settings_options_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_options_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_options_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_sharing-settings_options_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_settings_sharing-settings_options_popover{
  min-width:40px;
}
.zimbra-client_settings_sharing-settings_options_button{
  height:var(--spacing-lg);
  padding:0;
  width:var(--spacing-lg);
}
