@media (max-width: 480px){
  .zimbra-client_share-dialog_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_share-dialog_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_share-dialog_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_share-dialog_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_share-dialog_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_share-dialog_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_share-dialog_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_share-dialog_cardGroup{
  width:100%;
  flex:auto;
}
.zimbra-client_share-dialog_shareList{
  font-size:var(--font-size-small);
  color:var(--gray-light);
}
.zimbra-client_share-dialog_emailForm,
.zimbra-client_share-dialog_emailInput{
  width:100%;
}
.zimbra-client_share-dialog_emailInput{
  border:0;
  outline:none;
}
div.zimbra-client_share-dialog_invalid{
  border:solid 1px var(--brand-danger-500);
}
.zimbra-client_share-dialog_accessTable{
  width:100%;
  border-collapse:collapse;
}
.zimbra-client_share-dialog_section{
  margin-bottom:28px;
  overflow-y:auto;
  max-height:230px;
}
.zimbra-client_share-dialog_sectionTitle{
  font-weight:bold;
  margin-bottom:8px;
}
.zimbra-client_share-dialog_accessTableRow td{
  border-bottom:1px solid var(--border-color);
  padding-left:24px;
}
.zimbra-client_share-dialog_accessTableRow td:first-child{
  padding-left:0;
}
.zimbra-client_share-dialog_contactPreview{
  display:flex;
  align-items:center;
}
.zimbra-client_share-dialog_contactPreviewName{
  font-weight:bold;
}
.zimbra-client_share-dialog_contactPreviewAddress{
  color:var(--gray-light);
}
.zimbra-client_share-dialog_contactDetails{
  flex:1;
}
.zimbra-client_share-dialog_avatar{
  width:36px;
  height:36px;
  margin:6px 0 6px 6px;
  margin-right:8px;
}
.zimbra-client_share-dialog_disabledSharingLabel{
  color:var(--gray-light);
}
