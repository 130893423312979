.zimbra-client_context-menus_defaultContainer{
  transition:width 0.2s, height 0.2s;
  width:214px;
}
.zimbra-client_context-menus_defaultContainer.zimbra-client_context-menus_showingPicker{
  width:258px;
  height:294px;
}
.zimbra-client_context-menus_moveFolderGroup{
  padding:0;
}
.zimbra-client_context-menus_colorPicker{
  max-width:168px;
}
