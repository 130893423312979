@media (max-width: 480px){
  .zimbra-client_location-picker-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_location-picker-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_location-picker-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_location-picker-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_location-picker-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_location-picker-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_location-picker-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_location-picker-modal_locationModal .zimbra-client_location-picker-modal_locationModalContent{
  padding-right:0;
  padding-bottom:72px;
  padding-left:0;
}
.zimbra-client_location-picker-modal_contentWrapper{
  min-height:100px;
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_noResult{
  padding:var(--spacing-md) var(--spacing-lg);
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_showAvailableOnly{
  display:inline-block;
  margin:0 0 12px 20px;
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper{
  position:relative;
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper .zimbra-client_location-picker-modal_searchIcon{
  position:absolute;
  left:20px;
  top:10px;
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper .zimbra-client_location-picker-modal_searchInput{
  padding:12px 12px 12px 36px;
}
@media (min-width: 1025px){
  .zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper .zimbra-client_location-picker-modal_searchInput{
    padding-left:48px;
    border-radius:0;
    border-left:0;
    border-right:0;
  }
  .zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper .zimbra-client_location-picker-modal_searchInput:focus{
    outline:none;
    border-top-color:var(--brand-primary-500);
    border-bottom-color:var(--brand-primary-500);
  }
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList{
  list-style:none;
  height:260px;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li{
  display:flex;
  padding:var(--spacing-sm) 12px var(--spacing-sm) 20px;
  border-bottom:1px solid var(--gray-lighter);
  align-items:center;
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li:hover{
  background:var(--gray-lightest);
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li.zimbra-client_location-picker-modal_selected{
  background:var(--list-active-bg);
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li.zimbra-client_location-picker-modal_busy{
  background:var(--brand-danger-50);
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li.zimbra-client_location-picker-modal_hide{
  display:none;
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li .zimbra-client_location-picker-modal_locationDetails{
  padding-left:var(--spacing-sm);
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li .zimbra-client_location-picker-modal_locationDetails .zimbra-client_location-picker-modal_locationStats span:not(:last-child):after{
  content:' | ';
}
.zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList li .zimbra-client_location-picker-modal_locationDetails .zimbra-client_location-picker-modal_busyLabel{
  color:var(--brand-danger-500);
  padding-left:var(--spacing-md);
}
@media (max-width: 1024px){
  .zimbra-client_location-picker-modal_contentWrapper{
    flex:1;
    display:flex;
    flex-direction:column;
    min-height:0;
    border-bottom:none;
  }
  .zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper{
    margin:0 20px var(--spacing-md);
  }
  .zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_searchWrapper .zimbra-client_location-picker-modal_searchIcon{
    left:var(--spacing-sm);
  }
  .zimbra-client_location-picker-modal_contentWrapper .zimbra-client_location-picker-modal_locationList{
    flex:1;
    min-height:0;
    height:auto;
    border-top:1px solid var(--gray-lighter);
  }
}
