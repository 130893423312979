@media (max-width: 480px){
  .zimbra-client_sidebar_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_sidebar_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_sidebar_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_sidebar_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_sidebar_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_sidebar_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_sidebar_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_sidebar_wrap{
  overflow:visible;
}
.zimbra-client_sidebar_wrap > iframe{
  width:100%;
  top:-1px;
  height:1px !important;
}
.zimbra-client_sidebar_wrap.zimbra-client_sidebar_inline .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_inner{
  background:none;
}
.zimbra-client_sidebar_sidebar{
  display:flex;
  width:var(--sidebar-width);
  height:100%;
  background-color:var(--sidebar-bg-color);
  font-family:var(--branding-font-family-regular);
  border-right:solid 1px var(--border-color);
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_backdrop{
  display:none;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_inner{
  width:100%;
  height:100%;
}
@media (max-width: 1024px){
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_inner{
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
  }
}
.zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  position:fixed;
  float:none;
  left:-150%;
  transition:left 1ms linear 440ms;
  overflow:hidden;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  z-index:600;
}
.zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal.zimbra-client_sidebar_showing{
  left:0%;
  transition:none;
}
.zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal.zimbra-client_sidebar_showing .zimbra-client_sidebar_inner{
  transform:translate3d(0%, 0, 0);
}
.zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal.zimbra-client_sidebar_showing .zimbra-client_sidebar_backdrop{
  opacity:1;
}
.zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal .zimbra-client_sidebar_backdrop{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  display:block;
  background-color:rgba(0, 0, 0, 0.6);
  opacity:0;
  will-change:opacity;
  transition:opacity 400ms ease 20ms;
}
.zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal .zimbra-client_sidebar_inner{
  min-width:290px;
  width:80%;
  border-right:none;
  background:white;
  box-shadow:5px 0 15px rgba(0, 0, 0, 0.2);
  -webkit-backface-visibility:hidden;
          backface-visibility:hidden;
  transform:translate3d(-120%, 0, 0);
  transition:transform 400ms ease 20ms;
  will-change:transform;
}
@media (max-width: 1024px) and (orientation: landscape){
  .zimbra-client_sidebar_sidebar.zimbra-client_sidebar_modal .zimbra-client_sidebar_inner{
    width:50%;
  }
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_header{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:0 var(--spacing-md);
  height:var(--search-header-height);
  background-color:var(--sidebar-header-bg-color);
  color:#fff;
}
@media (max-width: 769px){
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_header .zimbra-client_sidebar_sidebarLogo svg,
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_header .zimbra-client_sidebar_sidebarLogo img{
    width:150px;
    height:auto;
    vertical-align:middle;
  }
}
@media (max-width: 769px) and (max-width: 325px){
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_header .zimbra-client_sidebar_sidebarLogo svg,
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_header .zimbra-client_sidebar_sidebarLogo img{
    width:130px;
  }
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_content{
  width:100%;
  position:relative;
  overflow:hidden;
}
@media (max-width: 1025px){
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_content{
    height:auto;
  }
}
@media (min-width: 1025px){
  .zimbra-client_sidebar_sidebar .zimbra-client_sidebar_content{
    height:100%;
    display:flex;
    flex-direction:column;
  }
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_footer{
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  box-shadow:-5px 0 15px rgba(0, 0, 0, 0.2);
  border-top:1px solid white;
  z-index:20;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_footer .zimbra-client_sidebar_item:last-child{
  border-bottom-width:0;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_footer ~ .zimbra-client_sidebar_content{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
  bottom:38px;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item{
  display:block;
  height:32px;
  padding:7px 32px;
  color:var(--text-color);
  border:none;
  text-decoration:none;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item:hover{
  color:var(--gray-darker);
  text-decoration:none;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item[data-depth="2"]{
  padding-left:calc(32px * 1.5);
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item[data-depth="3"]{
  padding-left:calc(32px * 2);
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item[data-depth="4"]{
  padding-left:calc(32px * 2.5);
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item[data-depth="5"]{
  padding-left:calc(32px * 3);
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item[data-depth="6"]{
  padding-left:calc(32px * 3.5);
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_item .zimbra-client_sidebar_icon{
  padding-right:7px;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_resizeControl div{
  z-index:9;
}
.zimbra-client_sidebar_sidebar .zimbra-client_sidebar_resizeControl div:first-child{
  width:0;
}
.zimbra-client_sidebar_mobileAppNav{
  background-color:var(--sidebar-header-bg-color);
  padding:0 var(--spacing-sm) var(--spacing-xs);
}
