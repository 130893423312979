@media (max-width: 480px){
  .zimbra-client_error-bar_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_error-bar_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_error-bar_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_error-bar_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_error-bar_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_error-bar_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_error-bar_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_error-bar_error{
  padding:1px 20px;
  margin:-20px -20px 20px;
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
}
