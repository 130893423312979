@media (max-width: 480px){
  .zimbra-client_label_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_label_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_label_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_label_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_label_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_label_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_label_hideMdUp{
    display:none !important;
  }
}
label{
  padding-bottom:6px;
  color:var(--gray-darkest);
  vertical-align:middle;
}
.zimbra-client_label_large{
  font-size:var(--font-size-large);
}
