@media (max-width: 480px){
  .zimbra-client_modal-drawer_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_modal-drawer_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_modal-drawer_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_modal-drawer_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_modal-drawer_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_modal-drawer_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_modal-drawer_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_modal-drawer_modal{
  height:100%;
  width:100%;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container{
  display:flex;
  background-color:white;
  flex-direction:column;
  height:100%;
  transition:transform 250ms ease-in-out;
  transform:none;
  width:100%;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container.zimbra-client_modal-drawer_slideRight{
  transform:translateX(100%);
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container > :first-child{
  border-bottom:1px solid var(--border-color);
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container .zimbra-client_modal-drawer_header{
  padding:0;
  background:var(--off-white);
  border-bottom:1px solid var(--gray-lighter);
  color:var(--text-color);
  text-align:center;
  border-bottom:none;
  background:none;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container .zimbra-client_modal-drawer_header h2{
  margin:0 90px 0 40px;
  padding:10px 0;
  color:var(--dialog-title-fg);
  font-weight:normal;
  font-size:120%;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container .zimbra-client_modal-drawer_header .zimbra-client_modal-drawer_actionButton{
  position:absolute;
  right:5px;
  top:5px;
  margin:0;
  z-index:1;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container .zimbra-client_modal-drawer_header .zimbra-client_modal-drawer_actionButton .zimbra-client_modal-drawer_icon{
  display:inline-block;
  position:relative;
  width:1.5em;
  height:1em;
  padding:0 0 0 5px;
  overflow:hidden;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container .zimbra-client_modal-drawer_header .zimbra-client_modal-drawer_closeButton{
  position:absolute;
  left:5px;
  top:5px;
  padding:7px;
  margin:0;
  background:var(--gray-light);
  border-radius:50%;
  vertical-align:middle;
  font-size:100%;
  font-weight:normal;
  color:white;
  cursor:pointer;
  z-index:1;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_container .zimbra-client_modal-drawer_header h2{
  font-size:20px;
  margin:0;
  padding:22px 20px 5px;
  text-align:left;
  color:var(--dialog-title-fg);
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_close{
  position:absolute;
  left:16px;
  top:16px;
  background:transparent;
  border:none;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_spinner{
  position:absolute;
  top:0;
  left:0;
}
.zimbra-client_modal-drawer_modal .zimbra-client_modal-drawer_error{
  display:block;
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  padding:var(--spacing-sm) var(--spacing-lg);
  font-size:var(--font-size-base);
}
