.blocks_label_label,
.blocks_label_label:hover,
.blocks_label_label:hover[href],
.blocks_label_label:hover[focus]{
  background:var(--gray-lightest);
  border:0;
  color:var(--gray-darkest);
  cursor:default;
  display:inline-block;
  font-size:13px;
  font-weight:normal;
  height:27px;
  margin-right:8px;
  padding:4px 8px 5px;
  text-decoration:none;
  white-space:nowrap;
}
a.blocks_label_label,
a.blocks_label_label:hover,
a.blocks_label_label:hover[href],
a.blocks_label_label:hover[focus],
button.blocks_label_label,
button.blocks_label_label:hover,
button.blocks_label_label:hover[href],
button.blocks_label_label:hover[focus]{
  cursor:pointer;
}
