@-webkit-keyframes blocks_select_open{
  from{
    opacity:0;
  }
  to{
    top:100%;
  }
}
@keyframes blocks_select_open{
  from{
    opacity:0;
  }
  to{
    top:100%;
  }
}
@-webkit-keyframes blocks_select_openFromBottom{
  from{
    opacity:0;
  }
  to{
    bottom:100%;
  }
}
@keyframes blocks_select_openFromBottom{
  from{
    opacity:0;
  }
  to{
    bottom:100%;
  }
}
.blocks_select_select{
  display:inline-block;
  overflow:visible;
  position:relative;
  white-space:nowrap;
}
.blocks_select_select.blocks_select_disabled *{
  color:var(--gray-darkest) !important;
  cursor:default !important;
}
.blocks_select_select > ul{
  -webkit-animation:blocks_select_open 200ms ease forwards 1;
          animation:blocks_select_open 200ms ease forwards 1;
  background:var(--body-bg);
  border-radius:4px;
  border:1px solid var(--border-color);
  box-shadow:0 4px 6px rgba(0, 0, 0, 0.36);
  display:none;
  left:0;
  list-style:none;
  max-height:300px;
  overflow:auto;
  margin:0;
  padding:var(--spacing-sm) 0;
  position:absolute;
  top:0;
  transform-origin:0 0;
  min-width:100px;
  max-width:inherit;
  z-index:1;
}
.blocks_select_select > ul.blocks_select_dropup{
  -webkit-animation:blocks_select_openFromBottom 200ms ease forwards 1;
          animation:blocks_select_openFromBottom 200ms ease forwards 1;
  bottom:0;
  top:auto;
}
.blocks_select_select > ul.blocks_select_active{
  display:block;
}
.blocks_select_select > ul.blocks_select_active .blocks_select_item.blocks_select_active{
  position:relative;
}
.blocks_select_select > ul.blocks_select_active .blocks_select_item.blocks_select_active .blocks_select_itemIcon{
  display:inline-block;
}
.blocks_select_select > ul.blocks_select_right{
  right:0;
  left:auto;
}
.blocks_select_select > ul.blocks_select_center{
  left:50%;
  transform:translateX(-50%);
}
.blocks_select_select > ul .blocks_select_item{
  text-align:left;
  cursor:pointer;
  display:block;
  padding:var(--spacing-sm) var(--spacing-xl);
  font-size:var(--font-size-base);
  text-transform:capitalize;
  overflow:hidden;
  position:relative;
  line-height:1.3;
  border:none;
}
.blocks_select_select > ul .blocks_select_item .blocks_select_selector{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
}
.blocks_select_select > ul .blocks_select_item.blocks_select_focus{
  outline:none;
  background-color:var(--gray-lightest);
}
.blocks_select_select > ul .blocks_select_item.blocks_select_left .blocks_select_itemIcon{
  left:var(--spacing-xs);
}
.blocks_select_select > ul .blocks_select_item.blocks_select_right{
  text-align:right;
}
.blocks_select_select > ul .blocks_select_item.blocks_select_right .blocks_select_itemIcon{
  right:var(--spacing-xs);
}
.blocks_select_select > ul .blocks_select_item .blocks_select_itemTitle{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width:100%;
}
.blocks_select_select > ul .blocks_select_item .blocks_select_itemIcon{
  display:none;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
}
.blocks_select_button{
  max-width:100%;
  cursor:pointer;
  display:inline-block;
  color:#000;
}
.blocks_select_button .blocks_select_buttonIcon{
  font-size:10px;
  vertical-align:middle;
}
.blocks_select_button:focus{
  outline-width:0;
  color:var(--brand-primary-500);
}
.blocks_select_button .blocks_select_toggle{
  margin:0;
  display:inline-block;
  font-weight:bold;
  text-transform:capitalize;
  vertical-align:bottom;
  max-width:calc(100% - 10px);
  padding:0 5px;
  overflow-x:hidden;
  text-overflow:ellipsis;
}
