.zimbra-client_toolbar_toolbarContainer{
  background-color:white;
  border-bottom:solid 1px var(--border-color);
  flex:0 1 auto;
  height:calc(var(--mobile-toolbar-height) - 1px);
  width:100%;
  min-width:0;
}
.zimbra-client_toolbar_toolbar{
  width:100%;
  height:100%;
  display:flex;
  flex:auto;
  align-items:stretch;
  flex-direction:row;
}
