@media (max-width: 480px){
  .zimbra-client_search-advanced_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_search-advanced_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_search-advanced_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_search-advanced_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_search-advanced_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_search-advanced_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_search-advanced_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_search-advanced_overlay{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  width:100%;
  background-color:transparent;
}
.zimbra-client_search-advanced_container{
  min-width:300px;
  background-color:#fff;
  color:var(--text-color);
  box-shadow:0 8px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius:4px;
  border:solid 1px var(--gray-light);
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  padding:64px var(--spacing-md) var(--spacing-lg) var(--spacing-md);
  position:absolute;
  top:-12px;
  width:632px;
  right:calc(-1 * var(--spacing-md));
}
.zimbra-client_search-advanced_close{
  position:absolute;
  top:5px;
  right:16px;
  width:16px;
  height:16px;
  -o-object-fit:contain;
     object-fit:contain;
}
.zimbra-client_search-advanced_indented{
  margin-left:80px;
  width:100%;
}
.zimbra-client_search-advanced_indented > *{
  width:100%;
  display:block;
}
.zimbra-client_search-advanced_formGroup{
  margin-bottom:12px;
  align-items:baseline;
}
.zimbra-client_search-advanced_filteredBy{
  display:flex;
}
.zimbra-client_search-advanced_filteredBy .zimbra-client_search-advanced_filterItem{
  margin:0 var(--spacing-lg) 0 0;
}
.zimbra-client_search-advanced_filteredBy .zimbra-client_search-advanced_filterItem button{
  top:-1px;
  height:auto;
}
.zimbra-client_search-advanced_searchIn{
  display:flex;
}
.zimbra-client_search-advanced_searchIn .zimbra-client_search-advanced_searchInItem{
  margin:0 var(--spacing-lg) 0 0;
}
.zimbra-client_search-advanced_searchIn .zimbra-client_search-advanced_searchInItem button{
  height:auto;
  padding-left:0;
}
.zimbra-client_search-advanced_addressFieldInput{
  padding:3px 0px 4px;
  border-width:1px;
}
.zimbra-client_search-advanced_contactSuggestInput{
  flex-grow:1;
  border-radius:3px;
  background-color:#fff;
  border:solid 1px var(--gray-lighter);
}
@media (max-width: calc(480px - 1px)){
  .zimbra-client_search-advanced_tokenInput .zimbra-client_search-advanced_addressField{
    position:static;
    position:initial;
  }
  .zimbra-client_search-advanced_tokenInput .zimbra-client_search-advanced_addressField .zimbra-client_search-advanced_suggestions{
    left:-16px;
    right:-16px;
    max-width:none;
    max-width:initial;
  }
}
.zimbra-client_search-advanced_tokenInputStyle{
  height:23px;
}
.zimbra-client_search-advanced_textInput{
  flex-grow:1;
  padding:5px 12px 6px;
  height:32px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  background-color:#fff;
  border:solid 1px var(--gray-lighter);
}
.zimbra-client_search-advanced_textInput::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_search-advanced_textInput:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_search-advanced_textInput::placeholder,
.zimbra-client_search-advanced_textInput[disabled]{
  color:var(--placeholder-color);
}
.zimbra-client_search-advanced_textInput[disabled]{
  background-color:var(--gray-lightest);
}
.zimbra-client_search-advanced_textInput.zimbra-client_search-advanced_block{
  display:block;
  width:100%;
}
@media (min-width: 480px){
  .zimbra-client_search-advanced_textInput.zimbra-client_search-advanced_block{
    width:374px;
  }
}
.zimbra-client_search-advanced_inLineFieldLeft{
  margin-left:4px;
}
.zimbra-client_search-advanced_inLineFieldRight{
  margin-right:4px;
}
.zimbra-client_search-advanced_checkboxes{
  margin-top:var(--spacing-md);
}
.zimbra-client_search-advanced_checkbox{
  margin-bottom:10px;
}
.zimbra-client_search-advanced_checkbox > input:focus{
  outline:none;
}
.zimbra-client_search-advanced_textInput:focus{
  outline:none;
  border:solid 1px var(--brand-primary-500);
}
.zimbra-client_search-advanced_formActions{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
}
.zimbra-client_search-advanced_formActions button,
.zimbra-client_search-advanced_formActions a{
  margin:0;
}
.zimbra-client_search-advanced_attachmentWrapper .zimbra-client_search-advanced_showMoreButton{
  margin:0;
}
.zimbra-client_search-advanced_attachmentWrapper .zimbra-client_search-advanced_rowWrapper{
  margin-bottom:12px;
}
.zimbra-client_search-advanced_attachmentWrapper .zimbra-client_search-advanced_rowWrapper .zimbra-client_search-advanced_attachmentType{
  width:120px;
  display:inline-block;
}
.zimbra-client_search-advanced_disableText{
  color:#999;
}
.zimbra-client_search-advanced_actionMenu{
  max-height:300px;
  overflow:auto;
}
.zimbra-client_search-advanced_actionMenu button{
  height:auto;
  min-width:110px;
  text-align:left;
  padding-left:0;
}
.zimbra-client_search-advanced_navigateDown{
  height:38px;
  border-left:1px solid var(--gray-lighter);
  padding:0 var(--spacing-md);
  color:var(--gray-light);
}
.zimbra-client_search-advanced_menu{
  position:relative;
}
a.zimbra-client_search-advanced_disableHover:not(.zimbra-client_search-advanced_disabled):hover{
  background:none;
}
a.zimbra-client_search-advanced_selected:not(.zimbra-client_search-advanced_disabled),
a.zimbra-client_search-advanced_selected:not(.zimbra-client_search-advanced_disabled):hover{
  background:rgba(0, 159, 219, 0.15);
}
.zimbra-client_search-advanced_searchFolderGroup{
  overflow-y:auto;
  overflow-x:hidden;
  max-height:60vh;
  font-size:var(--font-size-base);
}
.zimbra-client_search-advanced_allMail{
  font-size:var(--font-size-base);
  font-weight:bold;
  text-align:left;
  color:var(--gray-darker);
}
.zimbra-client_search-advanced_dropdownHeading{
  padding-left:var(--spacing-xl);
  font-weight:bold;
  font-size:var(--font-size-base);
  color:var(--gray-darker);
}
.zimbra-client_search-advanced_folderGroupHeading{
  text-indent:15px;
}
