@media (max-width: 480px){
  .zimbra-client_mail-sidebar_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_mail-sidebar_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_mail-sidebar_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_mail-sidebar_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_mail-sidebar_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_mail-sidebar_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_mail-sidebar_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_mail-sidebar_accountList{
  padding:8px 0;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
@media (max-width: 1024px){
  .zimbra-client_mail-sidebar_accountList{
    padding:0 0 0 34px;
  }
}
.zimbra-client_mail-sidebar_composeButtonWrapper{
  background-color:var(--gray-lightest);
  height:48px;
  text-align:center;
}
@media (min-width: 1024px){
  .zimbra-client_mail-sidebar_composeButtonWrapper{
    padding:1px var(--spacing-md) 0;
  }
}
.zimbra-client_mail-sidebar_boxShadow{
  box-shadow:0 2px 4px rgba(0, 0, 0, 0.2);
}
.zimbra-client_mail-sidebar_sidebarListWrapper{
  flex:1;
  position:relative;
}
@media (min-width: 1025px){
  .zimbra-client_mail-sidebar_sidebarListWrapper{
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
    padding:0 var(--spacing-md);
  }
}
a.zimbra-client_mail-sidebar_account{
  position:relative;
  padding-left:var(--spacing-md);
  line-height:32px;
  cursor:pointer;
  display:block;
  color:var(--text-color);
  display:flex;
  align-items:center;
}
@media (max-width: 1024px){
  a.zimbra-client_mail-sidebar_account{
    padding:12px 0;
    line-height:normal;
    line-height:initial;
  }
}
a.zimbra-client_mail-sidebar_account:focus,
a.zimbra-client_mail-sidebar_account:hover{
  text-decoration:none;
}
a.zimbra-client_mail-sidebar_account:hover{
  color:inherit;
}
a.zimbra-client_mail-sidebar_account.zimbra-client_mail-sidebar_active{
  color:var(--gray-base);
  font-weight:500;
}
a.zimbra-client_mail-sidebar_account.zimbra-client_mail-sidebar_active:hover .zimbra-client_mail-sidebar_showIcon{
  display:block;
  background-color:transparent;
}
a.zimbra-client_mail-sidebar_account.zimbra-client_mail-sidebar_active::before{
  content:'';
  width:0;
  height:0;
  position:absolute;
  left:0;
  top:10px;
  z-index:1;
  border-top:6px solid transparent;
  border-bottom:6px solid transparent;
  border-left:6px solid var(--brand-primary-500);
}
@media (max-width: 1024px){
  a.zimbra-client_mail-sidebar_account.zimbra-client_mail-sidebar_active::before{
    left:-14px;
    top:14px;
  }
}
a.zimbra-client_mail-sidebar_account.zimbra-client_mail-sidebar_failing{
  cursor:not-allowed;
}
.zimbra-client_mail-sidebar_accountSeparator{
  height:1px;
  margin-bottom:var(--spacing-sm);
  width:100%;
  background-color:var(--gray-lighter);
  margin-top:10px;
}
.zimbra-client_mail-sidebar_warningIcon{
  color:var(--brand-danger-500);
  padding-left:6px;
}
.zimbra-client_mail-sidebar_otherAccountWrapper{
  padding-left:50px;
}
.zimbra-client_mail-sidebar_otherAccountWrapper a.zimbra-client_mail-sidebar_account{
  padding-bottom:13px;
  padding-left:0;
  border-bottom:1px solid var(--gray-lighter);
}
