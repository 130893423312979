ul.blocks_vertical-list_verticalList{
  list-style:none;
}
ul.blocks_vertical-list_verticalList li{
  position:relative;
  background:var(--body-bg);
  border-bottom:solid 1px var(--gray-lighter);
  padding:var(--spacing-sm) var(--spacing-md) var(--spacing-sm) 12px;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
ul.blocks_vertical-list_verticalList li:first-child{
  border-top:solid 1px var(--gray-lighter);
}
ul.blocks_vertical-list_verticalList li:last-child{
  margin-bottom:var(--spacing-md);
}
ul.blocks_vertical-list_verticalList li span.blocks_vertical-list_removeIcon{
  position:absolute;
  right:var(--spacing-sm);
  top:var(--spacing-sm);
  display:none;
  font-size:20px;
}
ul.blocks_vertical-list_verticalList li:hover{
  background:var(--gray-lightest);
}
ul.blocks_vertical-list_verticalList li:hover span.blocks_vertical-list_removeIcon{
  display:inline;
}
