@media (max-width: 480px){
  .zimbra-client_contacts_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_contacts_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_contacts_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_contacts_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contacts_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contacts_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_contacts_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_contacts_item{
  border:none;
  font-weight:700;
  margin-bottom:var(--spacing-md);
}
.zimbra-client_contacts_item a{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_contacts_primaryContactInfo .zimbra-client_contacts_item:nth-child(-n+2){
  width:75%;
}
.zimbra-client_contacts_mobileToolbar{
  display:flex;
  margin-left:auto;
  align-items:center;
  margin-right:5px;
}
.zimbra-client_contacts_mobileToolbar .zimbra-client_contacts_mobileToolbarItem{
  padding:0 var(--spacing-sm);
}
.zimbra-client_contacts_sidebarListWrapper{
  flex:1;
  position:relative;
}
@media (min-width: 1025px){
  .zimbra-client_contacts_sidebarListWrapper{
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
    padding:0 var(--spacing-md);
  }
}
.zimbra-client_contacts_boxShadow{
  box-shadow:0 2px 4px rgba(0, 0, 0, 0.2);
}
.zimbra-client_contacts_contacts{
  display:flex;
  background:var(--read-pane-bg-color);
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_sidebarHeader{
  background-color:var(--gray-lightest);
  height:48px;
  text-align:center;
}
@media (min-width: 1024px){
  .zimbra-client_contacts_contacts .zimbra-client_contacts_sidebarHeader{
    padding:1px var(--spacing-md) 0;
  }
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contactWrapper{
  flex:1;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar{
  display:flex;
  height:var(--toolbar-height);
  border-bottom:1px solid var(--gray-lighter);
  justify-content:space-between;
  align-items:center;
  padding-left:var(--spacing-xs);
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar.zimbra-client_contacts_leftPanelHeader{
  height:calc(var(--toolbar-height) * 2);
  justify-content:initial;
  border-bottom:none;
  padding-left:0;
  display:inline;
  display:initial;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar .zimbra-client_contacts_row{
  height:var(--toolbar-height);
  display:inline-block;
  border-bottom:1px solid var(--gray-lighter);
  width:100%;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar .zimbra-client_contacts_row .zimbra-client_contacts_arrowButton{
  padding:0;
  color:var(--gray-darker);
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar .zimbra-client_contacts_row .zimbra-client_contacts_arrowButton:hover{
  color:var(--brand-primary-500);
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar .zimbra-client_contacts_sort{
  padding-left:var(--spacing-xs);
  display:flex;
  align-items:center;
  justify-content:space-between;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar [class*=choiceInputContainer]{
  margin:0 calc(var(--spacing-md) - var(--spacing-xs));
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar .zimbra-client_contacts_alignRight{
  margin-left:auto;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_toolbar .zimbra-client_contacts_alignRight > span{
  color:var(--gray-darker);
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contactList{
  float:left;
  width:328px;
  height:100%;
  background:var(--body-bg);
  border-right:1px solid var(--gray-lighter);
  transition:width 450ms ease 20ms;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contactList.zimbra-client_contacts_attachmentViewerOpen{
  display:none;
}
@media (max-width: 1024px){
  .zimbra-client_contacts_contacts .zimbra-client_contacts_contactList{
    width:100%;
  }
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact{
  position:relative;
  display:flex;
  width:100%;
  height:var(--row-height-narrow);
  background:rgba(255, 255, 255, 0.9);
  border-left:solid 4px transparent;
  border-bottom:1px solid var(--gray-lighter);
  color:var(--text-color);
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer;
  contain:strict;
  overflow:hidden;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact:hover,
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact:focus{
  background:var(--list-hover-bg);
  outline:none;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact.zimbra-client_contacts_selected{
  background:var(--list-active-bg);
  border-left:solid 4px var(--brand-primary-500);
  color:var(--gray-base);
  z-index:2;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact span.zimbra-client_contacts_danger{
  color:var(--color-light-negative);
  font-size:var(--font-size-base);
  font-weight:normal;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact > span{
  display:inline-block;
  height:100%;
  position:relative;
  width:37px;
  margin-right:6px;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact > span label{
  display:block;
  height:100%;
  width:100%;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact > span [class*=choiceInputContainer]{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  margin:0;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact > div{
  display:inline-block;
  height:100%;
  flex:1;
  vertical-align:top;
  padding:7px 0;
  width:calc(100% - 59px);
  margin-right:var(--spacing-md);
  white-space:nowrap;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact h4,
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact h5,
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact h6{
  margin:0;
  padding:0;
  color:inherit;
  font-weight:normal;
  font-size:100%;
  min-height:1.43em;
  overflow:hidden;
  text-overflow:ellipsis;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact h4{
  font-weight:bold;
  font-size:var(--font-size-h5);
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_contact h5{
  padding-bottom:1px;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_readPane,
.zimbra-client_contacts_contacts .zimbra-client_contacts_importContacts{
  position:relative;
  height:100%;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_readPane{
  overflow-y:auto;
  display:flex;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_readPane.zimbra-client_contacts_attachmentViewerOpen{
  width:calc(50% - calc(var(--sidebar-width) / 2 + 1));
  min-width:0;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_readPane .zimbra-client_contacts_selectedContacts{
  position:absolute;
  left:0;
  top:50%;
  width:100%;
  padding:0 30px;
  margin:-1em 0 0;
  text-align:center;
  font-size:var(--font-size-h3);
  font-weight:300;
  color:var(--text-color);
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_readPane .zimbra-client_contacts_contactViewer{
  flex:1;
  position:relative;
  height:inherit;
}
.zimbra-client_contacts_contacts .zimbra-client_contacts_readPane .zimbra-client_contacts_contactViewer > .zimbra-client_contacts_inner{
  height:100%;
}
.zimbra-client_contacts_createContactGroup{
  display:block;
  margin:var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-md);
}
.zimbra-client_contacts_actionButtons{
  display:flex;
  justify-content:flex-end;
  flex:1;
  flex-shrink:0;
  margin-right:4px;
}
.zimbra-client_contacts_composeButton{
  background-color:var(--brand-primary-500);
  color:white;
  border-radius:2px;
}
.zimbra-client_contacts_composeButton:hover{
  color:white;
}
.zimbra-client_contacts_contactEditor{
  display:flex;
  flex-direction:column;
  position:relative;
  height:100%;
  background:var(--body-bg);
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_contacts_contactEditor > .zimbra-client_contacts_inner{
  flex:1 1 100%;
  padding:0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_contacts_contactEditor.zimbra-client_contacts_hasFooter > .zimbra-client_contacts_inner{
  bottom:64px;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_header{
  border-bottom:1px solid var(--gray-lighter);
  padding:14px 24px;
  margin:0;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_header h2{
  margin:0;
  padding:0;
  font-size:var(--font-size-h4);
  font-weight:400;
}
.zimbra-client_contacts_contactEditor.zimbra-client_contacts_hasHeader .zimbra-client_contacts_contactEditFormWrapper{
  top:54px;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_error{
  position:relative;
  margin:0;
  padding:12px 24px;
  background:var(--brand-danger-50);
  color:var(--brand-danger-900);
  text-align:left;
  -webkit-animation:zimbra-client_contacts_fadeIn 300ms ease forwards 1;
          animation:zimbra-client_contacts_fadeIn 300ms ease forwards 1;
}
@-webkit-keyframes zimbra-client_contacts_fadeIn{
  0%{
    opacity:0;
  }
}
@keyframes zimbra-client_contacts_fadeIn{
  0%{
    opacity:0;
  }
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_error ~ .zimbra-client_contacts_form{
  top:104px;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_error span{
  display:block;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_footer{
  position:relative;
  bottom:0;
  flex:0 0 0;
  width:100%;
  height:64px;
  padding:0 20px;
  background:var(--body-bg);
  border-top:1px solid var(--gray-lighter);
  line-height:64px;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_addRemoveButtons{
  margin-left:var(--spacing-xs);
  display:inline-block;
  vertical-align:middle;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_addRemoveButtons button{
  background:none;
  border:none;
  color:#5a5a5a;
  font-weight:100;
  padding:0 var(--spacing-xs);
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form{
  padding:var(--spacing-lg) var(--spacing-lg) 0;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form fieldset{
  padding:0;
  margin:0 0 var(--spacing-xl);
  border:none;
  position:relative;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form fieldset legend{
  color:var(--gray-light);
  font-size:var(--font-size-med);
  margin-bottom:var(--spacing-md);
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form fieldset > div{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form label,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_dropdownLabel{
  display:block;
  font-size:var(--font-size-base);
  float:left;
  width:179px;
  clear:left;
  margin:0;
  padding:var(--spacing-sm) var(--spacing-md);
  color:var(--gray-darker);
  text-align:left;
  overflow:hidden;
}
@media (max-width: 1024px){
  .zimbra-client_contacts_contactEditor .zimbra-client_contacts_form label,
  .zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_dropdownLabel{
    padding:var(--spacing-sm) 0;
  }
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form label .blocks_select,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputDropdown .blocks_select{
  display:block;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form label .blocks_select .blocks_select_button,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputDropdown .blocks_select .blocks_select_button{
  display:block;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form label .blocks_select .blocks_select_button .blocks_select_toggle,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputDropdown .blocks_select .blocks_select_button .blocks_select_toggle{
  font-weight:normal;
  padding-left:0;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_dropdownLabel{
  overflow:visible;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_addFieldDropdown{
  padding:0;
  height:auto;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_addressGroup .zimbra-client_contacts_inputWrap{
  display:block;
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap{
  display:inline-block;
  vertical-align:middle;
  position:relative;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap > *{
  display:block;
  width:100%;
  padding:var(--spacing-sm);
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap > .zimbra-client_contacts_dateInput{
  padding:0;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap .zimbra-client_contacts_addressGroup{
  padding:0;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap .zimbra-client_contacts_inputDropdown{
  width:216px;
  padding:0;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap .zimbra-client_contacts_otherField{
  margin-right:var(--spacing-sm);
  width:172px;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap .zimbra-client_contacts_block input,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap input,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap textarea,
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap .blocks_select{
  font-size:var(--font-size-base);
  padding:var(--spacing-sm);
  width:216px;
  transition:box-shadow 300ms ease;
  border:1px solid var(--gray-lighter);
  border-radius:3px;
  transition:border 300ms ease;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  cursor:text;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap textarea{
  height:64px;
  resize:none;
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap .zimbra-client_contacts_errorField{
  border:1px solid var(--brand-danger-500);
  box-shadow:0 0 4px 0 rgba(0, 0, 0, 0.3);
}
.zimbra-client_contacts_contactEditor .zimbra-client_contacts_form .zimbra-client_contacts_inputWrap input[type="date"]{
  background-color:transparent;
  min-height:35px;
}
.zimbra-client_contacts_contactDetails.zimbra-client_contacts_hasDetails{
  background:var(--gray-lightest);
}
@media (min-width: 1300px){
  .zimbra-client_contacts_contactDetails{
    height:calc(100% - 49px);
  }
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_inner{
    display:flex;
  }
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_flexColumn{
    flex-direction:column;
  }
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_flexColumn .zimbra-client_contacts_card{
    flex:0;
  }
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_details{
    display:flex;
    flex-direction:column;
    flex:0 1 auto;
    max-width:calc(100% - 420px);
  }
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_details h3{
  color:var(--gray-base);
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessages,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFiles,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhotos{
  position:relative;
  padding:var(--spacing-sm) var(--spacing-lg);
  flex:0;
  max-width:100%;
  -webkit-animation:zimbra-client_contacts_fadeIn 150ms ease forwards;
          animation:zimbra-client_contacts_fadeIn 150ms ease forwards;
}
@keyframes zimbra-client_contacts_fadeIn{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessages.zimbra-client_contacts_empty,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFiles.zimbra-client_contacts_empty,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhotos.zimbra-client_contacts_empty{
  display:none;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessages h3,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFiles h3,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhotos h3{
  padding:calc(var(--spacing-md) - var(--spacing-xs)) 0;
  margin:0;
  font-size:100%;
  font-weight:normal;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessages h3 strong,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFiles h3 strong,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhotos h3 strong{
  font-weight:bold;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessages .zimbra-client_contacts_contactPhotosGallery,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFiles .zimbra-client_contacts_contactPhotosGallery,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhotos .zimbra-client_contacts_contactPhotosGallery{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1024px){
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessages,
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFiles,
  .zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhotos{
    padding:0 var(--spacing-md);
  }
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMoreMessages{
  padding:var(--spacing-sm) 0;
  display:flex;
  justify-content:flex-end;
  border-top:1px solid var(--border-color);
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMoreMessages a{
  font-weight:700;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessage,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFile{
  display:block;
  padding:var(--spacing-sm) 0;
  border-top:1px solid var(--border-color);
  color:var(--gray-base);
  text-decoration:none;
  overflow:hidden;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessage:hover,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFile:hover,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessage:focus,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFile:focus{
  color:var(--gray-base);
  background-color:rgba(0, 0, 0, 0.03);
  text-decoration:none;
  cursor:pointer;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessage .zimbra-client_contacts_title,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFile .zimbra-client_contacts_title{
  display:block;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessage .zimbra-client_contacts_time,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFile .zimbra-client_contacts_time{
  float:right;
  width:6em;
  text-align:right;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactMessage .zimbra-client_contacts_folder,
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactFile .zimbra-client_contacts_folder{
  float:right;
  padding-left:10px;
  max-width:12em;
  text-align:right;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
.zimbra-client_contacts_contactDetails .zimbra-client_contacts_contactPhoto{
  flex-basis:calc(25% - 8px);
  margin:0 var(--spacing-sm) var(--spacing-sm) 0;
}
.zimbra-client_contacts_contactIconAlign{
  padding-right:10px;
}
.zimbra-client_contacts_card{
  padding:var(--spacing-lg) var(--spacing-md);
  background:var(--body-bg);
  flex-direction:column;
  align-items:center;
  flex:1 0 420px;
  position:relative;
}
@media (max-width: 1299px){
  .zimbra-client_contacts_card{
    background:var(--body-bg);
  }
}
@media (min-width: 769px){
  .zimbra-client_contacts_card .zimbra-client_contacts_cardHeader{
    float:right;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contacts_card .zimbra-client_contacts_cardHeader{
    flex-direction:column;
    align-items:center;
  }
}
@media (max-width: 768px){
  .zimbra-client_contacts_card .zimbra-client_contacts_name{
    text-align:center;
  }
  .zimbra-client_contacts_card .zimbra-client_contacts_name > span{
    display:block;
  }
}
.zimbra-client_contacts_card .zimbra-client_contacts_cardBody{
  width:100%;
  position:relative;
  word-break:break-word;
  word-wrap:break-word;
  -webkit-hyphens:auto;
      -ms-hyphens:auto;
          hyphens:auto;
  align-self:flex-start;
}
.zimbra-client_contacts_card .zimbra-client_contacts_cardBody .zimbra-client_contacts_cardHeaderContainer{
  margin-bottom:var(--spacing-md);
}
@media (max-width: 769px){
  .zimbra-client_contacts_card .zimbra-client_contacts_cardBody .zimbra-client_contacts_cardHeaderContainer{
    text-align:center;
  }
}
.zimbra-client_contacts_card .zimbra-client_contacts_name,
.zimbra-client_contacts_card h3{
  font-size:28px;
  color:var(--gray-base);
  font-weight:300;
  margin:0 0 var(--spacing-sm) 0;
  overflow:hidden;
}
.zimbra-client_contacts_card .zimbra-client_contacts_byline{
  margin:0;
  font-weight:var(--headings-font-weight);
}
.zimbra-client_contacts_card h3{
  font-size:var(--font-size-h5);
}
.zimbra-client_contacts_card .zimbra-client_contacts_nickname,
.zimbra-client_contacts_card .zimbra-client_contacts_phoneticName{
  font-weight:300;
  font-size:var(--font-size-large);
  margin-right:var(--spacing-md);
  display:inline-block;
}
.zimbra-client_contacts_card .zimbra-client_contacts_tagPills{
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.zimbra-client_contacts_card .zimbra-client_contacts_tagPills .zimbra-client_contacts_alignBaseline{
  vertical-align:baseline;
}
.zimbra-client_contacts_card .zimbra-client_contacts_tagPills .zimbra-client_contacts_alignBaseline > span:not([role="img"]){
  padding:0 0 0 var(--spacing-sm);
}
@media (max-width: 769px){
  .zimbra-client_contacts_card .zimbra-client_contacts_tagPills{
    display:block;
  }
}
.zimbra-client_contacts_card .zimbra-client_contacts_type{
  color:var(--gray);
  font-weight:400;
  margin-bottom:6px;
}
.zimbra-client_contacts_card .zimbra-client_contacts_primaryContactInfo,
.zimbra-client_contacts_card .zimbra-client_contacts_addressInfo,
.zimbra-client_contacts_card .zimbra-client_contacts_secondaryContactInfo{
  width:100%;
}
.zimbra-client_contacts_card .zimbra-client_contacts_primaryContactInfo .zimbra-client_contacts_expiredCertLabel{
  color:var(--brand-danger-500);
}
.zimbra-client_contacts_card .zimbra-client_contacts_primaryContactInfo .zimbra-client_contacts_smimePubCertShieldIcon{
  display:inline-block;
  transform:translate(0, -2px);
  margin-right:2px;
}
.zimbra-client_contacts_importContacts{
  padding:48px 40px;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_contacts_importContacts .zimbra-client_contacts_providerContent h3,
.zimbra-client_contacts_importContacts h2{
  font-size:var(--font-size-h4);
  font-weight:300;
}
.zimbra-client_contacts_importContacts h2{
  padding-bottom:var(--spacing-md);
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_contacts_importContacts .zimbra-client_contacts_providerList{
  list-style:none;
  margin:0;
  padding:0;
}
.zimbra-client_contacts_importContacts .zimbra-client_contacts_providerList li{
  padding:calc(var(--spacing-md) + var(--spacing-xs)) 0;
}
.zimbra-client_contacts_importContacts .zimbra-client_contacts_providerIcon{
  color:#3e5c97;
  font-size:3.7rem;
}
.zimbra-client_contacts_importContacts .zimbra-client_contacts_providerContent h3{
  margin-top:2px;
  margin-bottom:var(--spacing-xs);
}
.zimbra-client_contacts_importContacts .zimbra-client_contacts_providerContent p{
  margin-top:0;
  margin-bottom:0;
}
.zimbra-client_contacts_sidePane{
  padding:var(--spacing-sm) calc(var(--spacing-xl) + var(--spacing-sm));
  height:100%;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_itemContent h3,
.zimbra-client_contacts_sidePane h2{
  font-weight:300;
}
.zimbra-client_contacts_sidePane h2{
  font-size:var(--font-size-h3);
  padding-bottom:var(--spacing-md);
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_actionItemList{
  list-style:none;
  margin:0;
  padding:0;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_actionItemList li{
  padding:calc(var(--spacing-md) + var(--spacing-xs)) 0;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_icon{
  color:var(--gray-light);
  font-size:3.2rem;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_iconEmail{
  font-size:2.8rem;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_iconSm{
  font-size:2.4rem;
  line-height:1.5;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_itemContent h3{
  font-size:var(--font-size-h4);
  margin-top:2px;
  margin-bottom:var(--spacing-xs);
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_itemContent p{
  margin-top:0;
  margin-bottom:var(--spacing-md);
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_mediaAction{
  margin:0;
}
.zimbra-client_contacts_sidePane .zimbra-client_contacts_mediaAction.zimbra-client_contacts_disabledAction{
  cursor:default;
}
.zimbra-client_contacts_sidebarSectionHeaderIcon{
  background-repeat:no-repeat;
  display:block;
  width:20px;
  height:19px;
  position:absolute;
  left:15px;
  top:14px;
}
.zimbra-client_contacts_sidebarSectionHeader{
  position:relative;
  display:flex;
  align-items:center;
  justify-content:space-between;
  height:44px;
  padding:0 10px 0 50px;
  font-family:var(--branding-font-family-bold);
  font-size:var(--font-size-base);
}
.zimbra-client_contacts_sidebarSectionHeaderCompose{
  display:block;
  font-size:20px;
  color:var(--gray) !important;
}
.zimbra-client_contacts_viewCert{
  color:var(--brand-tertiary-500);
  cursor:pointer;
  padding-right:3px;
  font-weight:normal;
  padding-top:7px;
  display:block;
}
.zimbra-client_contacts_deleted{
  color:var(--color-light-negative);
}
.zimbra-client_contacts_deleted.zimbra-client_contacts_list{
  font-weight:normal;
}
.zimbra-client_contacts_trashedContact{
  text-decoration:line-through;
}
.zimbra-client_contacts_avatar{
  position:relative;
  z-index:1;
  width:128px;
  height:128px;
  text-align:center;
  color:white;
  font-size:80px;
  margin:0 auto var(--spacing-md);
}
@media (min-width: 769px){
  .zimbra-client_contacts_avatar{
    width:110px;
    height:110px;
    float:right;
  }
}
.zimbra-client_contacts_mobileEmail{
  color:var(--gray-dark);
  background-color:var(--body-bg);
  position:relative;
  top:-2px;
  left:18px;
}
