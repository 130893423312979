.zimbra-client_close-button_close{
  width:34px;
  height:34px;
  display:block;
  border-radius:3px;
  border:1px solid transparent;
  padding:var(--spacing-xs);
  line-height:1;
}
.zimbra-client_close-button_close.zimbra-client_close-button_inverse:hover{
  background:#ffffff;
}
.zimbra-client_close-button_close:hover{
  border-color:var(--border-color);
  background:var(--background-gray);
}
