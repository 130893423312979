@media (max-width: 480px){
  .zimbra-client_calendar-icon_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_calendar-icon_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_calendar-icon_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_calendar-icon_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_calendar-icon_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_calendar-icon_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_calendar-icon_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_calendar-icon_icon[class]{
  vertical-align:text-bottom;
  margin-right:var(--spacing-xs);
}
