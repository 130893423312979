.blocks_progress-button_btnProgress{
  display:block;
  white-space:nowrap;
  background:var(--brand-primary-500);
  border:0;
  text-align:center;
  position:relative;
  line-height:inherit;
  cursor:pointer;
  color:var(--text-color-light);
}
.blocks_progress-button_btnProgress > span{
  position:relative;
}
.blocks_progress-button_btnProgress.blocks_progress-button_disabledButton{
  opacity:1;
  box-shadow:none;
}
.blocks_progress-button_progress{
  width:0%;
  transition:all 0.3s ease;
  background:var(--brand-primary-200);
  border-radius:0 3px 3px 0;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
}
