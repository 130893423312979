@media (max-width: 480px){
  .zimbra-client_contacts_photo-upload_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_contacts_photo-upload_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_contacts_photo-upload_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_contacts_photo-upload_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contacts_photo-upload_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contacts_photo-upload_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_contacts_photo-upload_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_contacts_photo-upload_photoContainer{
  width:100%;
  height:100%;
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoThumbnail{
  font-size:70px;
  width:100%;
  height:100%;
  border-radius:50%;
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoThumbnail.zimbra-client_contacts_photo-upload_icon{
  padding:20px;
  background-color:var(--gray-lighter);
  color:white;
  display:block;
}
@media (max-width: 1025px){
  .zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoThumbnail{
    font-size:50px;
  }
  .zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoThumbnail.zimbra-client_contacts_photo-upload_icon{
    padding:10px;
  }
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_spinner{
  position:absolute;
  top:50%;
  transform:translateY(-50%);
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoOverlay{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
  cursor:pointer;
  border-radius:50%;
  transition:opacity 0.5s ease;
  background-color:rgba(0, 0, 0, 0.5);
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoOverlay:hover{
  opacity:1;
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoOverlay .zimbra-client_contacts_photo-upload_photoOverlayContent{
  position:absolute;
  top:50%;
  left:50%;
  font-size:var(--font-size-base);
  color:white;
  transform:translate(-50%, -50%);
}
.zimbra-client_contacts_photo-upload_photoContainer .zimbra-client_contacts_photo-upload_photoOverlay .zimbra-client_contacts_photo-upload_photoOverlayContent .zimbra-client_contacts_photo-upload_photoOverlayIcon{
  font-size:var(--font-size-h3);
  display:block;
  text-align:center;
  padding-bottom:3px;
}
