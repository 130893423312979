@media (max-width: 480px){
  .zimbra-client_header-actions_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_header-actions_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_header-actions_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_header-actions_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_header-actions_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_header-actions_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_header-actions_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_header-actions_headerActions{
  font-family:var(--branding-font-family-medium);
  white-space:nowrap;
  display:flex;
  align-items:center;
}
@media (min-width: 1025px){
  .zimbra-client_header-actions_headerActions{
    padding-right:12px;
  }
}
.zimbra-client_header-actions_headerAction{
  color:var(--header-actions-fg);
  margin-left:var(--spacing-sm);
  opacity:0.8;
  display:flex;
}
.zimbra-client_header-actions_headerAction:hover,
.zimbra-client_header-actions_headerAction:hover[href],
.zimbra-client_header-actions_headerAction:focus[href]{
  color:var(--header-actions-fg);
  text-decoration:none;
  cursor:pointer;
  opacity:1;
}
.zimbra-client_header-actions_headerActionButton{
  height:auto;
}
@media (max-width: 768px){
  .zimbra-client_header-actions_headerActionButton{
    margin-top:1px;
    vertical-align:middle;
  }
}
.zimbra-client_header-actions_headerActionIcon{
  font-size:28px;
}
@media (min-width: 1025px){
  .zimbra-client_header-actions_headerActionIcon{
    margin-right:4px;
  }
}
.zimbra-client_header-actions_headerActionImage{
  width:28px;
  height:28px;
  border-radius:50%;
}
@media (min-width: 1025px){
  .zimbra-client_header-actions_headerActionImage{
    margin-right:4px;
  }
}
.zimbra-client_header-actions_headerActionTitle{
  display:none;
}
@media (min-width: 1025px){
  .zimbra-client_header-actions_headerActionTitle{
    display:flex;
    align-items:center;
    font-size:var(--font-size-base);
    font-weight:500;
    line-height:1;
  }
}
.zimbra-client_header-actions_headerActionTitle .zimbra-client_header-actions_headerActionName{
  display:flex;
  align-items:center;
}
.zimbra-client_header-actions_settingsIcon.zimbra-client_header-actions_supportClass{
  color:var(--header-actions-fg);
}
.zimbra-client_header-actions_settingsIcon{
  font-size:28px;
}
.zimbra-client_header-actions_settingsIcon:hover{
  color:var(--brand-primary-500);
  border-color:var(--border-color);
  background:#ffffff;
  display:block;
  border-radius:3px;
  padding:2px;
  border:1px solid var(--border-color);
}
.zimbra-client_header-actions_settingsActionButton{
  width:34px;
  height:34px;
  padding:0px;
}
@media (max-width: 768px){
  .zimbra-client_header-actions_changePassword[role="dialog"]{
    top:10px;
    transform:translate(-50%, 0);
  }
}
