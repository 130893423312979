.blocks_tooltip_tooltip{
  position:absolute;
  background-color:white;
  z-index:100;
  box-shadow:2px 2px 10px rgba(0, 0, 0, 0.1);
}
.blocks_tooltip_tooltip,
.blocks_tooltip_tooltip *{
  padding:0;
  margin:0;
}
