@media (max-width: 480px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_smime-and-encryption_certificate-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper{
  width:770px;
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfoMainWrapper .zimbra-client_smime-and-encryption_certificate-modal_section{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfoMainWrapper .zimbra-client_smime-and-encryption_certificate-modal_section:last-child{
  margin-bottom:0;
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfoMainWrapper .zimbra-client_smime-and-encryption_certificate-modal_section .zimbra-client_smime-and-encryption_certificate-modal_title{
  display:block;
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfoMainWrapper .zimbra-client_smime-and-encryption_certificate-modal_section .zimbra-client_smime-and-encryption_certificate-modal_certInfoWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfo{
  display:flex;
  margin-bottom:calc(var(--spacing-sm) - 3);
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfoMainWrapper .zimbra-client_smime-and-encryption_certificate-modal_section .zimbra-client_smime-and-encryption_certificate-modal_certInfoWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfo .zimbra-client_smime-and-encryption_certificate-modal_name{
  width:200px;
}
.zimbra-client_smime-and-encryption_certificate-modal_settings .zimbra-client_smime-and-encryption_certificate-modal_certificateWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfoMainWrapper .zimbra-client_smime-and-encryption_certificate-modal_section .zimbra-client_smime-and-encryption_certificate-modal_certInfoWrapper .zimbra-client_smime-and-encryption_certificate-modal_certInfo .zimbra-client_smime-and-encryption_certificate-modal_val{
  flex:1;
  word-break:break-all;
}
