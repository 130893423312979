@media (max-width: 480px){
  .zimbra-client_search_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_search_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_search_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_search_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_search_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_search_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_search_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_search_searchContainer{
  display:flex;
  flex:1;
  justify-content:flex-end;
  height:100%;
  align-items:center;
}
.zimbra-client_search_headerSearch{
  height:auto;
}
.zimbra-client_search_search{
  display:flex;
  justify-content:flex-end;
  flex:1;
  font-size:var(--font-size-base);
  height:100%;
}
.zimbra-client_search_searchControl{
  display:flex;
  position:relative;
  flex:1;
  max-width:600px;
  margin-right:12px;
}
@media (max-width: 1024px){
  .zimbra-client_search_searchControl{
    max-width:100%;
    height:calc(var(--mobile-toolbar-height) - 3px);
    margin-right:0;
  }
}
.zimbra-client_search_searchDropdown{
  display:inline-block;
  padding:0.375rem 1rem;
  color:var(--gray-light);
  border-right:1px solid var(--gray-lighter);
}
.zimbra-client_search_searchScreen{
  align-self:center;
  flex:initial;
}
.zimbra-client_search_searchInput{
  display:inline-block;
  width:80%;
  padding:0.375rem 1rem;
  border:0;
  outline:none;
}
.zimbra-client_search_searchInput::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_search_searchInput:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_search_searchInput::placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_search_searchIn{
  width:59px;
  height:16px;
  padding:1.25rem 2.188rem;
  font-size:var(--font-size-med);
  font-weight:bold;
  font-style:normal;
  font-stretch:normal;
  line-height:normal;
  letter-spacing:normal;
  text-align:left;
  color:var(--gray-base);
}
.zimbra-client_search_allMail{
  padding-top:0.6rem;
  font-size:var(--font-size-base);
  font-weight:bold;
  font-style:normal;
  font-stretch:normal;
  line-height:normal;
  letter-spacing:normal;
  text-align:left;
  color:#4d4d4d;
}
.zimbra-client_search_specificMail{
  font-size:var(--font-size-base);
  font-weight:normal;
  font-style:normal;
  font-stretch:normal;
  line-height:normal;
  letter-spacing:normal;
  text-align:left;
  color:#4d4d4d;
}
