@media (max-width: 480px){
  .zimbra-client_error-tooltip_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_error-tooltip_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_error-tooltip_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_error-tooltip_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_error-tooltip_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_error-tooltip_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_error-tooltip_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_error-tooltip_error{
  color:var(--color-light-negative);
  position:absolute;
  top:10px;
  right:25px;
}
