@media (max-width: 480px){
  .zimbra-client_animated-checkmark_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_animated-checkmark_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_animated-checkmark_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_animated-checkmark_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_animated-checkmark_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_animated-checkmark_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_animated-checkmark_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_animated-checkmark_circle{
  stroke-dasharray:166;
  stroke-dashoffset:166;
  stroke-width:2;
  stroke-miterlimit:10;
  stroke:var(--brand-success-500);
  fill:none;
  -webkit-animation:zimbra-client_animated-checkmark_stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation:zimbra-client_animated-checkmark_stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.zimbra-client_animated-checkmark_checkmark{
  width:32px;
  height:32px;
  border-radius:50%;
  display:block;
  stroke-width:2;
  stroke:#fff;
  stroke-miterlimit:10;
  box-shadow:inset 0px 0px 0px var(--brand-success-500);
  -webkit-animation:zimbra-client_animated-checkmark_fill 0.4s ease-in-out 0.4s forwards, zimbra-client_animated-checkmark_scale 0.3s ease-in-out 0.9s both;
          animation:zimbra-client_animated-checkmark_fill 0.4s ease-in-out 0.4s forwards, zimbra-client_animated-checkmark_scale 0.3s ease-in-out 0.9s both;
}
.zimbra-client_animated-checkmark_check{
  transform-origin:50% 50%;
  stroke-dasharray:48;
  stroke-dashoffset:48;
  -webkit-animation:zimbra-client_animated-checkmark_stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation:zimbra-client_animated-checkmark_stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@-webkit-keyframes zimbra-client_animated-checkmark_stroke{
  100%{
    stroke-dashoffset:0;
  }
}
@keyframes zimbra-client_animated-checkmark_stroke{
  100%{
    stroke-dashoffset:0;
  }
}
@-webkit-keyframes zimbra-client_animated-checkmark_scale{
  0%,
  100%{
    transform:none;
  }
  50%{
    transform:scale3d(1.1, 1.1, 1);
  }
}
@keyframes zimbra-client_animated-checkmark_scale{
  0%,
  100%{
    transform:none;
  }
  50%{
    transform:scale3d(1.1, 1.1, 1);
  }
}
@-webkit-keyframes zimbra-client_animated-checkmark_fill{
  100%{
    box-shadow:inset 0px 0px 0px 30px var(--brand-success-500);
  }
}
@keyframes zimbra-client_animated-checkmark_fill{
  100%{
    box-shadow:inset 0px 0px 0px 30px var(--brand-success-500);
  }
}
