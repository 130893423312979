.zimbra-client_dumpster_hideOverlay{
  background:none;
}
[role="dialog"].zimbra-client_dumpster_dialog{
  height:100%;
  max-height:600px;
}
[role="dialog"].zimbra-client_dumpster_dialog .zimbra-client_dumpster_header h2{
  margin:0;
  font-size:20px;
  font-weight:normal;
}
[role="dialog"].zimbra-client_dumpster_dialog .zimbra-client_dumpster_msgDialogContent{
  padding-left:0;
  padding-right:0;
  overflow:hidden;
  display:flex;
  flex-direction:column;
}
[role="dialog"].zimbra-client_dumpster_dialog .zimbra-client_dumpster_footer{
  border-top:1px solid var(--gray-lighter);
}
[role="dialog"].zimbra-client_dumpster_dialog .zimbra-client_dumpster_footer .zimbra-client_dumpster_selectionState{
  position:absolute;
  right:14px;
  top:30px;
  font-size:var(--font-size-base);
}
.zimbra-client_dumpster_query{
  position:relative;
  padding:5px 20px;
}
.zimbra-client_dumpster_query input{
  width:100%;
  max-width:100%;
  margin:0;
  padding:var(--spacing-sm) var(--spacing-xl) var(--spacing-sm) 10px;
  background:#FFF;
  border:1px solid var(--gray-lighter);
  font-size:var(--font-size-base);
  border-radius:3px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  outline:none;
}
.zimbra-client_dumpster_query input::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_dumpster_query input:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_dumpster_query input::placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_dumpster_query input:focus{
  border-color:var(--brand-primary-500);
}
.zimbra-client_dumpster_query .zimbra-client_dumpster_searchInputIcon{
  color:var(--gray-light);
  position:absolute;
  right:22px;
  top:var(--spacing-sm);
  height:var(--spacing-lg);
  line-height:var(--spacing-lg);
}
.zimbra-client_dumpster_query .zimbra-client_dumpster_searchInputIcon:hover,
.zimbra-client_dumpster_query .zimbra-client_dumpster_searchInputIcon:focus{
  color:var(--brand-primary-500);
}
.zimbra-client_dumpster_noMsgs{
  display:block;
  padding:var(--spacing-xl) 0;
  text-align:center;
  font-weight:normal;
  color:var(--gray-light);
}
.zimbra-client_dumpster_msgListPane{
  position:relative;
  margin-top:10px;
  border-top:1px solid var(--border-color);
  overflow-y:auto;
  outline:none;
  flex-direction:column;
  flex:1;
}
[role="dialog"].zimbra-client_dumpster_folderModal{
  height:422px;
}
@media (max-height: 422px){
  [role="dialog"].zimbra-client_dumpster_folderModal{
    height:100%;
  }
}
[role="dialog"].zimbra-client_dumpster_folderModal .zimbra-client_dumpster_folderDialogContent{
  border-top:1px solid var(--gray-lighter);
  border-bottom:1px solid var(--gray-lighter);
  padding:var(--spacing-sm);
  background-color:var(--gray-lightest);
}
.zimbra-client_dumpster_folderDrawer .zimbra-client_dumpster_containerClass header.zimbra-client_dumpster_headerClass{
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_dumpster_folderDrawer .zimbra-client_dumpster_containerClass header.zimbra-client_dumpster_headerClass h2{
  padding:22px 20px;
}
