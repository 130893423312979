@media (max-width: 480px){
  .zimbra-client_color-picker_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_color-picker_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_color-picker_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_color-picker_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_color-picker_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_color-picker_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_color-picker_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_color-picker_colorPicker{
  display:flex;
  flex-wrap:wrap;
  padding:0 8px;
}
.zimbra-client_color-picker_colorPickerContainer{
  display:flex;
  align-items:center;
}
.zimbra-client_color-picker_colorInputContainer{
  position:relative;
  cursor:pointer;
  padding:8px;
}
@media (max-width: 1024px){
  .zimbra-client_color-picker_colorInputContainer{
    margin:7px;
  }
}
.zimbra-client_color-picker_colorInput{
  width:12px;
  height:12px;
  border-radius:1px;
}
.zimbra-client_color-picker_colorInputHighlight{
  position:absolute;
  top:6px;
  bottom:6px;
  left:6px;
  right:6px;
  border:solid 1px var(--brand-primary-500);
  border-radius:2px;
  background-color:transparent;
}
