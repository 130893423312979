.zimbra-client_external-header-item_externalHeaderItem{
  color:var(--external-header-fg);
  text-decoration:none;
  display:inline-block;
  padding:0 0.6rem;
  line-height:var(--external-header-height);
  font-size:var(--font-size-small);
}
.zimbra-client_external-header-item_externalHeaderItem:focus[href],
.zimbra-client_external-header-item_externalHeaderItem:hover[href]{
  color:var(--external-header-fg);
  text-decoration:underline;
}
