@media (max-width: 480px){
  .zimbra-client_settings_sharing-settings_common_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_sharing-settings_common_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_sharing-settings_common_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_sharing-settings_common_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_common_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_common_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_sharing-settings_common_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection{
  display:flex;
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection:not(:first-child){
  margin-top:var(--spacing-sm);
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_common_sharingSubsection{
    display:block;
  }
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_subsectionTitle{
  min-width:220px;
  padding:2px var(--spacing-md) 0 0;
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_subsectionTitle{
    color:var(--gray);
    width:100%;
    padding:0;
  }
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerBody{
  display:flex;
  width:100%;
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerBody .zimbra-client_settings_sharing-settings_common_viewTypeIcon{
  flex-grow:1;
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerBody .zimbra-client_settings_sharing-settings_common_viewTypeIcon .zimbra-client_settings_sharing-settings_common_viewTypeText{
  position:relative;
  margin-left:var(--spacing-xs);
  top:3px;
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerBody .zimbra-client_settings_sharing-settings_common_calIconText{
  position:absolute;
  margin-top:var(--spacing-sm);
  margin-left:6px;
  font-size:10px;
  font-weight:bold;
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerKey{
  cursor:pointer;
  width:100%;
  font-weight:bold;
}
@media (max-width: 1024px){
  .zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerKey{
    display:flex;
  }
}
.zimbra-client_settings_sharing-settings_common_sharingSubsection .zimbra-client_settings_sharing-settings_common_headerKey .zimbra-client_settings_sharing-settings_common_name{
  flex-grow:1;
  color:var(--gray-darkest);
}
