.zimbra-client_external-header-dropdown_actionButton{
  color:var(--external-header-fg);
  text-decoration:none;
  display:inline-block;
  padding:0 0.6rem;
  line-height:var(--external-header-height);
  height:var(--external-header-height);
  font-size:var(--font-size-small);
}
.zimbra-client_external-header-dropdown_actionButton .zimbra-client_external-header-dropdown_caret{
  position:relative;
  right:-2px;
}
.zimbra-client_external-header-dropdown_actionButton:focus,
.zimbra-client_external-header-dropdown_actionButton:hover{
  color:var(--external-header-fg);
  text-decoration:none;
}
.zimbra-client_external-header-dropdown_actionButton .zimbra-client_external-header-dropdown_label{
  font-size:var(--font-size-small);
}
.zimbra-client_external-header-dropdown_popover{
  min-width:0;
}
.zimbra-client_external-header-dropdown_popover .zimbra-client_external-header-dropdown_item{
  padding:var(--spacing-sm) var(--spacing-md);
}
