.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails{
  display:flex;
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_userDetails label{
  width:100%;
  padding:0 0 0 var(--spacing-md);
  text-transform:none;
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_userDetails label span{
  margin-right:var(--spacing-xs);
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_userDetails label.zimbra-client_contacts_editor_public-cert-section_expiredCertLabel{
  color:var(--brand-danger-500);
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_userDetails label .zimbra-client_contacts_editor_public-cert-section_smimePubCertShieldIcon{
  display:inline-block;
  transform:translate(0, -2px);
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_buttonsContainer{
  display:flex;
  flex-direction:row;
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_buttonsContainer .zimbra-client_contacts_editor_public-cert-section_buttonDivider{
  padding:0 var(--spacing-sm);
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_buttonsContainer .zimbra-client_contacts_editor_public-cert-section_buttonDivider .zimbra-client_contacts_editor_public-cert-section_button{
  color:var(--brand-tertiary-500);
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertDetails .zimbra-client_contacts_editor_public-cert-section_buttonsContainer .zimbra-client_contacts_editor_public-cert-section_buttonDivider:not(:last-child){
  border-right:solid 1px #979799;
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertInputField label{
  display:inline;
  padding:0 var(--spacing-md);
  float:none;
  text-transform:none;
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertInputField .zimbra-client_contacts_editor_public-cert-section_certReadErrStatusMsg{
  color:var(--brand-danger-500);
}
.zimbra-client_contacts_editor_public-cert-section_userCertSection .zimbra-client_contacts_editor_public-cert-section_userCertInputField .zimbra-client_contacts_editor_public-cert-section_uploadCertBtn{
  color:var(--brand-tertiary-500);
  cursor:pointer;
}
