@media (max-width: 480px){
  .zimbra-client_ad-slots_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_ad-slots_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_ad-slots_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_ad-slots_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_ad-slots_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_ad-slots_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_ad-slots_hideMdUp{
    display:none !important;
  }
}
@media (min-width: 1300px){
  .zimbra-client_ad-slots_rightAdZimletSlot{
    height:100%;
    background-color:var(--gray-lightest);
    width:auto;
    overflow:hidden;
    overflow-y:auto;
  }
  .zimbra-client_ad-slots_rightAdZimletSlot span:empty{
    display:none;
  }
  .zimbra-client_ad-slots_rightAdZimletSlot .zimbra-client_ad-slots_adSlotHorizontal{
    width:300px;
    max-height:250px;
    margin:var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
    box-sizing:border-box;
    display:block;
    overflow:hidden;
  }
  .zimbra-client_ad-slots_rightAdZimletSlot .zimbra-client_ad-slots_adSlotVertical{
    max-height:600px;
    margin:var(--spacing-md) auto;
    padding:0 var(--spacing-md);
    box-sizing:border-box;
    display:block;
    overflow:hidden;
  }
  .zimbra-client_ad-slots_rightAdZimletSlot .zimbra-client_ad-slots_adSlotVertical.zimbra-client_ad-slots_narrow{
    width:calc(160px + calc(var(--spacing-md) * 2));
  }
  .zimbra-client_ad-slots_rightAdZimletSlot .zimbra-client_ad-slots_adSlotVertical.zimbra-client_ad-slots_wide{
    width:calc(300px + calc(var(--spacing-md) * 2));
  }
  .zimbra-client_ad-slots_rightAdZimletSlot .zimbra-client_ad-slots_newsSlot{
    width:300px;
    margin:var(--spacing-md);
    box-sizing:border-box;
    display:block;
    overflow:hidden;
  }
  .zimbra-client_ad-slots_leftAdZimletSlot .zimbra-client_ad-slots_adSlotLeft{
    overflow:hidden;
    display:block;
    margin:12px auto;
  }
  .zimbra-client_ad-slots_leftAdZimletSlot .zimbra-client_ad-slots_adSlotLeftLarge{
    width:300px;
    max-height:250px;
  }
  .zimbra-client_ad-slots_leftAdZimletSlot .zimbra-client_ad-slots_adSlotLeftMedium{
    width:180px;
    max-height:150px;
  }
  .zimbra-client_ad-slots_leftAdZimletSlot .zimbra-client_ad-slots_adSlotLeftSmall{
    width:120px;
    max-height:90px;
  }
  .zimbra-client_ad-slots_leftAdZimletSlot span:empty{
    display:none;
  }
}
.zimbra-client_ad-slots_zimletAdWrapper{
  position:fixed;
  bottom:0;
  width:100%;
  display:flex;
  justify-content:center;
}
.zimbra-client_ad-slots_zimletAdWrapper .zimbra-client_ad-slots_adSlotBottomMd{
  width:auto;
  max-height:122px;
}
.zimbra-client_ad-slots_zimletAdWrapper .zimbra-client_ad-slots_adSlotBottomXs{
  width:100%;
  max-height:82px;
}
