@media (max-width: 480px){
  .zimbra-client_sidebar-primary-button_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_sidebar-primary-button_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_sidebar-primary-button_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_sidebar-primary-button_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_sidebar-primary-button_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_sidebar-primary-button_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_sidebar-primary-button_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_sidebar-primary-button_button.zimbra-client_sidebar-primary-button_sidebarPrimaryButton{
  text-transform:uppercase;
  font-size:var(--font-size-small);
  font-weight:700;
  margin:var(--spacing-sm) 0;
  display:block;
  white-space:nowrap;
  padding:9px 0;
  width:159px;
}
.zimbra-client_sidebar-primary-button_button.zimbra-client_sidebar-primary-button_sidebarPrimaryButton.zimbra-client_sidebar-primary-button_buttonLink{
  box-shadow:none;
  background-color:transparent;
  color:var(--text-color);
  text-align:left;
}
.zimbra-client_sidebar-primary-button_button.zimbra-client_sidebar-primary-button_sidebarPrimaryButton.zimbra-client_sidebar-primary-button_buttonLink:hover,
.zimbra-client_sidebar-primary-button_button.zimbra-client_sidebar-primary-button_sidebarPrimaryButton.zimbra-client_sidebar-primary-button_buttonLink:focus{
  background-color:transparent !important;
  color:var(--text-color);
  text-decoration:none;
}
.zimbra-client_sidebar-primary-button_button .zimbra-client_sidebar-primary-button_icon{
  color:var(--brand-primary-500);
  margin-right:10px;
  font-size:var(--icon-size-sm);
  position:relative;
  top:-2px;
}
