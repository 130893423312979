.zimbra-client_tags_tag-pill_pill{
  display:inline-block;
  max-width:153px;
  vertical-align:text-top;
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  color:var(--gray-darkest);
  margin-right:var(--spacing-sm);
  margin-bottom:var(--spacing-sm);
  height:20px;
  cursor:pointer;
}
.zimbra-client_tags_tag-pill_pill .zimbra-client_tags_tag-pill_name{
  display:inline-block;
  max-width:125px;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  padding:0 0 0 var(--spacing-sm);
  font-size:var(--font-size-base);
  margin-right:6px;
  vertical-align:top;
}
.zimbra-client_tags_tag-pill_pill .zimbra-client_tags_tag-pill_icon{
  display:inline-block;
  padding:2px;
  vertical-align:top;
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  color:var(--gray-darkest);
}
.zimbra-client_tags_tag-pill_pill .zimbra-client_tags_tag-pill_icon:hover,
.zimbra-client_tags_tag-pill_pill .zimbra-client_tags_tag-pill_icon:focus{
  color:white;
  background-color:var(--gray);
}
