@media (max-width: 480px){
  .zimbra-client_contact-card-menu_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_contact-card-menu_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_contact-card-menu_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_contact-card-menu_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-card-menu_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-card-menu_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_contact-card-menu_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_contact-card-menu_menuButton{
  height:32px;
  padding:0 2px;
}
.zimbra-client_contact-card-menu_actionMenu .zimbra-client_contact-card-menu_menu{
  top:85%;
  min-width:100px;
}
.zimbra-client_contact-card-menu_actionMenu .zimbra-client_contact-card-menu_menu a{
  padding-right:40px;
  padding-left:10px;
}
