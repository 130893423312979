@media (max-width: 480px){
  .zimbra-client_calendar_appointment-edit_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_calendar_appointment-edit_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_calendar_appointment-edit_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_calendar_appointment-edit_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_calendar_appointment-edit_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper{
  display:flex;
  flex-direction:column;
  height:100%;
  width:100%;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_header{
  border-bottom:1px solid var(--gray-lighter);
  padding:14px var(--spacing-lg);
  margin:0;
  position:relative;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_header h2{
  margin:0;
  padding:0;
  font-size:var(--font-size-h4);
  font-weight:400;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_header .zimbra-client_calendar_appointment-edit_actionButton{
  top:10px;
  right:var(--spacing-md);
  width:var(--spacing-lg);
  height:var(--spacing-lg);
  padding:0;
  border-radius:50%;
  transition:all 150ms ease;
  position:absolute;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_simpleHeader{
  padding:0 0 var(--spacing-md) 0;
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_simpleHeader{
    padding:var(--spacing-md) var(--spacing-lg) 0 var(--spacing-lg);
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_simpleHeader h2{
  margin:0;
  padding:0;
  font-size:var(--font-size-h4);
  font-weight:400;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper{
  padding:var(--spacing-md) var(--spacing-lg);
  display:block;
  flex:1;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_alignedForm{
  display:flex;
  flex-direction:column;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_privateWrapper{
  padding-left:var(--spacing-sm);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_alignedLabel{
  display:block;
  width:110px;
  min-width:110px;
  padding-right:var(--spacing-sm);
  align-self:baseline;
  padding-top:var(--spacing-xs);
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_alignedLabel{
    min-width:60px !important;
    width:60px !important;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_alignedLabel.zimbra-client_calendar_appointment-edit_disabledElement a{
  color:var(--gray-darkest);
  text-decoration:none;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_fieldOffset{
  margin-left:110px;
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_fieldOffset{
    margin-left:60px;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_dateSelector{
  margin-right:var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_dateSelector{
    margin-bottom:var(--spacing-sm);
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeSelector{
  padding:5px 12px;
  margin-right:var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeSelector{
    padding:5px 0 5px 12px;
    width:110px;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeZoneSelector{
  margin:0 12px 0 var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeZoneSelector{
    margin:0 12px 0 0;
  }
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeZoneSelector select{
    width:180px;
  }
}
@media (max-width: 480px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeZoneSelector{
    margin:var(--spacing-sm) 12px 0 0;
  }
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_timeZoneSelector select{
    width:180px;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_allDay{
  margin-top:var(--spacing-sm);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_subOption{
  display:block;
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_subOption:last-child{
  margin-bottom:0;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_inviteesGroup{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_addressField{
  flex:1;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  background-color:var(--body-bg);
  border:solid 1px var(--gray-lighter);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_availabilityIndicatorGroup{
  margin-left:110px;
  width:auto;
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_availabilityIndicatorGroup{
    margin-left:60px;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_availabilityButton{
  margin:var(--spacing-sm) 0;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_notesContainer{
  border:solid 1px var(--gray-lighter);
  border-radius:3px;
  height:250px;
  display:flex;
  flex-direction:column;
  flex:1;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_notesContainer .mce-item-table,
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_notesContainer .mce-item-table caption,
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_notesContainer .mce-item-table td,
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_notesContainer .mce-item-table th{
  border:none;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_notesContainer .zimbra-client_calendar_appointment-edit_notesToolbar{
  border-top:solid 1px var(--gray-lighter);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_attachmentContainer{
  margin-left:110px;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_attachments{
  margin-left:10px;
  margin-right:var(--spacing-xs);
  display:inline-block;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect{
  padding:var(--spacing-sm) var(--spacing-xs);
  width:150px;
  border:solid 1px var(--border-color);
  border-radius:3px;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect > ul{
  max-width:250px;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOption{
  text-align:left;
  text-transform:none;
  background:var(--body-bg);
  color:var(--gray-base);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOption:hover,
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOption:focus{
  background:var(--gray-lightest);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOption > div[class*="itemTitle"]{
  float:left;
  padding-left:0;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOptionItem{
  display:flex;
  font-weight:normal;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOptionItem .zimbra-client_calendar_appointment-edit_calendarColor{
  margin-right:var(--spacing-sm);
  width:var(--spacing-md);
  height:var(--spacing-md);
  border:2px solid var(--body-bg);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_calendarOptionItem .zimbra-client_calendar_appointment-edit_calendarText{
  white-space:nowrap;
  text-transform:none;
  overflow:hidden;
  text-overflow:ellipsis;
  color:var(--gray-darkest);
  flex:1;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_toggleButtonClass{
  width:100%;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_toggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_calendarSelect .zimbra-client_calendar_appointment-edit_toggleButtonClass p{
  overflow:hidden;
  width:calc(100% - 14px);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_repeatInstanceBtn{
  width:400px;
  height:auto;
  text-align:left;
  border:solid 1px var(--gray-lighter);
  border-radius:3px;
  padding:calc(var(--spacing-sm) + 1px) calc(var(--spacing-xl) + 2px) calc(var(--spacing-sm) + 1px) calc(var(--spacing-md) - var(--spacing-xs));
  overflow:hidden;
  text-overflow:ellipsis;
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_repeatInstanceBtn{
    width:120px;
  }
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_formWrapper .zimbra-client_calendar_appointment-edit_repeatInstanceBtn [role="img"]{
  position:absolute;
  right:var(--spacing-md);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_footer{
  position:relative;
  bottom:0;
  flex:0 0 0;
  width:100%;
  height:64px;
  padding:0 20px;
  border-top:1px solid var(--gray-lighter);
  line-height:64px;
}
.zimbra-client_calendar_appointment-edit_wrapper label{
  padding:0;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_datepickerWrapper{
  display:flex;
  flex-wrap:wrap;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_disabledElement{
  color:var(--placeholder-color);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_disabledElement span{
  color:var(--placeholder-color) !important;
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_disabledElement button{
  color:var(--placeholder-color);
}
.zimbra-client_calendar_appointment-edit_wrapper .zimbra-client_calendar_appointment-edit_disabledElement :after{
  color:var(--placeholder-color);
}
.zimbra-client_calendar_appointment-edit_weekDayOption{
  margin:0 0 var(--spacing-md) 90px;
}
.zimbra-client_calendar_appointment-edit_weekDayOption ul.zimbra-client_calendar_appointment-edit_selectWeekDays{
  list-style:none;
  display:inline-block;
  margin-left:var(--spacing-xs);
}
.zimbra-client_calendar_appointment-edit_weekDayOption ul.zimbra-client_calendar_appointment-edit_selectWeekDays li{
  display:inline;
  margin:0 var(--spacing-xs);
}
.zimbra-client_calendar_appointment-edit_weekDayOption ul.zimbra-client_calendar_appointment-edit_selectWeekDays li label{
  display:inline-block;
  background-color:var(--gray-lightest);
  color:var(--gray-dark);
  border-radius:25px;
  margin:var(--spacing-xs) 0;
  width:var(--spacing-lg);
  height:var(--spacing-lg);
  padding:2px;
  box-sizing:border-box;
  text-align:center;
  font-size:var(--font-size-base);
  cursor:pointer;
}
.zimbra-client_calendar_appointment-edit_weekDayOption ul.zimbra-client_calendar_appointment-edit_selectWeekDays li input[type="checkbox"]{
  position:absolute;
  opacity:0;
  width:var(--spacing-lg);
  height:var(--spacing-lg);
}
.zimbra-client_calendar_appointment-edit_weekDayOption ul.zimbra-client_calendar_appointment-edit_selectWeekDays li input[type="checkbox"]:checked + label{
  background-color:#0088c1;
  color:var(--body-bg);
  transition:all 0.2s;
}
.zimbra-client_calendar_appointment-edit_toolbarAction{
  width:100%;
  height:100%;
  display:flex;
  justify-content:flex-end;
  flex:auto;
  padding:5px;
}
.zimbra-client_calendar_appointment-edit_toolbarAction .zimbra-client_calendar_appointment-edit_addEventAction{
  height:calc(var(--spacing-xl) + var(--spacing-xs));
  border-radius:2px;
}
.zimbra-client_calendar_appointment-edit_recurrenceEditModal .zimbra-client_calendar_appointment-edit_header{
  padding-bottom:var(--spacing-sm);
}
.zimbra-client_calendar_appointment-edit_recurrenceEditModal label{
  padding-bottom:5px;
  display:inline-block;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_header{
  color:var(--gray-darkest);
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_endingOption{
  padding-top:var(--spacing-md);
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_endingOption .zimbra-client_calendar_appointment-edit_dateSelector{
  display:inline-block;
  margin-left:var(--spacing-md);
  width:100px;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_endingOption .zimbra-client_calendar_appointment-edit_numOfOccurencesSelector{
  margin:0 var(--spacing-sm) 0 calc(var(--spacing-md) + 4px);
  width:60px;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_repeatInterval{
  width:56px;
  height:36px;
  margin:0 var(--spacing-sm) 0 var(--spacing-md);
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_repeatvalue{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_repeatvalue .zimbra-client_calendar_appointment-edit_selectButton{
  width:164px;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton{
  width:228px;
  border:solid 1px var(--border-color);
  border-radius:3px;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton > ul{
  width:100%;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton li.zimbra-client_calendar_appointment-edit_dropdownOption{
  text-align:left;
  background:none;
  color:var(--gray-darker);
  padding:var(--spacing-sm) calc(var(--spacing-md) - 1px);
  font-weight:normal;
  text-transform:none;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton li.zimbra-client_calendar_appointment-edit_dropdownOption:hover,
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton li.zimbra-client_calendar_appointment-edit_dropdownOption:focus{
  background:var(--gray-lightest);
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton li.zimbra-client_calendar_appointment-edit_dropdownOption > div[class*="itemTitle"]{
  float:none;
  padding-left:0;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton .zimbra-client_calendar_appointment-edit_toggleButtonClass{
  display:block;
  padding:var(--spacing-sm) calc(var(--spacing-xs) + 1px);
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton .zimbra-client_calendar_appointment-edit_toggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_selectButton .zimbra-client_calendar_appointment-edit_toggleButtonClass p{
  width:calc(100% - 14px);
  font-weight:normal;
  color:var(--gray-darkest);
  text-transform:none;
}
.zimbra-client_calendar_appointment-edit_customRecurrenceModal .zimbra-client_calendar_appointment-edit_monthYearOption{
  margin:0 0 var(--spacing-md) 90px;
}
.zimbra-client_calendar_appointment-edit_discardModalContent{
  padding-bottom:52px !important;
}
.zimbra-client_calendar_appointment-edit_inEventNotification{
  background-color:rgba(255, 184, 28, 0.2);
  padding:var(--spacing-sm) var(--spacing-md);
  margin-bottom:var(--spacing-md);
  max-width:446px;
  color:var(--gray-base);
  border-bottom:1px solid var(--brand-warning-500);
}
.zimbra-client_calendar_appointment-edit_inEventNotification.zimbra-client_calendar_appointment-edit_fullWidth{
  margin-bottom:10px;
  max-width:100%;
}
@media (max-width: 1024px){
  .zimbra-client_calendar_appointment-edit_inEventNotification.zimbra-client_calendar_appointment-edit_fullWidth{
    margin:var(--spacing-md) 0 0 0;
  }
}
.zimbra-client_calendar_appointment-edit_anchorLink{
  color:var(--link-color);
  padding-left:0;
}
.zimbra-client_calendar_appointment-edit_customRecurrText{
  white-space:pre-line;
  color:var(--text-color);
}
