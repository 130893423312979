@media (max-width: 480px){
  .blocks_dialog_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .blocks_dialog_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .blocks_dialog_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .blocks_dialog_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .blocks_dialog_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .blocks_dialog_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .blocks_dialog_hideMdUp{
    display:none !important;
  }
}
.blocks_dialog_overlay{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background-color:rgba(0, 0, 0, 0.6);
  z-index:999;
  margin:0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.blocks_dialog_noScroll{
  overflow:hidden;
}
.blocks_dialog_dialog{
  position:absolute;
  z-index:1000;
}
.blocks_dialog_dialog.blocks_dialog_fixed{
  position:fixed;
}
.blocks_dialog_dialog.blocks_dialog_centered{
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
