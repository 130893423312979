@media (max-width: 480px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_filters-settings_select-folder-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal{
  max-height:315px;
  overflow:auto;
  font-size:var(--font-size-base);
}
@media (max-width: 1025px){
  .zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul{
    margin-left:20px;
    list-style:none;
  }
  .zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul ul{
    margin-left:0;
  }
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul{
  padding-bottom:5px;
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul ul{
  padding-left:20px;
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul li .zimbra-client_settings_filters-settings_select-folder-modal_folderRow{
  line-height:var(--spacing-xl);
  margin:0;
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul li .zimbra-client_settings_filters-settings_select-folder-modal_folderRow > *{
  display:inline-block;
  vertical-align:middle;
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul li .zimbra-client_settings_filters-settings_select-folder-modal_folderRow .zimbra-client_settings_filters-settings_select-folder-modal_itemName{
  width:calc(100% - 50px);
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul li .zimbra-client_settings_filters-settings_select-folder-modal_folderRow.zimbra-client_settings_filters-settings_select-folder-modal_padLeft{
  padding-left:20px;
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul li .zimbra-client_settings_filters-settings_select-folder-modal_folderRow.zimbra-client_settings_filters-settings_select-folder-modal_accountRow .zimbra-client_settings_filters-settings_select-folder-modal_itemName{
  width:calc(100% - 28px);
}
.zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul li .zimbra-client_settings_filters-settings_select-folder-modal_folderExpandCollapse{
  cursor:pointer;
  display:inline-block;
  font-size:var(--font-size-small);
  outline:none;
}
@media (max-width: 1025px){
  .zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal{
    max-height:calc(100vh - 55px);
  }
  .zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul{
    margin-left:20px;
    list-style:none;
  }
  .zimbra-client_settings_filters-settings_select-folder-modal_selectFolderModal ul ul{
    margin-left:0;
  }
}
