@-webkit-keyframes blocks_loader-bar_slide1{
  from{
    left:-30%;
  }
  to{
    left:170%;
  }
}
@keyframes blocks_loader-bar_slide1{
  from{
    left:-30%;
  }
  to{
    left:170%;
  }
}
@-webkit-keyframes blocks_loader-bar_slide2{
  from{
    left:-100%;
  }
  to{
    left:100%;
  }
}
@keyframes blocks_loader-bar_slide2{
  from{
    left:-100%;
  }
  to{
    left:100%;
  }
}
.blocks_loader-bar_loader{
  position:relative;
  overflow:hidden;
  height:3px;
}
.blocks_loader-bar_loader:before,
.blocks_loader-bar_loader:after{
  content:'';
  position:absolute;
  background-color:inherit;
  top:0;
  left:-30%;
  bottom:0;
  will-change:left;
  width:30%;
  height:3px;
  background-color:var(--brand-primary-500);
  -webkit-animation-duration:1.5s;
          animation-duration:1.5s;
  -webkit-animation-iteration-count:infinite;
          animation-iteration-count:infinite;
  -webkit-animation-timing-function:linear;
          animation-timing-function:linear;
}
.blocks_loader-bar_loader:before{
  -webkit-animation-name:blocks_loader-bar_slide1;
          animation-name:blocks_loader-bar_slide1;
}
.blocks_loader-bar_loader:after{
  -webkit-animation-name:blocks_loader-bar_slide2;
          animation-name:blocks_loader-bar_slide2;
  -webkit-animation-delay:0.2s;
          animation-delay:0.2s;
}
