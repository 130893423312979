@media (max-width: 480px){
  .zimbra-client_folder-list_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_folder-list_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_folder-list_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_folder-list_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_folder-list_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_folder-list_divider{
  display:block;
  padding:8px 10px;
  background:rgba(0, 0, 0, 0.05);
  border:none;
  box-shadow:-1px 0 1px -1px black;
  text-transform:uppercase;
  color:var(--gray-darkest);
}
.zimbra-client_folder-list_dropFolderTarget{
  background:var(--folder-item-hover-bg);
}
.zimbra-client_folder-list_item{
  display:block;
  height:32px;
  padding:7px 32px;
  color:var(--text-color);
  border:none;
  text-decoration:none;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  padding:7px 0 7px 0;
  display:flex;
  align-items:center;
  position:relative;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.zimbra-client_folder-list_item:hover{
  color:var(--gray-darker);
  text-decoration:none;
}
.zimbra-client_folder-list_item[data-depth="2"]{
  padding-left:calc(32px * 1.5);
}
.zimbra-client_folder-list_item[data-depth="3"]{
  padding-left:calc(32px * 2);
}
.zimbra-client_folder-list_item[data-depth="4"]{
  padding-left:calc(32px * 2.5);
}
.zimbra-client_folder-list_item[data-depth="5"]{
  padding-left:calc(32px * 3);
}
.zimbra-client_folder-list_item[data-depth="6"]{
  padding-left:calc(32px * 3.5);
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_item{
    height:44px;
    padding:0 0 0 34px;
  }
}
.zimbra-client_folder-list_item.zimbra-client_folder-list_hasBadge{
  font-family:var(--branding-font-family-bold);
  font-weight:normal;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_item.zimbra-client_folder-list_hasBadge{
    font-family:var(--branding-font-family-regular);
    color:var(--brand-tertiary-500);
  }
}
.zimbra-client_folder-list_item.zimbra-client_folder-list_dropTarget{
  background:var(--folder-item-hover-bg);
}
.zimbra-client_folder-list_item.zimbra-client_folder-list_dropped:after{
  -webkit-animation:zimbra-client_folder-list_fadeOut 250ms ease forwards 1;
          animation:zimbra-client_folder-list_fadeOut 250ms ease forwards 1;
}
@-webkit-keyframes zimbra-client_folder-list_fadeOut{
  0%{
    opacity:0;
  }
  25%{
    opacity:0;
  }
  26%{
    opacity:1;
  }
  50%{
    opacity:1;
  }
  51%{
    opacity:0;
  }
  75%{
    opacity:0;
  }
  76%{
    opacity:1;
  }
  99%{
    opacity:1;
  }
  100%{
    opacity:0;
  }
}
@keyframes zimbra-client_folder-list_fadeOut{
  0%{
    opacity:0;
  }
  25%{
    opacity:0;
  }
  26%{
    opacity:1;
  }
  50%{
    opacity:1;
  }
  51%{
    opacity:0;
  }
  75%{
    opacity:0;
  }
  76%{
    opacity:1;
  }
  99%{
    opacity:1;
  }
  100%{
    opacity:0;
  }
}
.zimbra-client_folder-list_item.zimbra-client_folder-list_disabled{
  cursor:default;
}
.zimbra-client_folder-list_item.zimbra-client_folder-list_disabled .zimbra-client_folder-list_itemTitle{
  opacity:0.5;
  color:var(--gray-light);
}
.zimbra-client_folder-list_item.zimbra-client_folder-list_disabled .zimbra-client_folder-list_pstLoader{
  margin-left:5px;
}
.zimbra-client_folder-list_hide{
  display:none;
}
.zimbra-client_folder-list_badge{
  margin-left:auto;
  flex:0 0 auto;
  color:var(--gray);
  font-weight:700;
  border-radius:50px;
  background:var(--off-white);
  padding:2px 8px;
  font-size:var(--font-size-small);
  line-height:1.2;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_badge{
    font-size:10px;
    font-weight:bold;
    background-color:var(--gray-lighter);
    border-radius:14px;
    padding:3px 7px;
    display:inline-block;
    margin-left:var(--spacing-sm);
    color:var(--gray-dark);
  }
}
.zimbra-client_folder-list_itemLink{
  display:flex;
  flex:1;
  width:80%;
  color:var(--text-color);
  padding:0 var(--spacing-xs) 0 0;
  font-weight:400;
  cursor:pointer;
}
.zimbra-client_folder-list_itemLink.zimbra-client_folder-list_checkedItemLink{
  padding:0;
}
.zimbra-client_folder-list_itemLink.zimbra-client_folder-list_brokenSymLink .zimbra-client_folder-list_itemTitle{
  text-decoration:line-through;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_itemLink{
    padding-top:12px;
    padding-right:10px;
    padding-bottom:12px;
    padding-left:0;
    border-bottom:1px solid var(--gray-lighter);
  }
}
.zimbra-client_folder-list_itemLink[href]:focus,
.zimbra-client_folder-list_itemLink[href]:hover{
  color:var(--gray-darkest);
  font-weight:700;
  background:var(--gray-lighter);
  border-radius:2px;
  text-decoration:none;
}
@media (min-width: 769px){
  .zimbra-client_folder-list_itemLink.zimbra-client_folder-list_activeClass.zimbra-client_folder-list_active{
    background:var(--brand-primary-100);
    padding-right:0;
  }
  .zimbra-client_folder-list_itemLink.zimbra-client_folder-list_active{
    color:var(--gray-darkest);
    font-weight:700;
    border-radius:2px;
  }
  .zimbra-client_folder-list_itemLink.zimbra-client_folder-list_active .zimbra-client_folder-list_badge{
    background:var(--brand-primary-500);
    color:white;
  }
}
.zimbra-client_folder-list_tagLink{
  padding:var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-sm);
}
.zimbra-client_folder-list_itemInner{
  align-items:center;
  display:flex;
  width:100%;
  padding:var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-lg);
}
.zimbra-client_folder-list_itemInner [class*=coloredCheckbox] input[type='checkbox'] + i:before{
  color:var(--gray-light);
}
.zimbra-client_folder-list_itemInner [class*=coloredCheckbox] input[type='checkbox']:checked + i:before,
.zimbra-client_folder-list_itemInner [class*=coloredCheckbox] input[type='checkbox']:indeterminate + i:before{
  content:var(--zimbra-icon-check-square-o);
  color:var(--gray-light);
}
.zimbra-client_folder-list_hasBadge{
  color:var(--gray-darkest);
  font-weight:700;
}
.zimbra-client_folder-list_itemTitle{
  align-items:center;
  flex:0 1 auto;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_itemTitle{
    flex-grow:1;
    font-size:var(--font-size-base);
    font-weight:700;
    color:var(--gray-darkest);
  }
  .zimbra-client_folder-list_active .zimbra-client_folder-list_itemTitle{
    color:var(--brand-primary-500);
  }
  .zimbra-client_folder-list_itemTitle.zimbra-client_folder-list_tagName{
    flex:0 1 auto;
  }
}
.zimbra-client_folder-list_itemTitle.zimbra-client_folder-list_tagName{
  border-top-right-radius:10px;
  border-bottom-right-radius:10px;
  padding:1px 8px;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_contextMenuIcon{
    margin-left:var(--spacing-md);
  }
}
.zimbra-client_folder-list_item:hover .zimbra-client_folder-list_active .zimbra-client_folder-list_refresh{
  display:block;
}
button.zimbra-client_folder-list_refresh{
  display:none;
  border-radius:50%;
  color:var(--gray-light);
  background-color:transparent;
  overflow:hidden;
  width:25px;
}
button.zimbra-client_folder-list_refresh.zimbra-client_folder-list_refreshing{
  display:block;
}
button.zimbra-client_folder-list_refresh.zimbra-client_folder-list_refreshing:not(:disabled):hover,
button.zimbra-client_folder-list_refresh.zimbra-client_folder-list_refreshing:not(:disabled):focus{
  background-color:transparent;
}
button.zimbra-client_folder-list_refresh:not(:disabled):hover,
button.zimbra-client_folder-list_refresh:not(:disabled):focus{
  color:var(--gray-darker);
  background-color:white;
}
button.zimbra-client_folder-list_refresh .zimbra-client_folder-list_refreshIcon{
  outline:0px;
}
@media (max-width: 1024px){
  button.zimbra-client_folder-list_refresh{
    right:8px;
    top:10px;
    display:inline-block;
  }
}
@-webkit-keyframes zimbra-client_folder-list_rotate{
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);
  }
}
@keyframes zimbra-client_folder-list_rotate{
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);
  }
}
.zimbra-client_folder-list_refreshing{
  -webkit-animation:zimbra-client_folder-list_rotate 1s linear infinite;
          animation:zimbra-client_folder-list_rotate 1s linear infinite;
}
div.zimbra-client_folder-list_folderCollapsibleControl{
  margin-left:-20px;
}
@media (max-width: 1024px){
  div.zimbra-client_folder-list_folderCollapsibleControl{
    left:22px;
    top:6px;
  }
}
div.zimbra-client_folder-list_folderCollapsibleControl--depth1.zimbra-client_folder-list_grouped{
  left:14px;
}
@media (max-width: 1024px){
  div.zimbra-client_folder-list_folderCollapsibleControl--depth1.zimbra-client_folder-list_grouped{
    left:36px;
  }
}
div.zimbra-client_folder-list_folderCollapsibleControl--depth2{
  left:14px;
}
div.zimbra-client_folder-list_folderCollapsibleControl--depth2.zimbra-client_folder-list_grouped{
  left:28px;
}
@media (max-width: 1024px){
  div.zimbra-client_folder-list_folderCollapsibleControl--depth2.zimbra-client_folder-list_grouped{
    left:50px;
  }
}
div.zimbra-client_folder-list_folderCollapsibleControl--depth3{
  left:28px;
}
div.zimbra-client_folder-list_folderCollapsibleControl--depth3.zimbra-client_folder-list_grouped{
  left:32px;
}
.zimbra-client_folder-list_groupToggle{
  padding:var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-lg);
  font-family:var(--branding-font-family-bold);
  font-weight:400;
  position:relative;
  cursor:pointer;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  height:32px;
  display:flex;
  align-items:center;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_groupToggle{
    font-family:var(--branding-font-family-bold);
    padding:0 0 0 34px;
    height:44px;
  }
}
.zimbra-client_folder-list_contactGroupIcon{
  margin-right:var(--spacing-xs);
}
.zimbra-client_folder-list_customFolderToggle{
  display:flex;
  flex:1;
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_customFolderToggle{
    padding:11px 10px 11px 0;
    border-bottom:1px solid var(--gray-lighter);
    font-size:var(--font-size-base);
    font-weight:700;
    color:var(--gray-darkest);
  }
}
.zimbra-client_folder-list_customFolderToggle:hover .zimbra-client_folder-list_folderGroupAction{
  display:block;
}
.zimbra-client_folder-list_customFolderToggleName{
  flex:1;
}
.zimbra-client_folder-list_topLevelInput{
  margin-left:16px;
}
.zimbra-client_folder-list_folderGroupAction{
  line-height:1;
  margin-left:4px;
}
@media (min-width: 1025px){
  .zimbra-client_folder-list_folderGroupAction{
    display:none;
  }
}
.zimbra-client_folder-list_folderGroupAction:hover{
  color:var(--gray-base);
}
.zimbra-client_folder-list_item .zimbra-client_folder-list_folderItemAction{
  display:none;
  padding:0 4px;
}
.zimbra-client_folder-list_item .zimbra-client_folder-list_folderItemAction:hover{
  color:var(--gray-base);
}
.zimbra-client_folder-list_item:hover .zimbra-client_folder-list_folderItemAction{
  display:block;
}
.zimbra-client_folder-list_groupChildren{
  padding-left:0;
}
.zimbra-client_folder-list_folderWithSubFolders{
  overflow:auto;
  display:flex;
  flex-flow:wrap;
  flex-direction:column;
}
.zimbra-client_folder-list_folderWithSubFolders .zimbra-client_folder-list_folderWithSubFolders > div:not(:first-child){
  margin-left:var(--spacing-md);
}
.zimbra-client_folder-list_folderWithSubFolders > div:not(:first-child) .zimbra-client_folder-list_item{
  padding-left:var(--spacing-md);
}
@media (max-width: 1024px){
  .zimbra-client_folder-list_folderWithSubFolders > div:not(:first-child) .zimbra-client_folder-list_item{
    padding-left:66px;
  }
}
.zimbra-client_folder-list_folderInput{
  padding-left:var(--spacing-lg);
}
.zimbra-client_folder-list_loaderContainer{
  position:relative;
}
.zimbra-client_folder-list_loaderWrapper{
  position:absolute;
  top:0;
  left:0;
  margin:0;
  width:100%;
  height:100%;
  background-color:var(--gray-lighter);
  opacity:0.5;
  cursor:not-allowed;
  z-index:1;
}
.zimbra-client_folder-list_multiSelect .zimbra-client_folder-list_customFolderToggleName{
  margin-left:6px;
}
.zimbra-client_folder-list_multiSelect .zimbra-client_folder-list_checkboxContainer{
  margin:0 10px 0 6px;
}
.zimbra-client_folder-list_eventTooltip{
  padding:4px 8px;
  background-color:white;
  border-radius:3px;
  box-shadow:0 4px 6px 0 rgba(0, 0, 0, 0.36);
  width:141px;
  font-size:12px;
}
.zimbra-client_folder-list_eventTooltip span{
  display:flex;
  justify-content:space-between;
}
.zimbra-client_folder-list_eventTooltip p{
  margin:0px;
  padding:0px;
}
.zimbra-client_folder-list_eventTooltipTarget{
  width:100%;
  height:100%;
}
