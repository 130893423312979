@media (max-width: 480px){
  .zimbra-client_date-input_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_date-input_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_date-input_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_date-input_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_date-input_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_date-input_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_date-input_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_date-input_input{
  width:160px;
}
.zimbra-client_date-input_popover{
  width:186px;
}
.zimbra-client_date-input_clearButtonContainer{
  border-top:1px solid var(--border-color);
  margin:0 10px;
}
.zimbra-client_date-input_clearButton{
  width:100%;
  outline:none;
  border:none;
  background:none;
  font-family:var(--headings-font-family);
  font-size:var(--font-size-small);
  color:var(--text-color);
  padding:6px 0;
}
