.blocks_spinner_spinner{
  display:inline-block;
  border:1px solid transparent;
  border-top-color:rgba(0, 0, 0, 0.6);
  border-radius:50%;
  -webkit-animation:blocks_spinner_rotate 800ms linear infinite;
          animation:blocks_spinner_rotate 800ms linear infinite;
}
.blocks_spinner_spinner.blocks_spinner_xs{
  width:0.5em;
  height:0.5em;
}
.blocks_spinner_spinner.blocks_spinner_sm{
  width:1em;
  height:1em;
}
.blocks_spinner_spinner.blocks_spinner_md{
  width:1.5em;
  height:1.5em;
}
.blocks_spinner_spinner.blocks_spinner_lg{
  width:2em;
  height:2em;
}
.blocks_spinner_spinner.blocks_spinner_xl{
  width:3em;
  height:3em;
}
.blocks_spinner_spinner[dark]{
  border-top-color:rgba(255, 255, 255, 0.6);
}
.blocks_spinner_spinner[btnSpinner]{
  border-width:2px;
  border-top-color:var(--body-bg);
  width:1.5em;
  height:1.5em;
}
@-webkit-keyframes blocks_spinner_rotate{
  0%{
    transform:rotate(0deg);
  }
  100%{
    transform:rotate(360deg);
  }
}
@keyframes blocks_spinner_rotate{
  0%{
    transform:rotate(0deg);
  }
  100%{
    transform:rotate(360deg);
  }
}
.blocks_spinner_blockSpinner{
  display:table !important;
  height:100%;
  width:100%;
}
.blocks_spinner_blockSpinner > div{
  display:table-cell;
  text-align:center;
  vertical-align:middle;
}
.blocks_spinner_blockSpinner > div .blocks_spinner_innerSpinner{
  font-size:3em;
}
