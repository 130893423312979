@media (max-width: 480px){
  .zimbra-client_naked-button_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_naked-button_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_naked-button_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_naked-button_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_naked-button_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_naked-button_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_naked-button_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_naked-button_button{
  padding:0;
  line-height:1;
}
.zimbra-client_naked-button_button.zimbra-client_naked-button_linkColor{
  color:var(--link-color);
}
.zimbra-client_naked-button_button:disabled,
.zimbra-client_naked-button_button:disabled:hover{
  opacity:0.37;
  pointer-events:none;
}
