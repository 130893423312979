.zimbra-client_token-input_tokenInput{
  display:flex;
  flex-wrap:wrap;
  position:relative;
  width:100%;
  border:1px solid var(--gray-darkest);
  cursor:text;
}
.zimbra-client_token-input_tokenInput .zimbra-client_token-input_tokens{
  display:inline-flex;
  flex-grow:1;
  flex-wrap:wrap;
  max-width:100%;
  margin:0;
  align-items:center;
}
.zimbra-client_token-input_tokenInput .zimbra-client_token-input_tokens > *{
  cursor:pointer;
}
.zimbra-client_token-input_tokenInput .zimbra-client_token-input_inputWrap{
  align-items:center;
  display:inline-flex;
  flex-grow:1;
  overflow:visible;
}
.zimbra-client_token-input_tokenInput .zimbra-client_token-input_insertionPoint{
  padding-left:1px;
  border-left:black solid 2px;
  height:15px;
}
.zimbra-client_token-input_tokenInput .zimbra-client_token-input_input{
  width:100%;
  padding:2px;
  margin:0;
  border:none;
  background:none;
  outline:none;
  vertical-align:middle;
}
