@media (max-width: 480px){
  .zimbra-client_select_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_select_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_select_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_select_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_select_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_select_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_select_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_select_container{
  display:inline-block;
  position:relative;
  line-height:normal;
}
.zimbra-client_select_container:not(.zimbra-client_select_noArrow):before{
  font-family:'zimbra-icons';
  content:var(--zimbra-icon-angle-down);
  position:absolute;
  right:11px;
  top:12px;
  font-size:10px;
}
.zimbra-client_select_container.zimbra-client_select_fullWidth{
  width:100%;
}
.zimbra-client_select_container.zimbra-client_select_fullWidth .zimbra-client_select_select{
  width:100%;
}
.zimbra-client_select_select,
.zimbra-client_select_collapseLabel{
  white-space:nowrap;
  background-color:transparent;
  padding:9px 34px 9px 12px;
  border-radius:3px;
  border:solid 1px var(--gray-lighter);
  color:var(--gray-darkest);
  position:relative;
}
.zimbra-client_select_select.zimbra-client_select_collapsable,
.zimbra-client_select_collapseLabel.zimbra-client_select_collapsable{
  outline:none;
  padding:0 25px 0 2px;
}
.zimbra-client_select_select.zimbra-client_select_bold,
.zimbra-client_select_collapseLabel.zimbra-client_select_bold{
  font-weight:bold;
}
.zimbra-client_select_select.zimbra-client_select_noBorder,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder{
  border:0;
}
.zimbra-client_select_select.zimbra-client_select_noBorder:hover,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder:hover,
.zimbra-client_select_select.zimbra-client_select_noBorder:active,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder:active,
.zimbra-client_select_select.zimbra-client_select_noBorder:focus,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder:focus{
  background-color:var(--gray-lightest);
}
.zimbra-client_select_select.zimbra-client_select_noBorder:hover.zimbra-client_select_noHover,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder:hover.zimbra-client_select_noHover,
.zimbra-client_select_select.zimbra-client_select_noBorder:active.zimbra-client_select_noHover,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder:active.zimbra-client_select_noHover,
.zimbra-client_select_select.zimbra-client_select_noBorder:focus.zimbra-client_select_noHover,
.zimbra-client_select_collapseLabel.zimbra-client_select_noBorder:focus.zimbra-client_select_noHover{
  background-color:transparent;
  cursor:pointer;
}
.zimbra-client_select_collapseLabel.zimbra-client_select_inlineArrow:before{
  font-family:'zimbra-icons';
  content:var(--zimbra-icon-angle-down);
  position:absolute;
  right:11px;
  top:1px;
  font-size:9px;
}
.zimbra-client_select_select{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
}
.zimbra-client_select_select::-ms-expand{
  display:none;
}
.zimbra-client_select_select.zimbra-client_select_hidden{
  opacity:0;
}
.zimbra-client_select_collapseLabel{
  line-height:14px;
  position:absolute;
  display:inline-block;
  padding-right:25px;
}
.zimbra-client_select_collapseLabel.zimbra-client_select_hidden{
  display:none;
}
