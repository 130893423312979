.blocks_popover_popover-container{
  display:inline-block;
  max-width:100%;
}
.blocks_popover_popover-container .blocks_popover_button{
  background:transparent;
  border:none;
  color:#000;
  cursor:pointer;
  display:inline-block;
  padding:0;
  max-width:100%;
}
.blocks_popover_popover-container .blocks_popover_button[href] > span:focus,
.blocks_popover_popover-container .blocks_popover_button[href] > span:hover{
  text-decoration:underline;
}
.blocks_popover_popover-container .blocks_popover_button:focus,
.blocks_popover_popover-container .blocks_popover_button:hover{
  outline:none;
  color:var(--brand-primary-500);
}
.blocks_popover_popover-container .blocks_popover_button[disabled="true"]{
  pointer-events:none;
}
.blocks_popover_popover-container .blocks_popover_button .blocks_popover_title{
  display:inline-block;
  font-weight:bold;
  text-transform:capitalize;
  vertical-align:middle;
  text-align:center;
}
.blocks_popover_popper{
  background:#fff;
  border:1px solid var(--border-color);
  border-radius:4px;
  box-shadow:0 4px 6px rgba(0, 0, 0, 0.36);
  list-style:none;
  z-index:999;
}
.blocks_popover_popper[data-x-out-of-boundaries]{
  display:none;
}
.blocks_popover_popper .blocks_popover_dropdown{
  position:relative;
  z-index:1;
}
.blocks_popover_popper .blocks_popover_arrow,
.blocks_popover_popper .blocks_popover_borderArrow{
  width:0;
  height:0;
  border-style:solid;
  position:absolute;
  border-color:transparent;
}
.blocks_popover_popper .blocks_popover_arrow{
  border-width:12px;
}
.blocks_popover_popper .blocks_popover_borderArrow{
  border-width:13px;
}
.blocks_popover_popper[data-placement^="top"][arrow] .blocks_popover_arrow{
  border-bottom-width:0;
  border-top-color:#fff;
  bottom:-12px;
  margin:0 calc(13px/2);
}
.blocks_popover_popper[data-placement^="top"][arrow] .blocks_popover_borderArrow{
  border-bottom-width:0;
  border-top-color:var(--gray-lighter);
  bottom:-13px;
  margin:0 calc(12px/2);
}
.blocks_popover_popper[data-placement^="bottom"][arrow] .blocks_popover_arrow{
  border-top-width:0;
  border-bottom-color:#fff;
  top:-12px;
}
.blocks_popover_popper[data-placement^="bottom"][arrow] .blocks_popover_borderArrow{
  border-top-width:0;
  border-bottom-color:var(--gray-lighter);
  top:-13px;
}
.blocks_popover_popper[data-placement^="right"][arrow] .blocks_popover_arrow{
  border-left-width:0;
  border-right-color:#fff;
  left:-12px;
}
.blocks_popover_popper[data-placement^="right"][arrow] .blocks_popover_borderArrow{
  border-left-width:0;
  border-right-color:var(--gray-lighter);
  left:-13px;
}
.blocks_popover_popper[data-placement^="left"][arrow] .blocks_popover_arrow{
  border-right-width:0;
  border-left-color:#fff;
  right:-12px;
}
.blocks_popover_popper[data-placement^="left"][arrow] .blocks_popover_borderArrow{
  border-right-width:0;
  border-left-color:var(--gray-lighter);
  right:-13px;
}
