@media (max-width: 480px){
  .zimbra-client_header_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_header_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_header_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_header_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_header_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_header_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_header_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_header_primaryLogo{
  text-align:left;
  max-width:var(--logo-max-width);
  width:var(--logo-max-width);
}
.zimbra-client_header_header{
  display:none;
  align-items:center;
  width:100%;
  min-width:1024px;
  height:var(--search-header-height);
  background-color:var(--header-bg);
  color:var(--header-fg);
  white-space:nowrap;
  position:relative;
  padding:0 var(--spacing-md);
  z-index:100;
  box-shadow:0 2px 4px rgba(0, 0, 0, 0.2);
  justify-content:space-between;
}
.zimbra-client_header_header.zimbra-client_header_headerSearch{
  justify-content:initial;
}
@media (min-width: 769px){
  .zimbra-client_header_header{
    display:flex;
  }
}
