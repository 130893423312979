@media (max-width: 480px){
  .zimbra-client_filters_filter-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_filters_filter-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_filters_filter-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_filters_filter-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_filters_filter-modal_settings{
  display:flex;
  height:100%;
  width:100%;
  flex-direction:column;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings{
    flex-direction:unset;
  }
}
.zimbra-client_filters_filter-modal_settings ul{
  list-style:none;
}
.zimbra-client_filters_filter-modal_settings input:required{
  box-shadow:none;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebar{
  width:100%;
  padding:20px 0;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebar{
    width:300px;
    border-right:1px solid var(--gray-lighter);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebar.zimbra-client_filters_filter-modal_activePanel{
  display:none;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebar.zimbra-client_filters_filter-modal_activePanel{
    display:block;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_datePickerWrapper{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_datePickerWrapper .zimbra-client_filters_filter-modal_dateSelector{
  margin-right:var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_datePickerWrapper .zimbra-client_filters_filter-modal_dateSelector{
    margin-bottom:var(--spacing-sm);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_endDate{
  margin-top:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_alignLabel{
  display:block;
  align-self:baseline;
  padding-top:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_vactionSubField{
  max-width:574px;
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_vacationResponseTextArea{
  max-width:574px;
  width:100%;
  height:158px;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_vacationExternalMessageArea{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_buttonNoMargin{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_buttonNoMargin{
    margin-bottom:var(--spacing-md);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_buttonWithMargin{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
  margin-top:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_buttonWithMargin{
    margin-bottom:var(--spacing-md);
    margin-top:var(--spacing-md);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu{
  display:block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu .zimbra-client_filters_filter-modal_sidebarItem{
  display:block;
  padding:6px var(--spacing-lg);
  margin-bottom:var(--spacing-xs);
  cursor:pointer;
  color:var(--text-color);
  border-left:4px solid transparent;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu .zimbra-client_filters_filter-modal_sidebarItem:last-child{
  margin-bottom:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu .zimbra-client_filters_filter-modal_sidebarItem.zimbra-client_filters_filter-modal_active{
  font-weight:bold;
  color:var(--gray-darkest);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu .zimbra-client_filters_filter-modal_sidebarItem:focus,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu .zimbra-client_filters_filter-modal_sidebarItem:hover{
  border-color:var(--brand-primary-500);
  text-decoration:none;
  outline:none;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sidebarMenu .zimbra-client_filters_filter-modal_sidebarItem.zimbra-client_filters_filter-modal_hide{
  display:none;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_settingsWrapper{
  position:relative;
  display:none;
  height:100%;
  width:100%;
  overflow:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
  padding:20px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_settingsWrapper .zimbra-client_filters_filter-modal_error{
  margin:0 -20px var(--spacing-md);
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  padding:var(--spacing-sm) var(--spacing-lg);
  font-size:var(--font-size-base);
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_settingsWrapper{
    width:100%;
    display:block;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_settingsWrapper.zimbra-client_filters_filter-modal_activePanel{
  display:unset;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_marginBottomMd{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsection{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsection{
    margin-bottom:var(--spacing-md);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_marginBottomLg{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsection{
  display:flex;
  font-size:var(--font-size-base);
  color:var(--text-color);
  flex-direction:column;
  align-items:baseline;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsection{
    flex-direction:unset;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsection:last-child{
  margin-bottom:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsection.zimbra-client_filters_filter-modal_compact{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle{
  min-width:220px;
  width:220px;
  margin-bottom:calc(var(--spacing-sm) + 2px);
  font-weight:500;
  padding:2px var(--spacing-md) 0 0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle.zimbra-client_filters_filter-modal_verticallyCenteredLabel{
  display:flex;
  align-items:center;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle.zimbra-client_filters_filter-modal_forSelect{
  line-height:unset;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle.zimbra-client_filters_filter-modal_dateExample{
  width:100%;
  line-height:33px;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle{
    margin-bottom:0;
    font-weight:unset;
  }
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle.zimbra-client_filters_filter-modal_forSelect{
    padding-top:6px;
  }
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionTitle.zimbra-client_filters_filter-modal_plainTextSetting{
    width:auto;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersSubsectionTitle{
  min-width:210px;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersSubsectionTitle{
    width:210px;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sectionTitle{
  font-size:20px;
  color:var(--gray-darkest);
  padding-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_sectionTitle{
    font-size:var(--font-size-large);
    padding-bottom:calc(var(--spacing-sm) + 2px);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBodyOOO .zimbra-client_filters_filter-modal_list li{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody{
  flex-grow:1;
  max-width:375px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_half{
  width:150px;
  display:inline-block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_half.zimbra-client_filters_filter-modal_inline{
  display:inline-block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_half:first-child{
  margin-right:6px;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_half{
    width:184px;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_processAdditional{
  display:inline-block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_emailAlarmAddrInput{
  width:100%;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_emailAlarmAddrInput{
    width:260px;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection{
  max-width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_emailListContainer,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_emailListContainer{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList{
  position:relative;
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList:hover,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList:hover{
  padding-right:28px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList .zimbra-client_filters_filter-modal_emailAddress,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList .zimbra-client_filters_filter-modal_emailAddress{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_mailForwardAddress:hover span.zimbra-client_filters_filter-modal_closeForwardEmailAddress,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_mailForwardAddress:hover span.zimbra-client_filters_filter-modal_closeForwardEmailAddress{
  top:5px;
  right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_description,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_description{
  font-size:13px;
  color:var(--gray-darkest);
  font-weight:normal;
  margin-top:8px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_description.zimbra-client_filters_filter-modal_fromDescription,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_description.zimbra-client_filters_filter-modal_fromDescription{
  margin-top:0;
  margin-bottom:16px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_alignedForm,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_alignedForm,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_formGroup,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_formGroup{
  margin-bottom:16px;
  align-items:baseline;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_formGroup p,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_formGroup p{
  width:100%;
  margin:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_formGroup p.zimbra-client_filters_filter-modal_marginTopMd,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_formGroup p.zimbra-client_filters_filter-modal_marginTopMd{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_personaName,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_personaName{
  max-width:375px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_comboWithAddBtn,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_comboWithAddBtn{
  width:100%;
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_comboWithAddBtn button,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_comboWithAddBtn button{
  margin:0 5px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_optionalInput,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_optionalInput{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_sectionHeading,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_sectionHeading{
  margin-bottom:0px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_expandedSubsection,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_expandedSubsection{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_expandedSubsection .zimbra-client_filters_filter-modal_subsectionBody,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_expandedSubsection .zimbra-client_filters_filter-modal_subsectionBody{
  max-width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_optionalLabel,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_optionalLabel{
  margin-right:20px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_optionalLabel.zimbra-client_filters_filter-modal_multiline,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_optionalLabel.zimbra-client_filters_filter-modal_multiline{
  line-height:normal;
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_optionalLabel,
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_optionalLabel{
    margin-bottom:2px;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_folderList,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_folderList{
  width:100%;
  border:1px solid var(--gray-lighter);
  max-height:250px;
  overflow-y:auto;
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_comboTextSelect,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_comboTextSelect{
  display:flex;
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_comboTextSelect .zimbra-client_filters_filter-modal_optionalInput,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_comboTextSelect .zimbra-client_filters_filter-modal_optionalInput{
  padding-left:40px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_comboTextSelect .zimbra-client_filters_filter-modal_selectContainer,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_comboTextSelect .zimbra-client_filters_filter-modal_selectContainer{
  position:absolute;
  width:33px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_comboTextSelect .zimbra-client_filters_filter-modal_selectContainer select,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_comboTextSelect .zimbra-client_filters_filter-modal_selectContainer select{
  width:0px;
  border:none;
  border-right:1px solid var(--gray-lighter);
  border-radius:unset;
  padding-right:21px;
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_optionalInput,
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_optionalInput,
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_expandedSubsection,
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_expandedSubsection{
    width:100%;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_personaSubsection .zimbra-client_filters_filter-modal_externalServerSettings,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBody.zimbra-client_filters_filter-modal_editSubsection .zimbra-client_filters_filter-modal_externalServerSettings{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBodyButton{
  margin-top:0;
  margin-bottom:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBodyButton[disabled]{
  cursor:default;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_subsectionBodyButton:first-child{
  margin-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_compactCheckboxSection{
  margin-bottom:var(--spacing-xs);
  display:flex;
  align-items:center;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_compactCheckboxSection input{
  flex-shrink:0;
}
.zimbra-client_filters_filter-modal_settings ul.zimbra-client_filters_filter-modal_addressListDirectionBottom{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings ul.zimbra-client_filters_filter-modal_addressListDirectionBottom li:last-child{
  margin-bottom:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_label{
  display:flex;
  align-items:center;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_label .zimbra-client_filters_filter-modal_labelText{
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_list{
  margin:0;
  padding-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_list li{
  margin-bottom:calc(var(--spacing-md) - 2px);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_list li:last-child{
  margin-bottom:0;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_list li{
    margin-bottom:calc(var(--spacing-xs) + 2px);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_list.zimbra-client_filters_filter-modal_folderDownloadSection{
  padding-bottom:var(--spacing-md);
  margin-bottom:var(--spacing-md);
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect{
  padding:var(--spacing-sm) var(--spacing-xs);
  width:184px;
  border:solid 1px var(--border-color);
  border-radius:3px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect > ul{
  max-width:250px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOption{
  text-align:left;
  text-transform:none;
  background:var(--body-bg);
  color:var(--gray-base);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOption:hover,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOption:focus{
  background:var(--gray-lightest);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOption:not(:hover){
  background:transparent;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOption > div[class*="itemTitle"]{
  float:left;
  padding-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOptionItem{
  display:flex;
  align-items:center;
  font-weight:normal;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOptionItem .zimbra-client_filters_filter-modal_calendarColor{
  margin-right:var(--spacing-sm);
  width:var(--icon-size-sm);
  height:var(--spacing-md);
  border:2px solid var(--body-bg);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_calendarSelect .zimbra-client_filters_filter-modal_calendarOptionItem .zimbra-client_filters_filter-modal_calendarText{
  white-space:nowrap;
  overflow:hidden;
  text-transform:none;
  text-overflow:ellipsis;
  color:var(--gray-darkest);
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_toggleButtonClass{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_toggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_toggleButtonClass p{
  overflow:hidden;
  width:calc(100% - 10px);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_richTextPreview{
  position:relative;
  margin-top:var(--spacing-sm);
  background-color:var(--gray-lightest);
  border:solid 1px var(--gray-lighter);
  min-height:80px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_richTextPreview span{
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_inputGroup{
  position:relative;
  display:flex;
  margin-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_inputGroup button{
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  white-space:nowrap;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput{
  flex-grow:1;
  padding:5px 12px 6px;
  height:var(--spacing-xl);
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  background-color:var(--body-bg);
  border:solid 1px var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput::placeholder,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput[disabled]{
  color:var(--placeholder-color);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput[disabled]{
  background-color:var(--gray-lightest);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_textInput.zimbra-client_filters_filter-modal_block{
  display:block;
  margin-top:6px;
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_helperText{
  font-size:var(--font-size-base);
  color:var(--gray-light);
  padding-top:3px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_helperText.zimbra-client_filters_filter-modal_helperTextDark{
  color:var(--text-color);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_emailAddressList{
  padding-left:0;
  height:240px;
  margin:var(--spacing-sm) 0 12px;
  background-color:var(--body-bg);
  border:1px solid #ccc;
  border-radius:3px;
  overflow-y:auto;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_emailAddressListEntry{
  overflow-x:hidden;
  word-wrap:break-word;
  width:100%;
  padding:7px 11px;
  cursor:pointer;
  position:relative;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_emailAddressListEntry:hover{
  background-color:var(--gray-lightest);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_emailAddressListEntry.zimbra-client_filters_filter-modal_selected{
  background-color:var(--brand-primary-50);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_disabledElement{
  color:var(--placeholder-color);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission{
  position:relative;
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_subsectionBody{
  min-width:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_emailListContainer{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList{
  position:relative;
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList:hover{
  padding-right:28px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_emailListContainer .zimbra-client_filters_filter-modal_emailList .zimbra-client_filters_filter-modal_emailAddress{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_mailForwardAddress:hover span.zimbra-client_filters_filter-modal_closeForwardEmailAddress{
  top:5px;
  right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_inputGroup{
  position:relative;
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_freeBusyPermission .zimbra-client_filters_filter-modal_inputGroup .zimbra-client_filters_filter-modal_suggestionContainer{
  position:absolute;
  bottom:var(--spacing-xl);
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList{
  margin-top:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry{
  display:flex;
  flex-wrap:wrap;
  word-wrap:break-word;
  width:100%;
  overflow:hidden;
  border-top:1px solid var(--gray-lighter);
  background-color:var(--body-bg);
  transition:all 100ms linear;
  color:var(--gray-darkest);
  font-size:var(--font-size-base);
  padding-left:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_controls{
  display:flex;
  align-items:center;
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_controls .zimbra-client_filters_filter-modal_filterName{
  padding-left:var(--spacing-xs);
  font-weight:500;
  text-align:left;
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_controls .zimbra-client_filters_filter-modal_actionButton{
  height:calc(var(--spacing-xl) + var(--spacing-sm));
  padding:0;
  margin-right:var(--spacing-xs);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_controls .zimbra-client_filters_filter-modal_dragHandle{
  cursor:-webkit-grab;
  cursor:grab;
  display:inline-block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_controls .zimbra-client_filters_filter-modal_dragHandle:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails{
  padding-bottom:10px;
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails .zimbra-client_filters_filter-modal_filterProgressInfo{
  margin-bottom:10px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails .zimbra-client_filters_filter-modal_filterProgressInfo .zimbra-client_filters_filter-modal_progressBar{
  min-width:calc(100% - 150px);
  height:var(--spacing-sm);
  border-radius:var(--spacing-xs);
  background-color:var(--gray-lighter);
  display:inline-block;
  margin-right:var(--spacing-sm);
  vertical-align:middle;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails .zimbra-client_filters_filter-modal_filterProgressInfo .zimbra-client_filters_filter-modal_progressBar .zimbra-client_filters_filter-modal_progressIndicator{
  height:var(--spacing-sm);
  border-radius:var(--spacing-xs);
  background-color:var(--brand-success-500);
  display:inline-block;
  vertical-align:top;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails .zimbra-client_filters_filter-modal_filterProgressInfo .zimbra-client_filters_filter-modal_progressValue{
  font-size:var(--font-size-small);
  color:var(--gray-light);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails .zimbra-client_filters_filter-modal_filterProgressInfo .zimbra-client_filters_filter-modal_progressValue .zimbra-client_filters_filter-modal_cancelRun{
  margin-left:2px;
  cursor:pointer;
  color:var(--gray-light);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_filtersListEntry .zimbra-client_filters_filter-modal_filterDetails .zimbra-client_filters_filter-modal_filterProgressInfo .zimbra-client_filters_filter-modal_progressValue .zimbra-client_filters_filter-modal_runSuccessIndicator{
  color:var(--brand-success-500);
  margin-right:3px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .zimbra-client_filters_filter-modal_dummyDropZone{
  border-top:1px solid var(--gray-lighter);
  transition:all 100ms linear;
  height:var(--spacing-xl);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .dragOver{
  border-top:4px solid #CCC;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersList .draggedItem{
  opacity:0.5;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_unsupportedFilterRule{
  color:var(--brand-danger-500);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filterList{
  list-style-type:disc;
  padding-left:var(--spacing-xl);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filterList li{
  padding-left:12px;
  word-wrap:break-word;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_ifThenText{
  margin:var(--spacing-sm) 0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filterProcessLabel{
  margin:var(--spacing-xs) 0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filterProcessLabel .zimbra-client_filters_filter-modal_processAdditional{
  text-transform:lowercase;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_arrow{
  font-size:var(--icon-size-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_arrow:hover{
  cursor:pointer;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersControls{
  float:right;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersControls div{
  display:inline-block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_filtersControls:first-child{
  margin-right:6px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_flexContainer{
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_flexContainer .zimbra-client_filters_filter-modal_select{
  background-position:316px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox{
  display:flex;
  align-items:center;
  padding:var(--spacing-xs) var(--spacing-lg) var(--spacing-xs) 0;
  transition:all 200ms ease-in-out;
  cursor:pointer;
  position:relative;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox .zimbra-client_filters_filter-modal_rightArrow{
  position:absolute;
  right:5px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox .zimbra-client_filters_filter-modal_mailAccountContent{
  text-align:left;
  padding:0;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox .zimbra-client_filters_filter-modal_mailAccountName{
  margin-right:var(--spacing-xs);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox .zimbra-client_filters_filter-modal_mailAccountNote{
  color:var(--gray-light);
  margin-left:var(--spacing-xs);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox .zimbra-client_filters_filter-modal_leftLogo{
  display:flex;
  align-items:center;
  flex-shrink:0;
  width:var(--spacing-xl);
  height:var(--spacing-xl);
  color:var(--brand-primary);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountBox .zimbra-client_filters_filter-modal_leftLogoIcon{
  font-size:22px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mailAccountFailing{
  padding-right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountsList{
  padding:0;
  margin:0 0 10px 0;
  border-top:solid 1px var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountsList .zimbra-client_filters_filter-modal_mailAccountItem{
  border-bottom:solid 1px var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_twoFactorAuthSetup{
  margin-top:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_twoFactorAuthSetup .zimbra-client_filters_filter-modal_enableTwoFactorAuthBtn,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_twoFactorAuthSetup .zimbra-client_filters_filter-modal_disableTwoFactorAuthBtn{
  margin:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_addAccountSubsection,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_addAccountLabel,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_editAccountTitle,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_primaryAccountSection,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_twoFactorAuthSetup{
  padding-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_exportSection{
  margin-bottom:var(--spacing-xl);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_exportSection p{
  margin:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_exportSection .zimbra-client_filters_filter-modal_exportButton{
  margin:var(--spacing-md) 0 0 0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection p{
  margin:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection .zimbra-client_filters_filter-modal_lineBreak,
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection .zimbra-client_filters_filter-modal_buttons{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection .zimbra-client_filters_filter-modal_buttons span{
  margin-left:6px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection .zimbra-client_filters_filter-modal_buttons.zimbra-client_filters_filter-modal_fileChooseBtn{
  background-color:#e4e4e4;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_importPSTSection .zimbra-client_filters_filter-modal_buttons button{
  max-width:114px;
  min-width:114px;
  font-size:var(--font-size-base);
  padding:var(--spacing-sm) 11px;
  margin-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mobileConfigSection ul{
  margin:0 var(--spacing-md);
  padding:0;
  list-style-type:disc;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mobileConfigSection p{
  margin:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mobileConfigSection .zimbra-client_filters_filter-modal_optionText{
  margin:var(--spacing-lg) 0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mobileConfigSection .zimbra-client_filters_filter-modal_optionText p{
  margin-bottom:11px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mobileConfigSection .zimbra-client_filters_filter-modal_button{
  margin-top:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_mobileConfigSection .zimbra-client_filters_filter-modal_buttonspan{
  margin-left:7px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_addAccountLabel{
  display:block;
  font-size:var(--font-size-large);
  text-align:left;
  color:var(--gray-darkest);
  padding-bottom:20px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_addAccountInput{
  display:block;
  width:100%;
  font-size:var(--font-size-base);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_inlineList li{
  display:inline-block;
  margin-right:15px;
  line-height:32px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_infoInput{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_infoLabel{
  min-width:130px;
  width:130px;
  line-height:normal;
  margin-bottom:calc(var(--spacing-xs) + 3px);
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_infoLabel{
    line-height:32px;
    margin-bottom:0;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_portInput{
  width:90px;
  flex-grow:0;
  flex-shrink:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_rightHint{
  padding-left:12px;
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_rightHint{
    padding:0 var(--spacing-sm);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_plainButton{
  margin-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_editAccountButton{
  margin:0 0 20px 0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_groupToggle{
  left:10px;
  top:7px;
  cursor:pointer;
  padding-right:var(--spacing-xs);
  z-index:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_groupToggleTextContainer{
  display:flex;
  font-size:var(--font-size-base);
  text-align:left;
  color:var(--brand-tertiary-500);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_groupToggleTextContainer .zimbra-client_filters_filter-modal_groupToggleText{
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_groupToggleIcon{
  position:relative;
  line-height:15px;
  height:15px;
  width:15px;
  font-size:var(--font-size-base);
  color:var(--brand-tertiary-500);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_additionalInfoContainer{
  display:block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_checkboxList li{
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_checkboxList .zimbra-client_filters_filter-modal_togglePort{
  flex:2;
  padding-top:5px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_checkboxList .zimbra-client_filters_filter-modal_togglePortInput{
  flex:1;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_checkboxList .zimbra-client_filters_filter-modal_togglePortHint{
  flex:2;
  font-size:var(--font-size-base);
  text-align:left;
  color:var(--gray-light);
  margin:0;
  padding-top:5px;
  padding-left:12px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_confirmationMessage{
  font-size:var(--font-size-h3);
  margin-bottom:var(--spacing-xs);
  text-align:left;
  font-weight:300;
  color:var(--gray-darkest);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_confirmationSpan{
  font-size:var(--font-size-base);
  text-align:left;
  color:var(--gray-darker);
  margin-top:0;
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_normalSelect{
  font-size:small;
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_dateInput label{
    text-align:left;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_signatureSection{
  margin-bottom:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_signatureSection label{
  display:block;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_signatureSection .zimbra-client_filters_filter-modal_signatureBox{
  height:200px;
  display:flex;
  flex-direction:column;
  flex:1;
  border:1px solid var(--gray-lighter);
  padding-bottom:1px;
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_signatureSection .zimbra-client_filters_filter-modal_signatureBox .tox.tox-tinymce{
    border-top:1px solid var(--gray-lighter);
  }
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_signatureSection .zimbra-client_filters_filter-modal_signatureBox .tinymce-mobile-editor-socket{
    padding:var(--spacing-xs);
    border:1px solid var(--gray-lighter);
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_sectionTitle{
  font-size:var(--font-size-h3);
  font-weight:var(--headings-font-weight);
  padding-bottom:calc(var(--spacing-lg) - 2);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection{
  flex-direction:column;
  margin-bottom:var(--spacing-xl);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionTitle{
  font-size:var(--font-size-h4);
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_helperText{
  padding:var(--spacing-sm) 0 calc(var(--spacing-lg) - 4);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody{
  max-width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList .zimbra-client_filters_filter-modal_certificate{
  display:flex;
  align-items:center;
  margin:0;
  padding:calc(var(--spacing-sm) - 1) 0 var(--spacing-sm);
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList .zimbra-client_filters_filter-modal_certificate:last-child{
  border-bottom:1px solid var(--gray-lighter);
  padding-bottom:calc(var(--spacing-sm) - 1);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList .zimbra-client_filters_filter-modal_certificate .zimbra-client_filters_filter-modal_shieldIcon{
  width:var(--icon-size-md);
  height:var(--icon-size-md);
  margin-right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList .zimbra-client_filters_filter-modal_certificate .zimbra-client_filters_filter-modal_certAccount{
  flex-grow:1;
  padding-left:3px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList .zimbra-client_filters_filter-modal_certificate .zimbra-client_filters_filter-modal_viewCert{
  color:var(--brand-tertiary-500);
  cursor:pointer;
  padding-right:3px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_smimeSection .zimbra-client_filters_filter-modal_subsection .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smimeCertificatesList .zimbra-client_filters_filter-modal_certificate .zimbra-client_filters_filter-modal_viewCertError{
  color:var(--brand-danger-500);
  padding-right:3px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection .zimbra-client_filters_filter-modal_subsection{
  flex-wrap:wrap;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection p{
  margin:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection .zimbra-client_filters_filter-modal_alignedRadioButton{
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection .zimbra-client_filters_filter-modal_alignedRadioButton:not(:last-child){
  margin-bottom:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection .zimbra-client_filters_filter-modal_alignedRadioButton .zimbra-client_filters_filter-modal_radioButtonWrapper{
  flex:1 0 auto;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection .zimbra-client_filters_filter-modal_alignedRadioButton label{
  padding-top:1px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_offlineModeSection .zimbra-client_filters_filter-modal_bold{
  font-weight:700;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailAndStatus{
  max-width:474px;
  display:flex;
  flex-direction:row;
  margin-right:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailAndStatus .zimbra-client_filters_filter-modal_pending{
  color:var(--brand-danger-500);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailAndStatus .zimbra-client_filters_filter-modal_verified{
  color:var(--brand-success-500);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailAndStatus .zimbra-client_filters_filter-modal_recoveryEmail{
  flex:1 0 85%;
  font-size:var(--font-size-med);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailAndStatus .zimbra-client_filters_filter-modal_status{
  flex:1 0 15%;
  text-align:right;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailAndStatus .zimbra-client_filters_filter-modal_statusIcon{
  margin-right:5px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inputError:focus{
  outline-color:var(--brand-danger-500);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inlineModalTitle{
  color:var(--gray-darkest);
  font-size:var(--font-size-large);
  font-weight:normal;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inlineModalWrapper{
  border-radius:5px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inlineModalWrapper .zimbra-client_filters_filter-modal_inlineError{
  margin:0 calc(-1 * var(--spacing-lg)) var(--spacing-md);
  color:var(--brand-danger-500);
  background-color:var(--brand-danger-50);
  padding:var(--spacing-sm) var(--spacing-lg);
  font-size:var(--font-size-base);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inlineModalWrapper .zimbra-client_filters_filter-modal_inlineError.zimbra-client_filters_filter-modal_success{
  color:var(--brand-success-500);
  background-color:var(--brand-success-50);
}
@media (max-width: 769px){
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inlineModalWrapper .zimbra-client_filters_filter-modal_inlineModal{
    height:200px;
  }
  .zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_inlineModalWrapper .zimbra-client_filters_filter-modal_inlineModalToolbar{
    position:absolute;
  }
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_verificationMessage{
  margin-bottom:calc(var(--spacing-md) + var(--spacing-xs));
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_noLeftSpace{
  padding-left:0;
  margin-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_noLeftMargin{
  margin-left:0;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_actionSection .zimbra-client_filters_filter-modal_modifyButtons{
  width:100%;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_emailInput{
  margin-bottom:var(--spacing-md);
  width:50%;
  min-width:250px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_accountRecovery .zimbra-client_filters_filter-modal_codeInput{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_zimletList li{
  border-bottom:1px solid var(--gray-lighter);
  display:block;
  color:var(--gray-darker);
  padding:var(--spacing-sm) 0;
  font-size:var(--font-size-base);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_zimletList li:first-child{
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_zimletList li .zimbra-client_filters_filter-modal_radioButtonWrapper{
  display:flex;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_zimletList li label{
  padding-top:1px;
}
.zimbra-client_filters_filter-modal_settings .zimbra-client_filters_filter-modal_zimletList li label .zimbra-client_filters_filter-modal_description{
  color:var(--gray);
}
.zimbra-client_filters_filter-modal_editAccountTitle{
  font-size:var(--font-size-h3);
  font-weight:300;
  -webkit-font-smoothing:subpixel-antialiased;
}
.zimbra-client_filters_filter-modal_testingDataSourceContainer{
  padding-top:100px;
  text-align:center;
}
.zimbra-client_filters_filter-modal_testingDataSourceSection{
  text-align:center;
  margin-bottom:12px;
}
.zimbra-client_filters_filter-modal_testingDataSourceSection .zimbra-client_filters_filter-modal_check{
  display:inline-block;
}
.zimbra-client_filters_filter-modal_spinner{
  font-size:27px;
}
.zimbra-client_filters_filter-modal_animatedCheckmark{
  height:32px;
}
.zimbra-client_filters_filter-modal_successMessage,
.zimbra-client_filters_filter-modal_errorMessage,
.zimbra-client_filters_filter-modal_helpTooltipTitle,
.zimbra-client_filters_filter-modal_textButtonBold{
  font-weight:bold;
}
.zimbra-client_filters_filter-modal_successMessage{
  color:var(--brand-success-500);
}
.zimbra-client_filters_filter-modal_warningIcon,
.zimbra-client_filters_filter-modal_errorMessage{
  color:var(--brand-danger-500);
}
.zimbra-client_filters_filter-modal_errorMessage{
  word-break:break-word;
}
.zimbra-client_filters_filter-modal_downloadFolderPlaceholder{
  color:var(--gray-placeholder);
}
.zimbra-client_filters_filter-modal_downloadFolderName{
  display:inline-block;
  margin-left:var(--spacing-xs);
}
.zimbra-client_filters_filter-modal_largeLabel{
  margin-bottom:var(--spacing-xs);
}
.zimbra-client_filters_filter-modal_inlineRadioButton{
  margin-right:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_inputWithRightHint{
  display:flex;
  flex-direction:row;
  align-items:center;
  flex:auto;
}
.zimbra-client_filters_filter-modal_inputWithRightHint .zimbra-client_filters_filter-modal_changePortLabel{
  padding-right:12px;
}
.zimbra-client_filters_filter-modal_advancedToggle{
  color:var(--brand-tertiary-500);
  cursor:pointer;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.zimbra-client_filters_filter-modal_externalPassword{
  display:block;
  width:100%;
  padding:10px;
  font-size:100%;
  border:1px solid var(--gray-lighter);
  border-radius:2px;
  outline:none;
}
.zimbra-client_filters_filter-modal_w100{
  width:100%;
}
.zimbra-client_filters_filter-modal_w100 .zimbra-client_filters_filter-modal_toggleFolder{
  padding-bottom:var(--spacing-sm);
}
@media (max-width: 768px){
  .zimbra-client_filters_filter-modal_w100 .zimbra-client_filters_filter-modal_toggleFolder{
    padding-top:var(--spacing-sm);
  }
}
.zimbra-client_filters_filter-modal_defaultCalTooltip,
.zimbra-client_filters_filter-modal_defaultGeneralTooltip{
  width:275px;
}
@media (max-width: 480px){
  .zimbra-client_filters_filter-modal_defaultCalTooltip,
  .zimbra-client_filters_filter-modal_defaultGeneralTooltip{
    margin-left:-150px;
  }
}
@media (max-width: 360px){
  .zimbra-client_filters_filter-modal_defaultCalTooltip,
  .zimbra-client_filters_filter-modal_defaultGeneralTooltip{
    margin-left:-200px;
  }
}
.zimbra-client_filters_filter-modal_danger{
  color:var(--brand-danger-500);
}
.zimbra-client_filters_filter-modal_warning{
  color:var(--brand-warning-500);
}
.zimbra-client_filters_filter-modal_success{
  color:var(--brand-success-500);
}
.zimbra-client_filters_filter-modal_disabledText{
  color:var(--gray);
}
.zimbra-client_filters_filter-modal_notYetImplemented{
  display:none !important;
}
.zimbra-client_filters_filter-modal_signatureTitle{
  font-size:var(--font-size-large);
  padding-bottom:18px;
}
.zimbra-client_filters_filter-modal_blockSection div.zimbra-client_filters_filter-modal_addressErrorMessage{
  padding:7px 20px;
  margin:5px -20px 3px -20px;
}
.zimbra-client_filters_filter-modal_blockOrAllowTitle{
  font-size:var(--font-size-large);
  padding-bottom:7px;
}
.zimbra-client_filters_filter-modal_settingsSection{
  margin-top:20px;
}
.zimbra-client_filters_filter-modal_blockOrAllowSubSectionTitle{
  margin-top:10px;
}
.zimbra-client_filters_filter-modal_twoFactorAuthDescription{
  margin:0 0 10px 0;
}
.zimbra-client_filters_filter-modal_wrapTwoFactorAuthInfo{
  padding-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_wrapTwoFactorAuthInfo .zimbra-client_filters_filter-modal_subsection{
  margin-bottom:var(--spacing-md);
}
@media (min-width: 1300px){
  .zimbra-client_filters_filter-modal_wrapTwoFactorAuthInfo .zimbra-client_filters_filter-modal_subsectionBody{
    margin-left:var(--spacing-md);
  }
}
.zimbra-client_filters_filter-modal_wrapTwoFactorAuthInfo .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_nakedButton:not(:disabled){
  color:var(--brand-primary-500);
}
.zimbra-client_filters_filter-modal_wrapTwoFactorAuthInfo .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_trustedDevicesActionContainer{
  margin-top:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_oneTimeCodeDialog{
  width:260px;
  height:354px;
}
.zimbra-client_filters_filter-modal_oneTimeCodeDialog .zimbra-client_filters_filter-modal_hideContent{
  display:none;
}
.zimbra-client_filters_filter-modal_loadingDialogContent div[class*="inner"]{
  background-color:var(--gray-lightest);
}
.zimbra-client_filters_filter-modal_oneTimeCodeContainer{
  width:100%;
  margin:var(--spacing-md) 0 0;
  display:flex;
  flex-wrap:wrap;
}
.zimbra-client_filters_filter-modal_oneTimeCodeContainer .zimbra-client_filters_filter-modal_oneTimeCode{
  width:50%;
  margin:var(--spacing-sm) 0;
  color:var(--gray-darker);
  font-weight:bold;
}
.zimbra-client_filters_filter-modal_copyToclipboard{
  margin:var(--spacing-sm) 0;
}
.zimbra-client_filters_filter-modal_copyToclipboard button{
  color:var(--gray-dark);
}
.zimbra-client_filters_filter-modal_generalSection select{
  min-width:196px;
  width:100%;
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_usageBar{
  background:var(--gray-lightest);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_usageBar .zimbra-client_filters_filter-modal_usage > div.zimbra-client_filters_filter-modal_animationBar{
  position:relative;
  width:100%;
  height:var(--spacing-xl);
  line-height:var(--spacing-xl);
  -webkit-animation-name:zimbra-client_filters_filter-modal_progress;
          animation-name:zimbra-client_filters_filter-modal_progress;
  -webkit-animation-duration:1.5s;
          animation-duration:1.5s;
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_usageBar .zimbra-client_filters_filter-modal_usage span{
  white-space:pre;
  min-width:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_usageBar .zimbra-client_filters_filter-modal_usage span.zimbra-client_filters_filter-modal_textWhite{
  color:#fff;
  position:absolute;
  right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_usageBar .zimbra-client_filters_filter-modal_usage span:not(.zimbra-client_filters_filter-modal_textWhite){
  position:absolute;
  right:calc(-1 * var(--spacing-xl));
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_defaultMailAppEnabled{
  opacity:50%;
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_defaultMailAppNote{
  margin-top:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_autoArchiveSettings{
  margin-top:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_autoArchiveSettings .zimbra-client_filters_filter-modal_note{
  margin-bottom:var(--spacing-md);
  padding:2px var(--spacing-md) 0 0;
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_autoArchiveSettings .zimbra-client_filters_filter-modal_note:first-child{
  margin-bottom:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_autoArchiveSettings .zimbra-client_filters_filter-modal_frequencyInputContainer{
  margin-top:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_generalSection .zimbra-client_filters_filter-modal_autoArchiveSettings .zimbra-client_filters_filter-modal_frequencyInputContainer .zimbra-client_filters_filter-modal_frequencyInput{
  width:72px;
  margin:0 var(--spacing-xs);
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_infoSection{
  padding:var(--spacing-sm) 0;
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_alignSectionLabel{
  padding-top:7px;
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_syncInfo{
  padding:var(--spacing-sm) 0;
  position:relative;
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_syncInfo.zimbra-client_filters_filter-modal_success{
  color:var(--brand-success-500);
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_syncInfo.zimbra-client_filters_filter-modal_danger{
  color:var(--brand-danger-500);
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_syncInfo .zimbra-client_filters_filter-modal_syncStatusIcon{
  margin-right:7px;
}
.zimbra-client_filters_filter-modal_offlineDesktopSection .zimbra-client_filters_filter-modal_buttonNoMargin{
  margin:0;
}
.zimbra-client_filters_filter-modal_composerDefaultSection{
  margin-bottom:calc(var(--spacing-lg) + var(--spacing-xs));
}
@media (min-width: 768px){
  .zimbra-client_filters_filter-modal_composerDefaultSection{
    margin-bottom:var(--spacing-md);
  }
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_composerDefaultValues{
  padding:12px;
  margin-top:var(--spacing-sm);
  border:1px solid var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_textColorpicker{
  margin-left:var(--spacing-sm);
  position:relative;
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_textColorpicker .zimbra-client_filters_filter-modal_colorPicker{
  opacity:0;
  position:absolute;
  width:90px;
  cursor:pointer;
  left:0;
  top:0;
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_textColorpicker .zimbra-client_filters_filter-modal_dropDownIcon{
  margin:0 var(--spacing-sm);
  color:var(--gray-base);
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton{
  margin-right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton li.zimbra-client_filters_filter-modal_dropdownOption{
  text-align:left;
  background:none;
  color:var(--gray-darker);
  padding:var(--spacing-sm) var(--spacing-md);
  font-weight:normal;
  text-transform:none;
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton li.zimbra-client_filters_filter-modal_dropdownOption:hover,
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton li.zimbra-client_filters_filter-modal_dropdownOption:focus{
  background:var(--gray-lightest);
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton li.zimbra-client_filters_filter-modal_dropdownOption > div[class*="itemTitle"]{
  float:none;
  padding-left:var(--spacing-lg);
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton .zimbra-client_filters_filter-modal_toggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton .zimbra-client_filters_filter-modal_toggleButtonClass p{
  font-weight:normal;
  color:var(--gray-darker);
  text-transform:none;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton .zimbra-client_filters_filter-modal_toggleButtonClass p{
    padding-left:0;
    margin-top:10px;
  }
}
.zimbra-client_filters_filter-modal_composerDefaultSection .zimbra-client_filters_filter-modal_selectButton .zimbra-client_filters_filter-modal_toggleButtonClass span[role="img"]{
  vertical-align:middle;
  font-size:var(--icon-size-xs);
}
.zimbra-client_filters_filter-modal_sharing{
  width:100%;
}
.zimbra-client_filters_filter-modal_sharing .zimbra-client_filters_filter-modal_shareType{
  font-size:var(--font-size-large);
  color:var(--gray-darkest);
  padding-bottom:var(--spacing-md);
}
.zimbra-client_filters_filter-modal_sharing .zimbra-client_filters_filter-modal_shareType:not(:first-child){
  margin-top:var(--spacing-xl);
}
.zimbra-client_filters_filter-modal_sharing .zimbra-client_filters_filter-modal_shareInfo{
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_filters_filter-modal_sharing .zimbra-client_filters_filter-modal_shareInfo .zimbra-client_filters_filter-modal_sharingItem{
  border-top:1px solid var(--gray-lighter);
  padding:var(--spacing-sm) 0 var(--spacing-sm) 12px;
  color:var(--gray-darkest);
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_sharing .zimbra-client_filters_filter-modal_shareInfo .zimbra-client_filters_filter-modal_sharingItem{
    padding-left:0;
  }
}
.zimbra-client_filters_filter-modal_sharing .zimbra-client_filters_filter-modal_nothingToShow{
  color:var(--gray);
}
@-webkit-keyframes zimbra-client_filters_filter-modal_progress{
  0%{
    width:0%;
  }
  33%{
    width:0%;
  }
  100%{
    width:100%;
  }
}
@keyframes zimbra-client_filters_filter-modal_progress{
  0%{
    width:0%;
  }
  33%{
    width:0%;
  }
  100%{
    width:100%;
  }
}
.zimbra-client_filters_filter-modal_domainAddressListError{
  margin-top:0;
}
.zimbra-client_filters_filter-modal_header,
.zimbra-client_filters_filter-modal_footer{
  padding:14px 24px;
}
.zimbra-client_filters_filter-modal_header{
  font-size:var(--font-size-h3);
  font-weight:300;
  display:flex;
  justify-content:space-between;
  color:var(--gray-darkest);
  padding-right:14px;
}
.zimbra-client_filters_filter-modal_inner{
  display:flex;
  flex-direction:column;
  height:85vh;
  border-radius:3px;
  background-color:#ffffff;
  box-shadow:0 4px 6px 0 rgba(0, 0, 0, 0.36);
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_inner{
    height:auto;
    flex:1 0 auto;
  }
}
.zimbra-client_filters_filter-modal_contentWrapper{
  min-width:848px;
  flex:1 1 auto;
  border-top:1px solid var(--border-color);
  border-bottom:1px solid var(--border-color);
  background-color:#ffffff;
  position:relative;
  display:flex;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_contentWrapper{
    height:100%;
    min-width:100%;
    border-bottom:unset;
    border-top:unset;
  }
}
.zimbra-client_filters_filter-modal_contentWrapper > div{
  position:absolute;
}
@media (min-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalInner{
    width:800px;
    height:auto;
  }
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_title{
    font-size:var(--font-size-large);
    color:var(--gray-base);
  }
  .zimbra-client_filters_filter-modal_rulePrompt{
    font-weight:500;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent{
  margin-top:1px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent{
    padding:var(--spacing-md);
    overflow:auto;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsection{
  margin-bottom:var(--spacing-md);
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsection{
    margin-bottom:var(--spacing-xs);
    border-bottom:1px solid var(--gray-lighter);
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionTitle{
  min-width:186px;
  width:186px;
  line-height:33px;
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody{
  max-width:580px;
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smallerSelect{
  width:152px;
  display:inline-block;
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_selectWrapper{
  margin-bottom:10px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody{
    margin:var(--spacing-xs) 0;
    max-width:none;
    display:flex;
  }
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_half{
    width:49%;
    margin-right:2%;
  }
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_textInputOfFilter{
    flex-grow:1;
    padding:5px calc(var(--spacing-md) - var(--spacing-xs)) var(--spacing-sm);
    border-radius:3px;
    background-color:#fff;
    border:solid 1px var(--gray-lighter);
    width:49%;
    height:36px;
  }
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_textInputOfFilter.zimbra-client_filters_filter-modal_actionsInput{
    width:100%;
  }
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_itemSelect{
    width:100%;
    margin:0;
  }
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_subsectionBody .zimbra-client_filters_filter-modal_smallerSelect{
    margin-left:10px;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_checkbox{
  margin-left:15px;
  color:var(--gray-darkest);
  opacity:0.5;
  white-space:nowrap;
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_checkbox.zimbra-client_filters_filter-modal_checked{
  opacity:1;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_checkbox{
    margin:14px 0 calc(var(--spacing-md) - var(--spacing-xs)) 0;
    display:block;
    width:-webkit-max-content;
    width:-moz-max-content;
    width:max-content;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_titleSubsection,
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_moveMessageSubsection[class].zimbra-client_filters_filter-modal_marginBottom{
  margin-bottom:var(--spacing-xl);
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_titleSubsection,
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_moveMessageSubsection[class].zimbra-client_filters_filter-modal_marginBottom{
    margin-bottom:var(--spacing-md);
    border-bottom:none;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_thenLabel{
  line-height:18px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_thenLabel{
    margin:calc(var(--spacing-md) - var(--spacing-xs)) 0;
    width:auto;
    font-weight:500;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_processAdditional{
  display:inline-block;
  margin-top:10px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_processAdditional{
    margin:var(--spacing-sm) 0;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_ruleSection{
  margin-top:calc(var(--spacing-md) - var(--spacing-xs));
  margin-bottom:40px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_ruleSection{
    margin:0;
  }
}
.zimbra-client_filters_filter-modal_filterModalContent .zimbra-client_filters_filter-modal_inline{
  display:inline;
  margin-right:1%;
}
.zimbra-client_filters_filter-modal_advancedFilterSetting{
  color:var(--brand-tertiary-500);
}
.zimbra-client_filters_filter-modal_advancedFilterModal{
  height:70vh;
  font-size:var(--font-size-base);
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal{
    height:100%;
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedMainBody{
  padding:var(--spacing-sm);
  margin-bottom:0;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_filterName{
  width:73%;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_filterTitleLabel{
  min-width:80px;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_inputField{
  height:34px;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_inputFieldForRedirectAddress{
  width:100%;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedActionModal{
  display:flex;
  flex-direction:column;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedActions{
  max-width:100%;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedActions .zimbra-client_filters_filter-modal_inputSelectField{
    margin-right:var(--spacing-sm);
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedActionSubsectionBody{
  margin-left:2px;
  display:inline-block;
  width:184px;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedConditionSubsectionBody{
  margin-left:0px;
}
@media (min-width: 1025px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedConditionSubsectionBody{
    margin-left:var(--spacing-sm);
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_inputSelectField{
  background-color:var(--body-bg);
  padding:0;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedCloseButton{
  float:right;
  height:34px;
}
@media (min-width: 1025px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedCloseButton{
    display:none;
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_conditionInputValue{
  padding:7px calc(var(--spacing-md) - var(--spacing-xs));
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_actionDropDown{
  height:34px;
  box-sizing:border-box;
  padding:7px 34px 7px calc(var(--spacing-md) - var(--spacing-xs));
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedMainBody:hover .zimbra-client_filters_filter-modal_advancedCloseButton{
  display:inline-block;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedMainBody:hover{
  background-color:#f1f1f1;
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_conditionListWrapper{
  margin-top:calc(var(--spacing-md) - var(--spacing-xs));
  margin-bottom:15px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_conditionListWrapper{
    margin:0;
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_additionalFilters{
  margin-right:var(--spacing-sm);
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderInput{
  width:135px;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedSubsection .zimbra-client_filters_filter-modal_subsectionTitle{
    min-width:100px;
    width:49%;
  }
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_advancedConditionSubsectionBody .zimbra-client_filters_filter-modal_inputField{
    height:34px;
  }
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderBody{
    padding-bottom:35px;
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderBody .zimbra-client_filters_filter-modal_advancedSubsection{
  display:flex;
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderBody .zimbra-client_filters_filter-modal_advancedSubsection{
    display:block;
  }
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderBody .zimbra-client_filters_filter-modal_advancedSubsection .zimbra-client_filters_filter-modal_inputSelectField{
    float:left;
    width:49%;
    margin-right:2%;
  }
}
.zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderBody .zimbra-client_filters_filter-modal_advancedSubsection .zimbra-client_filters_filter-modal_namedHeaderInput{
  margin-left:var(--spacing-sm);
}
@media (max-width: 1024px){
  .zimbra-client_filters_filter-modal_advancedFilterModal .zimbra-client_filters_filter-modal_namedHeaderBody .zimbra-client_filters_filter-modal_advancedSubsection .zimbra-client_filters_filter-modal_namedHeaderInput{
    margin-left:0;
    height:34px;
  }
}
