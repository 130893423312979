@media (max-width: 480px){
  .zimbra-client_breadcrumb_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_breadcrumb_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_breadcrumb_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_breadcrumb_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_breadcrumb_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_breadcrumb_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_breadcrumb_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_breadcrumb_breadcrumb{
  margin:0;
  margin-bottom:15px;
  padding:0;
}
.zimbra-client_breadcrumb_breadcrumb li{
  display:inline;
  font-size:var(--font-size-base);
  color:#454545;
}
.zimbra-client_breadcrumb_breadcrumb li a{
  text-transform:none;
  color:var(--brand-tertiary-500);
}
.zimbra-client_breadcrumb_breadcrumb li a:hover{
  cursor:pointer;
}
.zimbra-client_breadcrumb_breadcrumb li:not(:last-child){
  position:relative;
  margin-right:13px;
}
.zimbra-client_breadcrumb_breadcrumb li:not(:last-child)::after{
  content:'>';
  position:absolute;
  right:-10px;
  bottom:-2px;
}
