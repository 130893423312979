@media (max-width: 480px){
  .zimbra-client_reminders_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_reminders_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_reminders_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_reminders_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_reminders_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_reminders_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_reminders_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_reminders_container{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:100;
}
.zimbra-client_reminders_cover{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:var(--gray-lightest);
}
.zimbra-client_reminders_modalDialog{
  width:50%;
  max-width:800px;
  min-width:650px;
  max-height:80%;
  overflow:auto;
  background:white;
}
@media (max-width: 1024px){
  .zimbra-client_reminders_modalDialog{
    width:80%;
    min-width:0;
    min-width:initial;
  }
}
.zimbra-client_reminders_apptWindow{
  background-color:#fff;
}
.zimbra-client_reminders_apptHeading{
  background-color:var(--gray-lightest);
  padding:10px 24px;
  position:sticky;
  top:-1px;
  z-index:1;
}
.zimbra-client_reminders_apptHeading h5{
  margin:0;
  font-size:var(--font-size-base);
  font-weight:normal;
  color:var(--gray-dark);
  line-height:15px;
}
.zimbra-client_reminders_apptContainer{
  list-style-type:none;
  display:block;
  margin:0;
  padding:0;
}
.zimbra-client_reminders_apptContainer.zimbra-client_reminders_multi .zimbra-client_reminders_appt{
  border-bottom:solid 1px var(--gray-lightest);
}
.zimbra-client_reminders_apptContainer .zimbra-client_reminders_appt{
  padding:15px 24px 26px;
}
.zimbra-client_reminders_apptContainer .zimbra-client_reminders_appt a,
.zimbra-client_reminders_apptContainer .zimbra-client_reminders_appt a:hover{
  text-decoration:none;
  outline:none;
}
.zimbra-client_reminders_apptContainer .zimbra-client_reminders_appt h4{
  display:inline-block;
}
.zimbra-client_reminders_apptName{
  margin:0;
  font-size:var(--font-size-base);
  line-height:17px;
  cursor:pointer;
  color:var(--brand-tertiary-500);
  font-weight:bold;
}
.zimbra-client_reminders_actionBtn + .zimbra-client_reminders_actionBtn{
  margin-left:8px;
}
.zimbra-client_reminders_apptMetaActionsContainer{
  margin:10px 0 0;
  display:flex;
  justify-content:space-between;
}
@media (max-width: 1024px){
  .zimbra-client_reminders_apptMetaActionsContainer{
    justify-content:initial;
    display:inline-block;
    width:100%;
  }
}
@media (max-width: 1024px){
  .zimbra-client_reminders_apptMeta{
    display:inline-block;
    width:100%;
  }
}
.zimbra-client_reminders_apptMeta i,
.zimbra-client_reminders_apptMeta b{
  display:block;
  font-size:var(--font-size-base);
  line-height:15px;
}
.zimbra-client_reminders_apptMeta i{
  margin:0 0 6px;
  font-style:normal;
  color:#454545;
}
.zimbra-client_reminders_apptMeta b{
  color:#cf2a2a;
}
.zimbra-client_reminders_allActions{
  padding:16px 24px 24px;
  position:sticky;
  bottom:0;
  background:white;
  border-top:solid 1px var(--gray-lightest);
}
.zimbra-client_reminders_apptActions,
.zimbra-client_reminders_allActions{
  display:flex;
}
.zimbra-client_reminders_actionMenu{
  margin-left:1px;
  height:100%;
}
.zimbra-client_reminders_snoozeReminderButton{
  display:inline-flex;
  margin:5px 0 5px 5px;
}
.zimbra-client_reminders_snoozeButton{
  margin:0;
  border-radius:3px 0 0 3px;
}
.zimbra-client_reminders_snoozeClockButton{
  height:100%;
  border-radius:0 3px 3px 0;
  background-color:var(--brand-primary-500);
  color:var(--text-color-light);
  padding:var(--spacing-sm);
  border:none;
}
.zimbra-client_reminders_snoozeClockButton:hover,
.zimbra-client_reminders_snoozeClockButton:focus{
  background-color:var(--brand-primary-400);
  color:var(--text-color-light);
}
.zimbra-client_reminders_toggleButton,
.zimbra-client_reminders_toggleButton > span{
  height:100%;
}
.zimbra-client_reminders_apptActions{
  margin-top:20px;
}
@media (max-width: 1024px){
  .zimbra-client_reminders_apptActions{
    display:inline-block;
    width:100%;
    text-align:right;
  }
}
