@media (max-width: 480px){
  .blocks_choice-input_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .blocks_choice-input_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .blocks_choice-input_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .blocks_choice-input_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .blocks_choice-input_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .blocks_choice-input_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .blocks_choice-input_hideMdUp{
    display:none !important;
  }
}
.blocks_choice-input_choiceInputContainer{
  display:inline-block;
  position:relative;
  vertical-align:middle;
  width:13px;
  height:13px;
  min-width:13px;
  margin-right:6px;
  border-radius:2px;
}
.blocks_choice-input_choiceInputContainer input[type='checkbox'],
.blocks_choice-input_choiceInputContainer input[type='radio']{
  opacity:0;
  width:14px;
  height:14px;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  position:absolute;
  z-index:1;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  cursor:pointer;
}
.blocks_choice-input_choiceInputContainer input[type='checkbox'] + i,
.blocks_choice-input_choiceInputContainer input[type='radio'] + i{
  vertical-align:top;
  font-size:20px;
  font-style:normal;
}
.blocks_choice-input_choiceInputContainer input[type='checkbox'] + i:before,
.blocks_choice-input_choiceInputContainer input[type='radio'] + i:before{
  content:var(--zimbra-icon-square-o);
  position:absolute;
  display:block;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  font-family:'zimbra-icons';
  margin-top:-1px;
}
.blocks_choice-input_choiceInputContainer input[type='checkbox']:checked + i:before,
.blocks_choice-input_choiceInputContainer input[type='radio']:checked + i:before{
  color:var(--brand-primary-500);
  content:var(--zimbra-icon-check-square);
}
.blocks_choice-input_choiceInputContainer input[type='checkbox']:indeterminate + i:before,
.blocks_choice-input_choiceInputContainer input[type='radio']:indeterminate + i:before{
  color:var(--brand-primary-500);
  content:var(--zimbra-icon-minus-square);
}
.blocks_choice-input_choiceInputContainer input[type='checkbox'][disabled],
.blocks_choice-input_choiceInputContainer input[type='radio'][disabled]{
  cursor:default;
  pointer-events:none;
}
.blocks_choice-input_choiceInputContainer input[type='checkbox'][disabled] + i,
.blocks_choice-input_choiceInputContainer input[type='radio'][disabled] + i{
  opacity:0.5;
}
.blocks_choice-input_choiceInputContainer[class*=radioInput] input[type='radio'] + i:before{
  content:var(--zimbra-icon-radio);
}
.blocks_choice-input_choiceInputContainer[class*=radioInput] input[type='radio']:checked + i:before{
  content:var(--zimbra-icon-radio-active);
}
