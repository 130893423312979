@media (max-width: 480px){
  .zimbra-client_help-button_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_help-button_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_help-button_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_help-button_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_help-button_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_help-button_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_help-button_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_help-button_helpButton{
  display:inline-block;
  width:22px;
  height:22px;
  margin:-2px 0 0 2px;
  border-radius:50%;
}
.zimbra-client_help-button_helpPopover{
  width:380px;
  padding:17px 62px 14px 24px;
  border:1px solid var(--gray-lighter);
  border-radius:0;
  box-shadow:0 2px 10px rgba(0, 0, 0, 0.05);
  color:var(--text-color);
  font-weight:normal;
}
.zimbra-client_help-button_helpPopover h6{
  font-weight:normal;
  font-size:100%;
  margin:0;
  padding:0;
}
.zimbra-client_help-button_helpPopover p{
  margin:0;
  padding:1em 0 0;
}
.zimbra-client_help-button_helpPopover .zimbra-client_help-button_close{
  position:absolute;
  right:14px;
  top:14px;
  font-size:var(--font-size-med);
  color:var(--text-color);
  font-weight:normal;
  background:none;
  border:none;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  cursor:pointer;
}
.zimbra-client_help-button_helpPopover .zimbra-client_help-button_close:hover{
  background:none;
}
