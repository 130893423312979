@media (max-width: 480px){
  .zimbra-client_import-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_import-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_import-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_import-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_import-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_import-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_import-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_import-modal_chooseFileWrapper{
  margin-left:0;
}
.zimbra-client_import-modal_importSettingSection{
  padding-bottom:20px;
}
.zimbra-client_import-modal_importSettingSection .zimbra-client_import-modal_importDescription{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_import-modal_importSettingSection span{
  margin-left:var(--spacing-md);
}
.zimbra-client_import-modal_importSettingSection button{
  min-width:120px;
  margin-left:0;
}
.zimbra-client_import-modal_importSettingSection button[disabled]{
  cursor:default;
}
.zimbra-client_import-modal_sectionTitle{
  font-size:20px;
  color:var(--gray-darkest);
  padding-bottom:calc(var(--spacing-md) + 4px);
}
@media (min-width: 768px){
  .zimbra-client_import-modal_sectionTitle{
    font-size:var(--font-size-large);
    padding-bottom:calc(var(--spacing-sm) + 2px);
  }
}
