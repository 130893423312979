@media (max-width: 480px){
  .zimbra-client_contact-picker_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_contact-picker_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_contact-picker_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_contact-picker_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-picker_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-picker_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_contact-picker_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_contact-picker_contact{
  padding:calc(var(--spacing-sm) - 2px) var(--spacing-md);
  cursor:pointer;
  border-bottom:1px solid var(--gray-lighter);
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  display:flex;
  align-items:center;
}
.zimbra-client_contact-picker_contact > *{
  vertical-align:baseline;
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem{
  margin-right:calc(var(--spacing-xs) + 1px);
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem.zimbra-client_contact-picker_contactFields{
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:pre;
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem.zimbra-client_contact-picker_contactFields.zimbra-client_contact-picker_emailField{
  flex:1;
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem.zimbra-client_contact-picker_contactFields.zimbra-client_contact-picker_emailField span{
  color:var(--gray-light);
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem .zimbra-client_contact-picker_avatar{
  width:36px;
  height:36px;
  flex-shrink:0;
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem [class*=choiceInputContainer]{
  margin-right:calc(var(--spacing-sm) - 2px);
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem span{
  display:inline-block;
  padding:0 5px;
  font-size:var(--font-size-base);
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem .zimbra-client_contact-picker_contactEmail{
  padding:0 5px;
  margin:0;
  font-weight:normal;
  color:var(--gray);
  font-size:var(--font-size-small);
}
.zimbra-client_contact-picker_contact .zimbra-client_contact-picker_flexItem .zimbra-client_contact-picker_contactName{
  margin:0;
  font-weight:normal;
  font-size:var(--font-size-base);
  display:block;
}
.zimbra-client_contact-picker_contact strong{
  display:inline-block;
  padding:0 5px;
  font-size:var(--font-size-base);
  color:var(--gray-darker);
}
.zimbra-client_contact-picker_contact.zimbra-client_contact-picker_selected{
  background-color:var(--gray-lightest);
}
.zimbra-client_contact-picker_additionalSelected{
  padding:10px;
  color:var(--gray-placeholder);
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  text-align:left;
  padding:calc(var(--spacing-sm) + 2px) var(--spacing-md);
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_contact-picker_dialog{
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  width:432px;
}
@media (max-width: 432px){
  .zimbra-client_contact-picker_dialog{
    width:100%;
  }
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_inner{
  background:var(--body-bg);
  border-radius:3px;
  width:100%;
  height:100%;
  box-shadow:0 4px 6px rgba(0, 0, 0, 0.36);
  cursor:default;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header{
  padding:0;
  background:var(--off-white);
  border-bottom:1px solid var(--gray-lighter);
  color:var(--text-color);
  text-align:center;
  height:60px;
  border-bottom:none;
  background:none;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header h2{
  margin:0 90px 0 40px;
  padding:10px 0;
  color:var(--dialog-title-fg);
  font-weight:normal;
  font-size:120%;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header .zimbra-client_contact-picker_actionButton{
  position:absolute;
  right:5px;
  top:5px;
  margin:0;
  z-index:1;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header .zimbra-client_contact-picker_actionButton .zimbra-client_contact-picker_icon{
  display:inline-block;
  position:relative;
  width:1.5em;
  height:1em;
  padding:0 0 0 5px;
  overflow:hidden;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header .zimbra-client_contact-picker_closeButton{
  position:absolute;
  left:5px;
  top:5px;
  padding:7px;
  margin:0;
  background:var(--gray-light);
  border-radius:50%;
  vertical-align:middle;
  font-size:100%;
  font-weight:normal;
  color:white;
  cursor:pointer;
  z-index:1;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header h2{
  font-size:20px;
  margin:0;
  padding:22px 24px 0;
  text-align:left;
  color:var(--dialog-title-fg);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header .zimbra-client_contact-picker_actionButton{
  top:16px;
  right:16px;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_content{
  position:relative;
  padding:0 var(--spacing-lg) var(--spacing-lg);
  margin:0;
  font-family:var(--font-family-sans-serif);
  font-size:var(--font-size-base);
  -webkit-font-smoothing:auto;
}
.zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable{
  height:328px;
}
.zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_hasFooter .zimbra-client_contact-picker_content{
  padding-bottom:76px;
}
@media (max-height: 348px){
  .zimbra-client_contact-picker_dialog,
  .zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable{
    height:100%;
  }
}
.zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable .zimbra-client_contact-picker_content{
  position:absolute;
  left:0;
  width:100%;
  top:76px;
  bottom:76px;
  padding:0 24px;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
@media (max-height: 328px){
  .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_content{
    position:absolute;
    left:0;
    width:100%;
    top:76px;
    bottom:76px;
    padding:0 24px;
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
  }
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_spinner{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
}
.zimbra-client_contact-picker_dialog [disabled]{
  opacity:0.5;
  pointer-events:none;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_footer{
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  height:76px;
  padding:20px 24px 24px;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_footer > :first-child{
  margin-left:0;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header{
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_header h2{
  margin:0;
  padding:var(--spacing-md) var(--spacing-md) 2px;
  font-size:20px;
  font-weight:normal;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_inner{
  display:flex;
  flex-direction:column;
  padding-bottom:76px;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_description{
  margin:0;
  padding:0 var(--spacing-md);
  text-align:left;
  font-size:var(--font-size-base);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder{
  display:inline-block;
  width:50%;
  padding:0;
  border:none;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer{
  border:1px solid var(--gray-lighter);
  border-radius:3px;
  width:100%;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle{
  width:100%;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton button,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton button,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle button,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle button{
  width:100%;
  height:26px;
  padding:6px;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton button .zimbra-icon-angle-down,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton button .zimbra-icon-angle-down,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle button .zimbra-icon-angle-down,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle button .zimbra-icon-angle-down{
  float:right;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton button .zimbra-client_action-menu_label,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverButton button .zimbra-client_action-menu_label,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle button .zimbra-client_action-menu_label,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_dropdownContainer .zimbra-client_contact-picker_popoverTitle button .zimbra-client_action-menu_label{
  float:left;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query input,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder input,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_select,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select{
  display:block;
  width:100%;
  max-width:100%;
  margin:0;
  padding:6px;
  background:#FFF;
  border:1px solid var(--gray-lighter);
  font-size:var(--font-size-base);
  border-radius:3px;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  outline:none;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query input::-moz-placeholder, .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder input::-moz-placeholder, .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_select::-moz-placeholder, .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query input:-ms-input-placeholder, .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder input:-ms-input-placeholder, .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_select:-ms-input-placeholder, .zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query input::placeholder,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder input::placeholder,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_select::placeholder,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select::placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query input:focus,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder input:focus,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_query .zimbra-client_contact-picker_select:focus,
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select:focus{
  border-color:var(--brand-primary-500);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder{
  padding-left:0;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select{
  vertical-align:middle;
  padding:var(--spacing-xs) 6px;
  text-align:left;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select p{
  display:inline-block;
  font-weight:normal;
  padding:0 2px;
  text-overflow:clip;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select ul{
  width:100%;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select .blocks_select_button{
  display:block;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_changeFolder .zimbra-client_contact-picker_select .blocks_select_buttonIcon{
  position:absolute;
  right:5px;
  top:7px;
  font-size:100%;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_content .zimbra-client_contact-picker_noContacts{
  display:block;
  padding:var(--spacing-xl) 0;
  text-align:center;
  font-weight:normal;
  color:var(--gray-light);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_contentContainer{
  display:flex;
  flex:1;
  min-height:0;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_contentContainer .zimbra-client_contact-picker_folderList{
  background-color:var(--gray-lightest);
  width:190px;
  overflow-y:auto;
  padding:var(--spacing-xs);
  border-right:1px solid var(--gray-lighter);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_contentContainer .zimbra-client_contact-picker_contactList{
  position:relative;
  width:280px;
  display:flex;
  flex-direction:column;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_contentContainer .zimbra-client_contact-picker_contactList .zimbra-client_contact-picker_contactListHeader{
  display:flex;
  padding:var(--spacing-md);
  align-items:center;
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_contentContainer .zimbra-client_contact-picker_contactPickerButtons{
  padding:var(--spacing-sm);
  border-left:1px solid var(--gray-lighter);
  border-right:1px solid var(--gray-lighter);
  flex:1;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_contentContainer .zimbra-client_contact-picker_contactPickerButtons button{
  display:block;
  margin:var(--spacing-sm) 0;
  width:100%;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_selectAll{
  position:fixed;
  top:112px;
  width:100%;
}
.zimbra-client_contact-picker_dialog .zimbra-client_contact-picker_footer .zimbra-client_contact-picker_selectionState{
  position:absolute;
  right:14px;
  top:30px;
  font-size:var(--font-size-base);
}
.zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable{
  height:446px;
  width:864px;
}
@media (max-height: 446px){
  .zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable{
    height:100%;
  }
}
.zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable .zimbra-client_contact-picker_content{
  padding:0;
  position:static;
}
.zimbra-client_contact-picker_dialog.zimbra-client_contact-picker_scrollable .zimbra-client_contact-picker_footer{
  border-top:1px solid var(--gray-lighter);
}
