@media (max-width: 480px){
  .zimbra-client_modal-drawer-toolbar_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_modal-drawer-toolbar_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_modal-drawer-toolbar_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_modal-drawer-toolbar_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_modal-drawer-toolbar_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_modal-drawer-toolbar_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_modal-drawer-toolbar_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_modal-drawer-toolbar_container{
  display:flex;
  flex:1;
}
.zimbra-client_modal-drawer-toolbar_leftContainer{
  margin-left:16px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.zimbra-client_modal-drawer-toolbar_rightContainer{
  flex:1;
  justify-content:flex-end;
  align-items:center;
  display:flex;
  margin-right:12px;
}
