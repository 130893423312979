@media (max-width: 480px){
  .zimbra-client_calendar_mini-cal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_calendar_mini-cal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_calendar_mini-cal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_calendar_mini-cal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_calendar_mini-cal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_calendar_mini-cal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_calendar_mini-cal_hideMdUp{
    display:none !important;
  }
}
div.zimbra-client_calendar_mini-cal_minical{
  padding:0;
  margin-bottom:16px;
}
div.zimbra-client_calendar_mini-cal_minical *:focus{
  outline-width:2px;
}
.zimbra-client_calendar_mini-cal_header{
  padding:0 0;
  border-bottom:1px solid var(--sidebar-shaded-color);
}
.zimbra-client_calendar_mini-cal_header .zimbra-client_calendar_mini-cal_prev,
.zimbra-client_calendar_mini-cal_header .zimbra-client_calendar_mini-cal_next{
  float:left;
  z-index:1;
  padding:0 var(--spacing-xs);
}
.zimbra-client_calendar_mini-cal_header .zimbra-client_calendar_mini-cal_picker{
  display:block;
  margin:0;
  padding:0;
  text-align:right;
  white-space:nowrap;
}
.zimbra-client_calendar_mini-cal_header .zimbra-client_calendar_mini-cal_picker > *{
  text-align:left;
}
.zimbra-client_calendar_mini-cal_header .zimbra-client_calendar_mini-cal_picker .zimbra-client_calendar_mini-cal_button{
  padding:0;
  line-height:48px;
  text-transform:uppercase;
  text-align:right;
  font-size:var(--font-size-med);
  max-width:auto;
  font-weight:normal;
  text-overflow:clip;
  max-width:200px;
  font-family:var(--headings-font-family);
  font-weight:var(--headings-font-weight);
  color:var(--text-color);
  cursor:pointer;
}
.zimbra-client_calendar_mini-cal_header .zimbra-client_calendar_mini-cal_picker .zimbra-client_calendar_mini-cal_button p{
  max-width:200px;
  line-height:inherit;
  font-weight:inherit;
  font-family:inherit;
  font-size:inherit;
}
.zimbra-client_calendar_mini-cal_monthView,
.zimbra-client_calendar_mini-cal_yearView,
.zimbra-client_calendar_mini-cal_yearsView{
  text-align:center;
}
.zimbra-client_calendar_mini-cal_yearView,
.zimbra-client_calendar_mini-cal_yearsView{
  display:flex;
  flex-wrap:wrap;
  align-content:space-between;
}
.zimbra-client_calendar_mini-cal_yearsView{
  width:100%;
}
.zimbra-client_calendar_mini-cal_week{
  display:flex;
  flex-wrap:wrap;
  width:100%;
  align-content:space-between;
}
.zimbra-client_calendar_mini-cal_fullCalendarView_week .zimbra-client_calendar_mini-cal_day.zimbra-client_calendar_mini-cal_current:not(.zimbra-client_calendar_mini-cal_today){
  background:none !important;
}
.zimbra-client_calendar_mini-cal_fullCalendarView_week .zimbra-client_calendar_mini-cal_week.zimbra-client_calendar_mini-cal_current{
  background:var(--gray-lighter);
  border-radius:2px;
}
.zimbra-client_calendar_mini-cal_year,
.zimbra-client_calendar_mini-cal_month,
.zimbra-client_calendar_mini-cal_day{
  display:inline-block;
  flex:1 1 auto;
  box-sizing:border-box;
  padding:var(--spacing-xs);
  margin:0;
  border:none;
  border-radius:2px;
  color:var(--text-color);
  white-space:nowrap;
  text-align:center;
  font-size:var(--font-size-base);
  font-weight:300;
  line-height:inherit;
  text-transform:uppercase;
}
.zimbra-client_calendar_mini-cal_year:hover,
.zimbra-client_calendar_mini-cal_month:hover,
.zimbra-client_calendar_mini-cal_day:hover{
  background:var(--gray-lighter) !important;
  color:var(--gray-base);
}
.zimbra-client_calendar_mini-cal_year.zimbra-client_calendar_mini-cal_current,
.zimbra-client_calendar_mini-cal_month.zimbra-client_calendar_mini-cal_current,
.zimbra-client_calendar_mini-cal_day.zimbra-client_calendar_mini-cal_current{
  background:var(--gray-lighter) !important;
}
.zimbra-client_calendar_mini-cal_year.zimbra-client_calendar_mini-cal_today,
.zimbra-client_calendar_mini-cal_month.zimbra-client_calendar_mini-cal_today,
.zimbra-client_calendar_mini-cal_day.zimbra-client_calendar_mini-cal_today{
  background:var(--sidebar-shaded-color) !important;
  color:var(--brand-primary-500) !important;
}
.zimbra-client_calendar_mini-cal_day{
  min-height:24px;
}
.zimbra-client_calendar_mini-cal_fullCalendarView_agenda .zimbra-client_calendar_mini-cal_current:not(.zimbra-client_calendar_mini-cal_today){
  background:none !important;
  color:var(--text-color) !important;
}
.zimbra-client_calendar_mini-cal_month{
  width:calc(100%/4);
}
.zimbra-client_calendar_mini-cal_year{
  width:calc(100%/3);
}
.zimbra-client_calendar_mini-cal_day{
  max-width:calc(100%/7);
}
.zimbra-client_calendar_mini-cal_day.zimbra-client_calendar_mini-cal_outsideOfMonth{
  opacity:0.5;
}
