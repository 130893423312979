@media (max-width: 480px){
  .zimbra-client_form-group_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_form-group_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_form-group_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_form-group_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_form-group_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_form-group_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_form-group_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_form-group_formGroup{
  display:flex;
  align-items:center;
  width:100%;
  margin-bottom:16px;
}
.zimbra-client_form-group_separator{
  padding-bottom:16px;
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_form-group_compact{
  margin-bottom:8px;
}
.zimbra-client_form-group_compact.zimbra-client_form-group_separator{
  padding-bottom:8px;
}
.zimbra-client_form-group_large{
  margin-bottom:24px;
}
.zimbra-client_form-group_large.zimbra-client_form-group_separator{
  padding-bottom:24px;
}
.zimbra-client_form-group_rows{
  flex-direction:column;
  align-items:start;
}
