@media (max-width: 480px){
  .zimbra-client_share-info-card_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_share-info-card_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_share-info-card_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_share-info-card_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_share-info-card_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_share-info-card_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_share-info-card_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_share-info-card_card{
  width:100%;
  margin:8px 0;
}
.zimbra-client_share-info-card_header{
  display:flex;
  justify-content:space-between;
  padding:6px 10px;
  background-color:var(--gray-lightest);
  border:1px solid var(--border-color);
  border-top-left-radius:2px;
  border-top-right-radius:2px;
}
.zimbra-client_share-info-card_header,
.zimbra-client_share-info-card_body{
  border:1px solid var(--border-color);
}
.zimbra-client_share-info-card_urlContainer{
  display:flex;
  justify-content:space-between;
  padding:11px 10px;
}
.zimbra-client_share-info-card_body{
  border-top:0;
  border-bottom-left-radius:2px;
  border-bottom-right-radius:2px;
}
.zimbra-client_share-info-card_url{
  max-width:400px;
  word-break:break-all;
}
.zimbra-client_share-info-card_copyLink{
  margin-left:40px;
  padding-right:12px;
  font-weight:bold;
  white-space:nowrap;
}
