.zimbra-client_msg-list-light_message{
  display:flex;
  width:100%;
  height:var(--row-height-narrow);
  margin:0;
  padding:6px 7px 6px 12px;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  border-bottom:1px solid var(--gray-lighter);
  border-left:var(--spacing-xs) solid transparent;
  transition:height 150ms ease;
  cursor:pointer;
}
.zimbra-client_msg-list-light_message:focus,
.zimbra-client_msg-list-light_message.zimbra-client_msg-list-light_focussed{
  outline:none;
  background-color:var(--list-hover-bg);
}
@media (hover: hover){
  .zimbra-client_msg-list-light_message:hover{
    outline:none;
    background-color:var(--list-hover-bg);
  }
}
.zimbra-client_msg-list-light_message.zimbra-client_msg-list-light_selected{
  border-left:var(--spacing-xs) solid var(--brand-primary-500);
  background-color:var(--list-active-bg);
}
.zimbra-client_msg-list-light_message.zimbra-client_msg-list-light_selected > .zimbra-client_msg-list-light_subject{
  color:var(--gray-base);
}
.zimbra-client_msg-list-light_message.zimbra-client_msg-list-light_selected > .zimbra-client_msg-list-light_excerpt{
  color:var(--gray-light);
}
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_sender,
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_subject,
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_excerpt{
  flex:1;
}
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_sender,
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_subject{
  margin:0;
  white-space:nowrap;
  font-weight:normal;
  text-overflow:ellipsis;
  overflow:hidden;
}
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_sender{
  font-size:var(--font-size-base);
}
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_subject{
  color:var(--text-color);
  font-size:var(--font-size-base);
}
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_excerpt{
  font-weight:normal;
  white-space:nowrap;
  text-overflow:ellipsis;
  color:var(--gray-light);
  overflow:hidden;
  color:var(--placeholder-color);
}
.zimbra-client_msg-list-light_message time{
  flex-shrink:0;
  color:var(--gray-light);
  font-size:var(--font-size-small);
}
.zimbra-client_msg-list-light_message .zimbra-client_msg-list-light_starred{
  color:var(--favorite-indicator-active);
}
.zimbra-client_msg-list-light_message.zimbra-client_msg-list-light_unread .zimbra-client_msg-list-light_sender,
.zimbra-client_msg-list-light_message.zimbra-client_msg-list-light_unread .zimbra-client_msg-list-light_subject{
  font-weight:bold;
  color:var(--gray-base);
}
.zimbra-client_msg-list-light_messageActions{
  height:100%;
  padding-top:6px;
  margin-right:12px;
}
.zimbra-client_msg-list-light_messageContent{
  flex:1;
  overflow:auto;
}
.zimbra-client_msg-list-light_row{
  display:flex;
  align-items:center;
  height:1.4rem;
}
.zimbra-client_msg-list-light_attachment{
  display:inline-block;
  padding-right:var(--spacing-sm);
  font-size:var(--font-size-med);
}
.zimbra-client_msg-list-light_messageTime{
  position:relative;
  padding-left:0 5px;
  float:right;
  text-align:right;
}
.zimbra-client_msg-list-light_narrowListItem{
  display:flex;
  flex:auto;
  flex-wrap:nowrap;
  width:100%;
}
