@media (max-width: 480px){
  .zimbra-client_briefcase_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_briefcase_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_briefcase_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_briefcase_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_briefcase_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_briefcase_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_briefcase_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_briefcase_boxShadow{
  box-shadow:0 2px 4px rgba(0, 0, 0, 0.2);
}
.zimbra-client_briefcase_sidebarListWrapper{
  flex:1;
  position:relative;
}
@media (min-width: 1025px){
  .zimbra-client_briefcase_sidebarListWrapper{
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
    padding:0 var(--spacing-md);
  }
}
.zimbra-client_briefcase_briefcase{
  display:flex;
  background:var(--read-pane-bg-color);
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
}
.zimbra-client_briefcase_briefcase .zimbra-client_briefcase_sidebarHeader{
  background-color:var(--gray-lightest);
  height:48px;
  text-align:center;
}
@media (min-width: 1024px){
  .zimbra-client_briefcase_briefcase .zimbra-client_briefcase_sidebarHeader{
    padding:1px var(--spacing-md) 0;
  }
}
.zimbra-client_briefcase_addToBriefcaseModal{
  width:320px;
}
.zimbra-client_briefcase_addToBriefcaseModal .zimbra-client_briefcase_header{
  font-family:var(--headings-font-family);
  font-size:var(--font-size-large);
  padding:var(--spacing-md) var(--spacing-lg);
  height:60px;
}
.zimbra-client_briefcase_addToBriefcaseModal .zimbra-client_briefcase_header .zimbra-client_briefcase_closeButton{
  position:absolute;
  top:var(--spacing-sm);
  right:var(--spacing-sm);
  width:var(--spacing-lg);
  height:var(--spacing-lg);
  padding:0;
}
.zimbra-client_briefcase_addToBriefcaseModal .zimbra-client_briefcase_content{
  height:300px;
  padding:10px;
  background-color:var(--gray-lightest);
  border-top:1px solid var(--gray-lighter);
  border-bottom:1px solid var(--gray-lighter);
  overflow-y:auto;
}
.zimbra-client_briefcase_addToBriefcaseModal .zimbra-client_briefcase_footer{
  padding:var(--spacing-md) var(--spacing-lg);
  height:60px;
}
.zimbra-client_briefcase_addToBriefcaseModal .zimbra-client_briefcase_footer .zimbra-client_briefcase_button{
  margin:0 var(--spacing-sm) 0 0;
  font-family:var(--font-family-sans-serif);
  font-size:var(--font-size-base);
  font-weight:normal;
}
