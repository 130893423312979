@-webkit-keyframes blocks_animated-button_shake-horizontal{
  0%,
  100%{
    transform:translateX(0);
  }
  10%,
  30%,
  50%,
  70%{
    transform:translateX(-10px);
  }
  20%,
  40%,
  60%{
    transform:translateX(10px);
  }
  80%{
    transform:translateX(8px);
  }
  90%{
    transform:translateX(-8px);
  }
}
@keyframes blocks_animated-button_shake-horizontal{
  0%,
  100%{
    transform:translateX(0);
  }
  10%,
  30%,
  50%,
  70%{
    transform:translateX(-10px);
  }
  20%,
  40%,
  60%{
    transform:translateX(10px);
  }
  80%{
    transform:translateX(8px);
  }
  90%{
    transform:translateX(-8px);
  }
}
.blocks_animated-button_shakeHorizontal{
  -webkit-animation:blocks_animated-button_shake-horizontal 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.25 both;
          animation:blocks_animated-button_shake-horizontal 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.25 both;
}
.blocks_animated-button_btnAnimated{
  position:relative;
}
.blocks_animated-button_btnAnimated .blocks_animated-button_spinner{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  line-height:0;
}
