@media (max-width: 480px){
  .zimbra-client_preview-resize-control_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_preview-resize-control_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_preview-resize-control_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_preview-resize-control_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_preview-resize-control_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_preview-resize-control_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_preview-resize-control_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_preview-resize-control_previewResizeControl{
  position:relative;
  float:left;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_horizontalResizeControl{
  width:100%;
  cursor:row-resize;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_horizontalResizeControl .zimbra-client_preview-resize-control_visibleBar{
  height:1px;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_horizontalResizeControl .zimbra-client_preview-resize-control_hiddenBar{
  width:100%;
  height:8px;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_verticalResizeControl{
  width:0;
  height:100%;
  cursor:col-resize;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_verticalResizeControl .zimbra-client_preview-resize-control_visibleBar{
  width:1px;
  height:100%;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_verticalResizeControl .zimbra-client_preview-resize-control_hiddenBar{
  width:8px;
  height:100%;
}
.zimbra-client_preview-resize-control_previewResizeControl.zimbra-client_preview-resize-control_moving .zimbra-client_preview-resize-control_hiddenBar{
  background-color:var(--gray-lighter);
  opacity:1;
}
.zimbra-client_preview-resize-control_visibleBar{
  background-color:var(--gray-lighter);
}
.zimbra-client_preview-resize-control_hiddenBar{
  position:absolute;
  top:0px;
  z-index:1;
  opacity:0;
  background-color:var(--gray-lighter);
  transition:opacity 200ms ease 200ms;
}
.zimbra-client_preview-resize-control_hiddenBar:hover{
  opacity:0.6;
}
