@media (max-width: 480px){
  .zimbra-client_composer_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_composer_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_composer_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_composer_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_composer_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_composer_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_composer_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_composer_composer{
  display:flex;
  flex-direction:column;
  height:100%;
  position:relative;
}
.zimbra-client_composer_composer .zimbra-client_composer_closeButton{
  position:absolute;
  top:var(--spacing-xs);
  right:calc(var(--spacing-md) + var(--spacing-xs));
  z-index:1;
}
@media (max-width: calc(480px - 1px)){
  .zimbra-client_composer_composer .zimbra-client_composer_tokenInput{
    position:static !important;
    position:initial !important;
  }
}
.zimbra-client_composer_composer .zimbra-client_composer_inner{
  display:flex;
  flex:1 1 auto;
  width:100%;
  position:relative;
}
@media (min-width: 768px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner{
    padding-bottom:calc(var(--toolbar-height) - var(--spacing-sm));
  }
}
@media (min-width: 1025px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner{
    padding-bottom:var(--toolbar-height);
  }
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_left,
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right{
  vertical-align:top;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_left{
  display:flex;
  flex:1;
  flex-direction:column;
  width:100%;
  transition:width 100ms ease-in-out;
  position:relative;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_left.zimbra-client_composer_mediaMenuOpen{
  width:calc(100% - 400px);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right{
  background-color:var(--gray-lightest);
  position:relative;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right:not(.zimbra-client_composer_mediaMenuOpen){
  width:0;
  overflow:hidden;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right > div{
  height:100%;
  padding:var(--spacing-md) 0;
  position:absolute;
  width:100%;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_subject,
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_from{
  display:flex;
  align-items:center;
  padding:var(--spacing-xs) 0;
  width:100%;
  background:var(--body-bg);
  color:var(--gray-base);
  border:none;
  outline:none;
  resize:none;
  line-height:var(--line-height-base);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_from{
  padding-top:10px;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_subject{
  box-shadow:inset 0 0 0 999px #FFF;
  text-overflow:ellipsis;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_fromValueWrapper{
  flex:1;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_fromValueWrapper p{
  font-weight:normal;
  text-transform:none;
  text-overflow:unset;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_fromValue{
  margin-left:var(--spacing-md);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue{
  margin-left:var(--spacing-md);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromOption{
  text-align:left;
  text-transform:none;
  background:#FFF;
  color:var(--gray-base);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromOption:hover,
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromOption:focus{
  background:var(--gray-lighter);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromOption > div[class*="itemTitle"]{
  float:left;
  padding-left:0;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromOptionItem{
  display:flex;
  font-weight:normal;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromToggleButtonClass{
  width:100%;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_selectFromValue .zimbra-client_composer_selectFromToggleButtonClass:focus{
  color:inherit;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_smimeOperationBtn{
  height:auto;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_senderOptionBtn{
  height:auto;
  padding:0;
  width:28px;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_header{
  border-bottom:1px solid var(--border-color);
  padding:0 var(--spacing-lg);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_header.zimbra-client_composer_focused{
  border-bottom-color:var(--brand-primary-500);
}
@media (max-width: 1024px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_header{
    padding:var(--spacing-xs) var(--spacing-md) 0;
  }
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_scrollableArea{
  display:flex;
  flex-direction:column;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  top:0;
  overflow:auto;
  -webkit-overflow-scrolling:touch;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_scrollableArea .zimbra-client_composer_item{
  display:block;
  border-bottom:1px solid var(--border-color);
  position:relative;
  min-height:38px;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_scrollableArea .zimbra-client_composer_item.zimbra-client_composer_focused{
  border-bottom-color:var(--brand-primary-500);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_scrollableArea .zimbra-client_composer_item .zimbra-client_composer_addressField{
  padding:var(--spacing-sm) 0;
  white-space:nowrap;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_scrollableArea .zimbra-client_composer_item:last-child{
  border-bottom:0;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar{
  background:var(--body-bg);
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  z-index:1;
  align-items:center;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_progressBar{
  position:absolute;
  left:0;
  bottom:100%;
  right:0;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_actionMenuAttachmentsButton{
  padding:0;
  margin:0 var(--spacing-xs) 0 var(--spacing-md);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_actionMenuAttachmentsButton,
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_trash{
  color:var(--gray);
  height:var(--spacing-xl);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_actionMenuAttachmentsButton:hover,
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_trash:hover{
  color:var(--gray-base);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_toolbarLeft{
  display:flex;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_sendButton{
  margin:0;
  vertical-align:top;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_sendButtonWithClockIcon{
  margin:0;
  vertical-align:top;
  border-radius:3px 0 0 3px;
}
.zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_composerToolbar .zimbra-client_composer_clockButton{
  margin:0 0 0 1px;
  border-radius:0 3px 3px 0;
  background-color:var(--brand-primary-500);
  color:var(--text-color-light);
  padding:var(--spacing-sm);
  border:none;
  height:var(--spacing-xl);
}
@media (min-width: 1025px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner:not(.zimbra-client_composer_inlineComposer) .zimbra-client_composer_scrollableArea{
    top:var(--spacing-lg);
  }
  .zimbra-client_composer_composer .zimbra-client_composer_inner:not(.zimbra-client_composer_inlineComposer) .zimbra-client_composer_composerToolbar{
    border-top:1px solid var(--gray-lighter);
    min-height:var(--toolbar-height);
  }
}
@media (min-width: 768px) and (max-width: 1024px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner:not(.zimbra-client_composer_inlineComposer){
    padding-bottom:calc(var(--toolbar-height) - var(--spacing-md));
  }
  .zimbra-client_composer_composer .zimbra-client_composer_inner:not(.zimbra-client_composer_inlineComposer) .zimbra-client_composer_composerToolbar .tox-tinymce-inline{
    border-top:1px solid var(--gray-lighter);
  }
}
.zimbra-client_composer_composer .zimbra-client_composer_inner.zimbra-client_composer_inlineComposer{
  height:500px;
  padding-bottom:var(--toolbar-height);
}
.zimbra-client_composer_composer .zimbra-client_composer_inner.zimbra-client_composer_inlineComposer .zimbra-client_composer_composerToolbar{
  min-height:var(--toolbar-height);
  border-top:1px solid var(--gray-lighter);
}
.zimbra-client_composer_composer .zimbra-client_composer_footer{
  padding:10px;
}
.zimbra-client_composer_composer .zimbra-client_composer_toggleCc{
  color:var(--text-color);
  margin:0;
  position:absolute;
  right:0;
  bottom:13px;
  font-size:var(--font-size-small);
}
.zimbra-client_composer_composer .zimbra-client_composer_toggleCc:hover,
.zimbra-client_composer_composer .zimbra-client_composer_toggleCc:focus{
  color:var(--brand-tertiary-500);
}
.zimbra-client_composer_composer .zimbra-client_composer_subjectInput{
  padding-top:6px;
}
.zimbra-client_composer_composer .zimbra-client_composer_bodyWrapper{
  height:100%;
}
.zimbra-client_composer_composer .zimbra-client_composer_timeStamp{
  display:flex;
  align-items:center;
}
.zimbra-client_composer_composer .zimbra-client_composer_savedAt{
  margin-right:0;
}
.zimbra-client_composer_composer .zimbra-client_composer_subjectField{
  display:flex;
}
.zimbra-client_composer_composer .dropdown-menu{
  background:#fff;
  border-radius:3px;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.4);
  display:none;
  left:0;
  list-style:none;
  margin:0;
  overflow:hidden;
  padding:10px 0;
  position:absolute;
  top:100%;
  z-index:1;
}
.zimbra-client_composer_composer .dropdown-menu-section{
  list-style:none;
  margin:0;
  padding:0;
}
.zimbra-client_composer_composer .dropdown-menu li{
  overflow:hidden;
  padding:0;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.zimbra-client_composer_composer .dropdown-menu button{
  background:none;
  border:0;
  color:var(--gray-base);
  cursor:pointer;
  display:block;
  font-size:var(--font-size-base);
  height:25px;
  line-height:25px;
  width:160px;
  padding:0 12px 0 25px;
  position:relative;
  text-align:left;
}
.zimbra-client_composer_composer .dropdown-menu li:hover{
  background:var(--brand-primary-50);
}
.zimbra-client_composer_composer .dropdown-menu-section + .dropdown-menu-section{
  border-top:1px solid #eee;
  padding-top:10px;
  margin-top:10px;
}
.zimbra-client_composer_composer .dropdown-menu span{
  height:0;
  position:absolute;
  left:8px;
  top:0;
  font-size:var(--font-size-small);
}
.zimbra-client_composer_composer .footerLinks [embedded-card]{
  margin-right:5px;
}
@media (min-width: 1025px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right{
    transition:all 400ms linear;
  }
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right.zimbra-client_composer_mediaMenuOpen{
    width:400px;
  }
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right.zimbra-client_composer_rightPane{
    width:0;
  }
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right.zimbra-client_composer_rightPane.zimbra-client_composer_mediaMenuOpen{
    width:400px;
  }
}
@media (max-width: 1024px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right.zimbra-client_composer_mediaMenuOpen{
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    width:374px;
    box-shadow:-2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right.zimbra-client_composer_mediaMenuOpen.zimbra-client_composer_rightPane{
    bottom:var(--toolbar-height);
  }
}
@media (max-width: 1024px) and (max-width: 480px){
  .zimbra-client_composer_composer .zimbra-client_composer_inner .zimbra-client_composer_right.zimbra-client_composer_mediaMenuOpen{
    left:0;
    width:auto;
    box-shadow:none;
  }
}
.zimbra-client_composer_selectedOperation{
  padding-right:5px;
}
span.zimbra-client_composer_shieldIcon{
  padding-right:5px;
  vertical-align:text-bottom;
}
#toggle_container{
  position:relative;
}
#toggle_container #__zm_composer_toggle__{
  height:var(--icon-size-md);
  width:var(--icon-size-md);
  opacity:0;
  position:absolute;
  left:0;
  top:-2px;
  z-index:1;
  cursor:pointer;
}
#toggle_container #__zm_composer_toggle__[displayOLKSrcBody] ~ #OLK_SRC_BODY_SECTION{
  display:block;
}
#toggle_container #__zm_composer_toggle__:not([displayOLKSrcBody]) ~ #OLK_SRC_BODY_SECTION{
  display:none;
}
#toggle_container #__zm_composer_toggle__ + i:before{
  position:relative;
  padding:0 var(--spacing-xs);
  top:-1px;
  background:var(--gray-lightest);
  border:1px solid var(--gray-placeholder);
  border-radius:3px;
  font-size:var(--font-size-base);
  color:var(--gray-placeholder);
  width:auto;
  height:18px;
  content:'•••';
}
#toggle_container #__zm_composer_toggle__[displayOLKSrcBody] + i:before{
  background:var(--gray-placeholder);
  color:var(--gray-lightest);
}
