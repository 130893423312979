@media (max-width: 480px){
  .zimbra-client_contact-suggestion_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_contact-suggestion_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_contact-suggestion_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_contact-suggestion_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-suggestion_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-suggestion_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_contact-suggestion_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_contact-suggestion_suggestion{
  display:flex;
  align-items:center;
  position:relative;
  overflow:hidden;
  border:2px solid #FFF;
  border-width:1px 2px;
  white-space:nowrap;
  text-overflow:ellipsis;
  color:var(--gray);
  cursor:pointer;
}
.zimbra-client_contact-suggestion_suggestion .zimbra-client_contact-suggestion_avatar{
  margin-right:8px;
}
.zimbra-client_contact-suggestion_suggestion .zimbra-client_contact-suggestion_name,
.zimbra-client_contact-suggestion_suggestion .zimbra-client_contact-suggestion_email{
  padding-right:8px;
}
.zimbra-client_contact-suggestion_suggestion .zimbra-client_contact-suggestion_avatar{
  width:36px;
  height:36px;
  flex-shrink:0;
}
.zimbra-client_contact-suggestion_suggestion .zimbra-client_contact-suggestion_name{
  font-weight:bold;
}
.zimbra-client_contact-suggestion_suggestion .zimbra-client_contact-suggestion_email{
  color:var(--gray-light);
  flex:1;
  overflow-x:hidden;
  text-overflow:ellipsis;
}
.zimbra-client_contact-suggestion_suggestion strong{
  font-weight:normal;
  color:var(--gray-base);
}
.zimbra-client_contact-suggestion_suggestion:first-child{
  border-top-width:2px;
}
.zimbra-client_contact-suggestion_suggestion:last-child{
  border-bottom-width:2px;
}
.zimbra-client_contact-suggestion_suggestion.zimbra-client_contact-suggestion_selected{
  background:var(--gray-lightest);
}
.zimbra-client_contact-suggestion_suggestion.zimbra-client_contact-suggestion_active{
  background:var(--gray-lighter);
}
.zimbra-client_contact-suggestion_closeContainer{
  display:none;
  flex:1;
  text-align:right;
  padding-right:4px;
}
.zimbra-client_contact-suggestion_closePlaceholder{
  width:22px;
  height:18px;
}
.zimbra-client_contact-suggestion_close{
  position:relative;
  color:var(--gray-darker);
  width:18px;
  height:18px;
}
.zimbra-client_contact-suggestion_close span{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
.zimbra-client_contact-suggestion_previouslySelectedLabel{
  font-size:var(--font-size-small);
}
