@media (max-width: 480px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_accounts-settings_app-specific-passwords_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_row{
  display:flex;
  padding:var(--spacing-sm);
  align-items:center;
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_header .zimbra-client_settings_accounts-settings_app-specific-passwords_col .zimbra-client_settings_accounts-settings_app-specific-passwords_removeAppBtn,
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_body .zimbra-client_settings_accounts-settings_app-specific-passwords_col .zimbra-client_settings_accounts-settings_app-specific-passwords_removeAppBtn{
  visibility:hidden;
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_header .zimbra-client_settings_accounts-settings_app-specific-passwords_col:first-child,
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_body .zimbra-client_settings_accounts-settings_app-specific-passwords_col:first-child{
  flex:1;
  word-break:break-all;
  padding-right:var(--spacing-xs);
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_header .zimbra-client_settings_accounts-settings_app-specific-passwords_col:last-child,
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_body .zimbra-client_settings_accounts-settings_app-specific-passwords_col:last-child{
  width:100px;
  display:flex;
  align-items:center;
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_header .zimbra-client_settings_accounts-settings_app-specific-passwords_col:last-child > span,
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_body .zimbra-client_settings_accounts-settings_app-specific-passwords_col:last-child > span{
  flex:1;
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_header{
  font-weight:600;
  background-color:var(--gray-lighter);
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_body .zimbra-client_settings_accounts-settings_app-specific-passwords_row:hover{
  background-color:#f8f8f8;
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_appPasswordsList .zimbra-client_settings_accounts-settings_app-specific-passwords_body .zimbra-client_settings_accounts-settings_app-specific-passwords_row:hover .zimbra-client_settings_accounts-settings_app-specific-passwords_removeAppBtn{
  visibility:visible;
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_addAppModal .zimbra-client_settings_accounts-settings_app-specific-passwords_appModalContent{
  padding-bottom:var(--spacing-lg);
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_addAppModal .zimbra-client_settings_accounts-settings_app-specific-passwords_appModalContent .zimbra-client_settings_accounts-settings_app-specific-passwords_appName{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_settings_accounts-settings_app-specific-passwords_addAppModal .zimbra-client_settings_accounts-settings_app-specific-passwords_appModalContent .zimbra-client_settings_accounts-settings_app-specific-passwords_btn{
  margin:0;
}
