.zimbra-client_dumpster_msg-list_smartMessageList{
  height:100%;
}
.zimbra-client_dumpster_msg-list_scrollableInner{
  overflow:auto;
}
.zimbra-client_dumpster_msg-list_leftContainer{
  display:flex;
  align-items:center;
}
.zimbra-client_dumpster_msg-list_msgListHeader .zimbra-client_dumpster_msg-list_row{
  min-height:var(--toolbar-height);
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_dumpster_msg-list_msgListHeader .zimbra-client_dumpster_msg-list_sort{
  min-height:var(--toolbar-height);
  padding-left:var(--spacing-md);
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-bottom:1px solid var(--gray-lighter);
}
