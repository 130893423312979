@media (max-width: 480px){
  .zimbra-client_action-menu_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_action-menu_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_action-menu_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_action-menu_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_action-menu_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_action-menu_dropdown{
  min-width:250px;
}
.zimbra-client_action-menu_label{
  font-size:var(--font-size-base);
}
.zimbra-client_action-menu_smallLabel{
  font-size:var(--font-size-small);
}
.zimbra-client_action-menu_toggle:disabled{
  opacity:1;
}
