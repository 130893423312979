@media (max-width: 480px){
  .zimbra-client_contact-hover-card_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_contact-hover-card_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_contact-hover-card_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_contact-hover-card_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-hover-card_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_contact-hover-card_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_contact-hover-card_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_contact-hover-card_contactCard{
  padding:0;
  max-width:440px !important;
  background:#FFF;
  box-shadow:0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius:4px;
  display:flex;
  flex-wrap:wrap;
  overflow:visible;
  font-size:var(--font-size-base);
  font-weight:normal;
  color:var(--gray-base);
  cursor:default;
  -webkit-animation:zimbra-client_contact-hover-card_card-in 200ms cubic-bezier(0.2, 1.5, 0.6, 1) forwards 1;
          animation:zimbra-client_contact-hover-card_card-in 200ms cubic-bezier(0.2, 1.5, 0.6, 1) forwards 1;
  transform-origin:40px -5px;
}
@-webkit-keyframes zimbra-client_contact-hover-card_card-in{
  from{
    opacity:0;
    transform:scale(0.1);
  }
}
@keyframes zimbra-client_contact-hover-card_card-in{
  from{
    opacity:0;
    transform:scale(0.1);
  }
}
.zimbra-client_contact-hover-card_contactCard.zimbra-client_contact-hover-card_loading > *{
  opacity:0.8;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details{
  position:relative;
  padding:var(--spacing-md);
  min-height:calc(168px - 35);
  flex:0 1 50%;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details *{
  margin:0;
  padding:0;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details h3{
  font-size:var(--font-size-med);
  font-weight:700;
  text-transform:capitalize;
  color:inherit;
  overflow:hidden;
  word-break:break-word;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details h4{
  font-size:100%;
  font-weight:normal;
  color:var(--gray-light);
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details dl{
  position:relative;
  padding:var(--spacing-xs) 0;
  clear:both;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details dl dt{
  float:left;
  font-size:var(--font-size-med);
  width:var(--icon-size-md);
  margin-right:var(--spacing-xs);
  text-align:center;
  color:var(--gray-light);
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details dl dd{
  min-height:18px;
  padding:5px 0 0;
  color:inherit;
  overflow:hidden;
  text-overflow:ellipsis;
  word-break:break-word;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details dl dd a,
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details dl dd a:hover,
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_details dl dd a[href]{
  color:var(--gray-base);
  font-weight:inherit;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_avatarWrapper{
  display:flex;
  flex:1 1 50%;
  overflow:hidden;
  justify-content:flex-end;
  align-items:center;
  border-radius:0 4px 0 0;
  position:relative;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_avatarWrapper .zimbra-client_contact-hover-card_avatar{
  height:150px;
  width:150px;
  font-size:50px;
  border-radius:50%;
  transform:scale(1.6);
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_avatarWrapper .zimbra-client_contact-hover-card_spinner{
  position:absolute;
  left:50%;
  transform:translateX(-50%);
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer{
  position:relative;
  padding:0 var(--spacing-md);
  background:var(--background-gray);
  border-radius:0 0 4px 4px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex:1 1 100%;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer .zimbra-client_contact-hover-card_addEditLink{
  flex:1;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer .zimbra-client_contact-hover-card_addEditLink button{
  margin-left:0;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer [role='button']{
  padding:0;
  margin:0;
  width:var(--spacing-xl);
  text-align:center;
  height:var(--spacing-xl);
  line-height:30px;
  border:0;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer [role='button']:hover,
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer [role='button']:focus{
  background:none;
  color:var(--brand-primary-500);
  text-decoration:none;
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer .zimbra-client_contact-hover-card_sMimePubCertVerified{
  color:var(--brand-success-500);
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer .zimbra-client_contact-hover-card_sMimePubCertExpired{
  color:var(--brand-danger-500);
}
.zimbra-client_contact-hover-card_contactCard .zimbra-client_contact-hover-card_footer .zimbra-client_contact-hover-card_smimePubCertShieldIcon{
  transform:translate(-2px, -2px);
  display:inline-block;
  margin-left:var(--spacing-xs);
}
