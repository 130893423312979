@media (max-width: 480px){
  .zimbra-client_availability-indicator_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_availability-indicator_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_availability-indicator_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_availability-indicator_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_availability-indicator_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_availability-indicator_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_availability-indicator_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_availability-indicator_availabilityIndicator{
  background-color:var(--gray-lightest);
  padding:19px 21px 25px;
  width:100%;
}
.zimbra-client_availability-indicator_wrapRowBody{
  position:relative;
}
.zimbra-client_availability-indicator_renderWorkingHours,
.zimbra-client_availability-indicator_renderFreeBusy{
  position:absolute;
}
.zimbra-client_availability-indicator_renderWorkingHours{
  z-index:1;
}
.zimbra-client_availability-indicator_renderWorkingHours .zimbra-client_availability-indicator_unavailableIndicator{
  background-color:var(--gray-lightest);
}
.zimbra-client_availability-indicator_renderFreeBusy{
  z-index:2;
}
.zimbra-client_availability-indicator_renderFreeBusy .zimbra-client_availability-indicator_freeIndicator{
  background-color:transparent;
}
.zimbra-client_availability-indicator_allInviteesStatus .zimbra-client_availability-indicator_freeIndicator{
  background-color:var(--body-bg);
}
.zimbra-client_availability-indicator_angleIcon{
  margin-right:18px;
}
.zimbra-client_availability-indicator_refreshIcon{
  margin-right:13px;
  font-size:var(--font-size-med);
}
.zimbra-client_availability-indicator_icon{
  cursor:pointer;
}
.zimbra-client_availability-indicator_header{
  margin-bottom:8px;
}
.zimbra-client_availability-indicator_headerControls{
  display:flex;
  align-items:center;
  margin-bottom:9px;
}
.zimbra-client_availability-indicator_title{
  flex:1;
  font-size:var(--font-size-large);
}
.zimbra-client_availability-indicator_tableBodyWrapper{
  width:940px;
}
@media (max-width: 1549px){
  .zimbra-client_availability-indicator_tableBodyWrapper{
    flex:1;
    width:auto;
  }
}
.zimbra-client_availability-indicator_tableBody{
  overflow-x:auto;
  position:relative;
}
.zimbra-client_availability-indicator_legend{
  margin:var(--spacing-sm) 0;
}
.zimbra-client_availability-indicator_legend .zimbra-client_availability-indicator_legendIndicator{
  display:inline-flex;
  margin-right:var(--spacing-md);
}
.zimbra-client_availability-indicator_legend .zimbra-client_availability-indicator_legendIndicator .zimbra-client_availability-indicator_nonworkingIndicator{
  background-color:var(--gray-lightest);
}
.zimbra-client_availability-indicator_legend .zimbra-client_availability-indicator_displayPattern{
  width:12px;
  height:12px;
  margin-right:var(--spacing-xs);
  margin-top:3px;
}
.zimbra-client_availability-indicator_tableGridlineLabelSpacer{
  border-bottom:solid 1px #b5b5b5;
}
.zimbra-client_availability-indicator_tableGridlines{
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  top:0;
  z-index:3;
}
.zimbra-client_availability-indicator_tableGridline{
  pointer-events:none;
  position:absolute;
  width:1px;
  bottom:0;
  background-color:var(--gray-lighter);
}
.zimbra-client_availability-indicator_tableGridlineLabels{
  position:relative;
  border-bottom:solid 1px #b5b5b5;
}
.zimbra-client_availability-indicator_tableGridlineLabels div{
  display:inline-block;
  text-align:center;
  font-size:10px;
  color:var(--gray);
}
.zimbra-client_availability-indicator_tableRowBody{
  border-bottom:solid 1px var(--gray-lighter);
  height:31px;
  position:relative;
}
.zimbra-client_availability-indicator_tableRowBody div{
  position:absolute;
  top:0;
  bottom:0;
}
.zimbra-client_availability-indicator_busyIndicator{
  background-color:#7fc3e0;
}
.zimbra-client_availability-indicator_freeIndicator{
  background-color:var(--body-bg);
}
.zimbra-client_availability-indicator_unavailableIndicator{
  background-color:var(--gray-lighter);
}
.zimbra-client_availability-indicator_tentativeIndicator{
  background:repeating-linear-gradient(-45deg, rgba(0, 136, 193, 0.5), rgba(0, 136, 193, 0.5) 4px, var(--body-bg) 4px, var(--body-bg) 8px);
}
.zimbra-client_availability-indicator_nodataIndicator{
  background:repeating-linear-gradient(-45deg, var(--gray-lighter), var(--gray-lighter) 4px, white 4px, white 8px);
}
.zimbra-client_availability-indicator_tableRowHeader{
  height:31px;
  border-right:solid 1px #b5b5b5;
  border-bottom:solid 1px var(--gray-lighter);
  display:flex;
  align-items:center;
  flex-shrink:0;
}
@media (max-width: 1549px){
  .zimbra-client_availability-indicator_tableRowHeader{
    width:162px;
  }
}
.zimbra-client_availability-indicator_contextMenu{
  width:100%;
  display:flex;
  align-items:center;
}
.zimbra-client_availability-indicator_contextMenuContainer{
  z-index:1001;
}
.zimbra-client_availability-indicator_requiredToggle{
  position:relative;
  flex:1;
}
.zimbra-client_availability-indicator_selectContainer{
  opacity:0;
}
.zimbra-client_availability-indicator_downAngleIcon{
  margin-left:4px;
  margin-right:8px;
}
.zimbra-client_availability-indicator_selectLabel{
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  left:0;
  display:flex;
  align-items:center;
}
.zimbra-client_availability-indicator_availabilityTable{
  display:flex;
  flex-direction:row;
}
@media (min-width: 1550px){
  .zimbra-client_availability-indicator_availabilityTable .zimbra-client_availability-indicator_tableHeader{
    flex:1;
  }
}
.zimbra-client_availability-indicator_eventOutline{
  pointer-events:none;
  position:absolute;
  border:solid 2px #a4a4a4;
  border-radius:2px;
  bottom:0;
  z-index:4;
}
.zimbra-client_availability-indicator_displayName{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.zimbra-client_availability-indicator_availabilityTableLoading{
  background:var(--gray-lightest);
  text-align:center;
  position:relative;
  width:auto;
}
@media (max-width: 1549px){
  .zimbra-client_availability-indicator_availabilityTableLoading{
    flex:1;
    width:auto;
  }
}
@media (max-width: 480px){
  .zimbra-client_availability-indicator_availabilityTableLoading{
    width:80%;
  }
}
.zimbra-client_availability-indicator_availabilityTableLoading .zimbra-client_availability-indicator_spinner{
  font-size:var(--font-size-h3);
  margin-bottom:15px;
  border-top-color:var(--gray-light);
}
.zimbra-client_availability-indicator_availabilityTableLoading .zimbra-client_availability-indicator_text{
  color:var(--gray-light);
}
