@media (max-width: 480px){
  .zimbra-client_tags_create-tags-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_tags_create-tags-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_tags_create-tags-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_tags_create-tags-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_tags_create-tags-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_tags_create-tags-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_tags_create-tags-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_tags_create-tags-modal_createTagModalContent{
  margin-top:14px;
}
@media (max-width: 1024px){
  .zimbra-client_tags_create-tags-modal_contentWrapper{
    padding:30px 20px;
  }
  header + .zimbra-client_tags_create-tags-modal_contentWrapper{
    padding-top:10px;
  }
}
.zimbra-client_tags_create-tags-modal_createTagModal .zimbra-client_tags_create-tags-modal_colorPicker{
  margin-left:16px;
}
.zimbra-client_tags_create-tags-modal_createTagModal .zimbra-client_tags_create-tags-modal_colorPickerContainer{
  display:flex;
  margin-top:24px;
}
@media (max-width: 1024px){
  .zimbra-client_tags_create-tags-modal_createTagModal .zimbra-client_tags_create-tags-modal_colorPickerContainer{
    margin-top:18px;
    align-items:top;
  }
  .zimbra-client_tags_create-tags-modal_createTagModal .zimbra-client_tags_create-tags-modal_colorPickerContainer .zimbra-client_tags_create-tags-modal_colorLabel{
    padding-top:10px;
  }
}
