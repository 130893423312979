@font-face{
  font-family:"zimbra-icons";
  src:url(/fonts/zimbra-icons_VTSIp.eot);
  src:url(/fonts/zimbra-icons_VTSIp.eot#iefix) format("embedded-opentype"), url(/fonts/zimbra-icons_1yB-i.ttf) format("truetype"), url(/fonts/zimbra-icons_1OhgE.woff) format("woff"), url(/assets/zimbra-icons_3buQU.svg#zimbra-icons) format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:auto;
}
.zimbra-icon{
  font-family:"zimbra-icons" !important;
  speak:none;
  font-style:normal;
  font-weight:normal;
  font-feature-settings:normal;
  font-variant:normal;
  text-transform:none;
  line-height:1;
  vertical-align:middle;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
.zimbra-icon:before{
  content:"      �";
}
.zimbra-icon-add-event:before{
  content:"\e900";
}
.zimbra-icon-folder-add:before{
  content:"\e901";
}
.zimbra-icon-add-note:before{
  content:"\e902";
}
.zimbra-icon-plus:before{
  content:"\e903";
}
.zimbra-icon-archive:before{
  content:"\e904";
}
.zimbra-icon-caret-down:before{
  content:"\e905";
}
.zimbra-icon-user-o:before{
  content:"\e906";
}
.zimbra-icon-user:before{
  content:"\e907";
}
.zimbra-icon-bold:before{
  content:"\e908";
}
.zimbra-icon-list-ul:before{
  content:"\e909";
}
.zimbra-icon-calendar-alt-o:before{
  content:"\e90a";
}
.zimbra-icon-calendar-o:before{
  content:"\e90b";
}
.zimbra-icon-align-center:before{
  content:"\e90c";
}
.zimbra-icon-chat:before{
  content:"\e90d";
}
.zimbra-icon-check-square:before{
  content:"\e90e";
}
.zimbra-icon-square-o:before{
  content:"\e90f";
}
.zimbra-icon-check:before{
  content:"\e910";
}
.zimbra-icon-close-circle:before{
  content:"\e911";
}
.zimbra-icon-close:before{
  content:"\e912";
}
.zimbra-icon-collapse-items:before{
  content:"\e913";
}
.zimbra-icon-angle-double-left:before{
  content:"\e914";
}
.zimbra-icon-pencil:before{
  content:"\e915";
}
.zimbra-icon-address-book:before{
  content:"\e916";
}
.zimbra-icon-arrows-alt-inverse:before{
  content:"\e917";
}
.zimbra-icon-file-word-o:before{
  content:"\e918";
}
.zimbra-icon-chevron-right:before{
  content:"\e919";
}
.zimbra-icon-download:before{
  content:"\e91a";
}
.zimbra-icon-angle-right:before{
  content:"\e91b";
}
.zimbra-icon-smile-o:before{
  content:"\e91c";
}
.zimbra-icon-expand-items:before{
  content:"\e91d";
}
.zimbra-icon-angle-double-right:before{
  content:"\e91e";
}
.zimbra-icon-facebook-official:before{
  content:"\e91f";
}
.zimbra-icon-mail-forward:before{
  content:"\e920";
}
.zimbra-icon-expand:before{
  content:"\e921";
}
.zimbra-icon-GIF:before{
  content:"\e922";
}
.zimbra-icon-grid:before{
  content:"\e923";
}
.zimbra-icon-question-circle:before{
  content:"\e924";
}
.zimbra-icon-home:before{
  content:"\e925";
}
.zimbra-icon-image:before{
  content:"\e926";
}
.zimbra-icon-arrows-alt:before{
  content:"\e927";
}
.zimbra-icon-indent:before{
  content:"\e928";
}
.zimbra-icon-italic:before{
  content:"\e929";
}
.zimbra-icon-align-left:before{
  content:"\e92a";
}
.zimbra-icon-link:before{
  content:"\e92b";
}
.zimbra-icon-mobile-phone:before{
  content:"\e92c";
}
.zimbra-icon-ellipsis-h:before{
  content:"\e92d";
}
.zimbra-icon-folder-move:before{
  content:"\e92e";
}
.zimbra-icon-angle-left:before{
  content:"\e92f";
}
.zimbra-icon-angle-down:before{
  content:"\e930";
}
.zimbra-icon-angle-up:before{
  content:"\e991";
}
.zimbra-icon-book:before{
  content:"\e931";
}
.zimbra-icon-list-ol:before{
  content:"\e932";
}
.zimbra-icon-external-link:before{
  content:"\e933";
}
.zimbra-icon-outdent:before{
  content:"\e934";
}
.zimbra-icon-file-pdf-o:before{
  content:"\e935";
}
.zimbra-icon-multimedia-active:before{
  content:"\e936";
}
.zimbra-icon-multimedia:before{
  content:"\e937";
}
.zimbra-icon-file-powerpoint-o:before{
  content:"\e938";
}
.zimbra-icon-print:before{
  content:"\e939";
}
.zimbra-icon-radio:before{
  content:"\e93a";
}
.zimbra-icon-radio-active:before{
  content:"\e93b";
}
.zimbra-icon-mail-reply-all:before{
  content:"\e93c";
}
.zimbra-icon-mail-reply:before{
  content:"\e93d";
}
.zimbra-icon-align-right:before{
  content:"\e93e";
}
.zimbra-icon-search:before{
  content:"\e93f";
}
.zimbra-icon-cog:before{
  content:"\e940";
}
.zimbra-icon-music:before{
  content:"\e941";
}
.zimbra-icon-shield:before{
  content:"\e942";
}
.zimbra-icon-star:before{
  content:"\e943";
}
.zimbra-icon-alarm:before{
  content:"\e944";
}
.zimbra-icon-arrow-left:before{
  content:"\e945";
}
.zimbra-icon-address-book-sync:before{
  content:"\e946";
}
.zimbra-icon-adn:before{
  content:"\e947";
}
.zimbra-icon-font:before{
  content:"\e948";
}
.zimbra-icon-trash:before{
  content:"\e949";
}
.zimbra-icon-twitter:before{
  content:"\e94a";
}
.zimbra-icon-underline:before{
  content:"\e94b";
}
.zimbra-icon-arrow-down:before{
  content:"\e94c";
}
.zimbra-icon-users:before{
  content:"\e94d";
}
.zimbra-icon-play-circle-o:before{
  content:"\e94e";
}
.zimbra-icon-file-excel-o:before{
  content:"\e94f";
}
.zimbra-icon-file-archive-o:before{
  content:"\e950";
}
.zimbra-icon-clock:before{
  content:"\e951";
}
.zimbra-icon-check-circle:before{
  content:"\e952";
}
.zimbra-icon-minus:before{
  content:"\e953";
}
.zimbra-icon-camera:before{
  content:"\e954";
}
.zimbra-icon-minus-square:before{
  content:"\e955";
}
.zimbra-icon-paperclip:before{
  content:"\e956";
}
.zimbra-icon-arrow-up:before{
  content:"\e957";
}
.zimbra-icon-bell:before{
  content:"\e958";
}
.zimbra-icon-calendar-range:before{
  content:"\e959";
}
.zimbra-icon-trash-forever:before{
  content:"\e95a";
}
.zimbra-icon-user-circle-o:before{
  content:"\e95b";
}
.zimbra-icon-add-contact:before{
  content:"\e95c";
}
.zimbra-icon-envelope:before{
  content:"\e95d";
}
.zimbra-icon-add-circle:before{
  content:"\e95e";
}
.zimbra-icon-text-options:before{
  content:"\e95f";
}
.zimbra-icon-assign-list:before{
  content:"\e960";
}
.zimbra-icon-bars:before{
  content:"\e961";
}
.zimbra-icon-restore:before{
  content:"\e962";
}
.zimbra-icon-remove-list:before{
  content:"\e963";
}
.zimbra-icon-lock:before{
  content:"\e964";
}
.zimbra-icon-repeat:before{
  content:"\e965";
}
.zimbra-icon-refresh:before{
  content:"\e966";
}
.zimbra-icon-chevron-left:before{
  content:"\e967";
}
.zimbra-icon-bell-slash:before{
  content:"\e968";
}
.zimbra-icon-warning:before{
  content:"\e969";
}
.zimbra-icon-encrypted:before{
  content:"\e96a";
}
.zimbra-icon-search-plus:before{
  content:"\e96b";
}
.zimbra-icon-search-minus:before{
  content:"\e96c";
}
.zimbra-icon-rotate_right:before{
  content:"\e96d";
}
.zimbra-icon-rotate_left:before{
  content:"\e96e";
}
.zimbra-icon-not-signed:before{
  content:"\e96f";
}
.zimbra-icon-signed:before{
  content:"\e970";
}
.zimbra-icon-cloud:before{
  content:"\e971";
}
.zimbra-icon-videocam:before{
  content:"\e972";
}
.zimbra-icon-location:before{
  content:"\e973";
}
.zimbra-icon-verified:before{
  content:"\e974";
}
.zimbra-icon-not-verified:before{
  content:"\e975";
}
.zimbra-icon-outline-user-circle-o:before{
  content:"\e976";
}
.zimbra-icon-web:before{
  content:"\e977";
}
.zimbra-icon-code:before{
  content:"\e978";
}
.zimbra-icon-check-square-o:before{
  content:"\e979";
}
.zimbra-icon-about:before{
  content:"\e97a";
}
.zimbra-icon-language:before{
  content:"\e97b";
}
.zimbra-icon-copy-to-clipboard:before{
  content:"\e97c";
}
.zimbra-icon-priority-high:before{
  content:"\e97d";
}
.zimbra-icon-priority-low:before{
  content:"\e97e";
}
.zimbra-icon-send-feedback:before{
  content:"\e97f";
}
.zimbra-icon-drag:before{
  content:"\e980";
}
.zimbra-icon-label:before{
  content:"\e981";
}
.zimbra-icon-install-as-app:before{
  content:"\e982";
}
.zimbra-icon-dashboard:before{
  content:"\e983";
}
.zimbra-icon-share:before{
  content:"\e984";
}
.zimbra-icon-arrow-up-thin:before{
  content:"\e985";
}
.zimbra-icon-arrow-right-thin:before{
  content:"\e986";
}
.zimbra-icon-arrow-down-thin:before{
  content:"\e987";
}
.zimbra-icon-arrow-left-thin:before{
  content:"\e992";
}
.zimbra-icon-collapse:before{
  content:"\e988";
}
.zimbra-icon-email-24px:before{
  content:"\e989";
}
.zimbra-icon-pin:before{
  content:"\e98a";
  color:#626262;
}
.zimbra-icon-pin-outline:before{
  content:"\e98b";
}
.zimbra-icon-preview-right:before{
  content:"\e98d";
}
.zimbra-icon-preview-bottom:before{
  content:"\e98e";
}
.zimbra-icon-preview-none:before{
  content:"\e98f";
}
.zimbra-icon-briefcase:before{
  content:"\e990";
}
.zimbra-icon-folder:before{
  content:"\e98c";
}
.zimbra-icon-integration:before{
  content:"\e993";
}
.zimbra-icon-up-to-parent:before{
  content:"\e994";
}
.zimbra-icon-notes:before{
  content:"\e995";
}
.zimbra-icon-zimbra-window-minimize:before{
  content:"\e996";
}
.zimbra-icon-zimbra-window-restore:before{
  content:"\e997";
}
.zimbra-icon-zimbra-make-current:before{
  content:"\e998";
}
@font-face{
  font-family:'Roboto';
  font-style:normal;
  font-weight:100;
  font-display:swap;
  src:local('Roboto Thin'), local('Roboto-Thin'), url(/fonts/roboto-v18-latin-100_1ozl-.woff2) format('woff2'), url(/fonts/roboto-v18-latin-100_sKaHJ.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:italic;
  font-weight:100;
  font-display:swap;
  src:local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/fonts/roboto-v18-latin-100italic_1BpB8.woff2) format('woff2'), url(/fonts/roboto-v18-latin-100italic_RYkBR.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:normal;
  font-weight:300;
  font-display:swap;
  src:local('Roboto Light'), local('Roboto-Light'), url(/fonts/roboto-v18-latin-300_7xYJx.woff2) format('woff2'), url(/fonts/roboto-v18-latin-300_3tpNl.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:italic;
  font-weight:300;
  font-display:swap;
  src:local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/fonts/roboto-v18-latin-300italic_3DgFl.woff2) format('woff2'), url(/fonts/roboto-v18-latin-300italic_1vumE.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:normal;
  font-weight:400;
  font-display:swap;
  src:local('Roboto'), local('Roboto-Regular'), url(/fonts/roboto-v18-latin-regular_2Msri.woff2) format('woff2'), url(/fonts/roboto-v18-latin-regular_LlKlE.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:italic;
  font-weight:400;
  font-display:swap;
  src:local('Roboto Italic'), local('Roboto-Italic'), url(/fonts/roboto-v18-latin-italic_3RZZ3.woff2) format('woff2'), url(/fonts/roboto-v18-latin-italic_1tfIx.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:normal;
  font-weight:500;
  font-display:swap;
  src:local('Roboto Medium'), local('Roboto-Medium'), url(/fonts/roboto-v18-latin-500_21GOL.woff2) format('woff2'), url(/fonts/roboto-v18-latin-500_3Lzss.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:italic;
  font-weight:500;
  font-display:swap;
  src:local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url(/fonts/roboto-v18-latin-500italic_3oIGR.woff2) format('woff2'), url(/fonts/roboto-v18-latin-500italic_ZUsyQ.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:normal;
  font-weight:700;
  font-display:swap;
  src:local('Roboto Bold'), local('Roboto-Bold'), url(/fonts/roboto-v18-latin-700_33elA.woff2) format('woff2'), url(/fonts/roboto-v18-latin-700_2Rya4.woff) format('woff');
}
@font-face{
  font-family:'Roboto';
  font-style:italic;
  font-weight:700;
  font-display:swap;
  src:local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/fonts/roboto-v18-latin-700italic_3x3tH.woff2) format('woff2'), url(/fonts/roboto-v18-latin-700italic_2Utvn.woff) format('woff');
}
@media (max-width: 480px){
  .zimbra-client_app-shell-loader_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_app-shell-loader_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_app-shell-loader_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_app-shell-loader_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_app-shell-loader_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_app-shell-loader_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_app-shell-loader_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_app-shell-loader_app{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  font-size:var(--font-size-base);
  contain:strict;
  overflow:hidden;
}
.zimbra-client_app-shell-loader_loading{
  position:absolute;
  box-sizing:border-box;
  left:50%;
  top:50%;
  padding:50px 0;
  width:220px;
  transform:translate(-50%, -50%);
  background:var(--gray-lightest);
  box-shadow:0 0 1px -1px #000, 0 0 1px -1px #000, 0 0 1px -1px #000;
  border-radius:5px;
  text-align:center;
}
@media (max-width: 480px){
  .zimbra-client_app-shell-loader_loading{
    width:80%;
  }
}
.zimbra-client_app-shell-loader_loading .zimbra-client_app-shell-loader_spinner{
  font-size:var(--font-size-h3);
  margin-bottom:15px;
  border-top-color:var(--gray-light);
}
.zimbra-client_app-shell-loader_loading .zimbra-client_app-shell-loader_text{
  color:var(--gray-light);
}
