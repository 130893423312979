@media (max-width: 480px){
  .zimbra-client_action-menu-group_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_action-menu-group_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_action-menu-group_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_action-menu-group_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu-group_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_action-menu-group_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_action-menu-group_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_action-menu-group_group{
  padding:var(--spacing-sm) 0;
  border-bottom:1px solid var(--gray-lighter);
}
.zimbra-client_action-menu-group_group:last-child{
  border-bottom:0;
}
