@media (max-width: 480px){
  .zimbra-client_modal-dialog_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_modal-dialog_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_modal-dialog_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_modal-dialog_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_modal-dialog_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_modal-dialog_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_modal-dialog_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_modal-dialog_dialog{
  display:block;
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  width:432px;
}
@media (max-width: 432px){
  .zimbra-client_modal-dialog_dialog{
    width:100%;
  }
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_inner{
  background:var(--body-bg);
  border-radius:3px;
  width:100%;
  height:100%;
  box-shadow:0 4px 6px rgba(0, 0, 0, 0.36);
  cursor:default;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header{
  padding:0;
  background:var(--off-white);
  border-bottom:1px solid var(--gray-lighter);
  color:var(--text-color);
  text-align:center;
  height:60px;
  border-bottom:none;
  background:none;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header h2{
  margin:0 90px 0 40px;
  padding:10px 0;
  color:var(--dialog-title-fg);
  font-weight:normal;
  font-size:120%;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header .zimbra-client_modal-dialog_actionButton{
  position:absolute;
  right:5px;
  top:5px;
  margin:0;
  z-index:1;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header .zimbra-client_modal-dialog_actionButton .zimbra-client_modal-dialog_icon{
  display:inline-block;
  position:relative;
  width:1.5em;
  height:1em;
  padding:0 0 0 5px;
  overflow:hidden;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header .zimbra-client_modal-dialog_closeButton{
  position:absolute;
  left:5px;
  top:5px;
  padding:7px;
  margin:0;
  background:var(--gray-light);
  border-radius:50%;
  vertical-align:middle;
  font-size:100%;
  font-weight:normal;
  color:white;
  cursor:pointer;
  z-index:1;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header h2{
  font-size:20px;
  margin:0;
  padding:22px 24px 0;
  text-align:left;
  color:var(--dialog-title-fg);
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_header .zimbra-client_modal-dialog_actionButton{
  top:16px;
  right:16px;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_content{
  position:relative;
  padding:0 var(--spacing-lg) var(--spacing-lg);
  margin:0;
  font-family:var(--font-family-sans-serif);
  font-size:var(--font-size-base);
  -webkit-font-smoothing:auto;
}
.zimbra-client_modal-dialog_dialog.zimbra-client_modal-dialog_scrollable{
  height:328px;
}
.zimbra-client_modal-dialog_dialog.zimbra-client_modal-dialog_hasFooter .zimbra-client_modal-dialog_content{
  padding-bottom:76px;
}
@media (max-height: 348px){
  .zimbra-client_modal-dialog_dialog,
  .zimbra-client_modal-dialog_dialog.zimbra-client_modal-dialog_scrollable{
    height:100%;
  }
}
.zimbra-client_modal-dialog_dialog.zimbra-client_modal-dialog_scrollable .zimbra-client_modal-dialog_content{
  position:absolute;
  left:0;
  width:100%;
  top:76px;
  bottom:76px;
  padding:0 24px;
  overflow:auto;
  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
}
@media (max-height: 328px){
  .zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_content{
    position:absolute;
    left:0;
    width:100%;
    top:76px;
    bottom:76px;
    padding:0 24px;
    overflow:auto;
    overflow-x:hidden;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
  }
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_spinner{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
}
.zimbra-client_modal-dialog_dialog [disabled]{
  opacity:0.5;
  pointer-events:none;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_footer{
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  height:76px;
  padding:20px 24px 24px;
}
.zimbra-client_modal-dialog_dialog .zimbra-client_modal-dialog_footer > :first-child{
  margin-left:0;
}
.zimbra-client_modal-dialog_content{
  margin:5px 4px;
  font-family:var(--font-family-sans-serif);
  font-size:var(--font-size-base);
}
.zimbra-client_modal-dialog_footer a[role="button"],
.zimbra-client_modal-dialog_footer button{
  margin:5px 4px;
  font-family:var(--font-family-sans-serif);
  font-size:var(--font-size-base);
}
button.zimbra-client_modal-dialog_button{
  min-width:92px;
}
