@media (max-width: 480px){
  .zimbra-client_default-home-page_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_default-home-page_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_default-home-page_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_default-home-page_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_default-home-page_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_default-home-page_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_default-home-page_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_default-home-page_wrapper{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:-1;
}
.zimbra-client_default-home-page_wrapper .zimbra-client_default-home-page_icon{
  font-size:120px;
  color:var(--gray-lighter);
}
.zimbra-client_default-home-page_heading{
  font-size:20px;
  margin-top:0;
}
.zimbra-client_default-home-page_ladingContentWrapper{
  padding:31px 40px;
}
.zimbra-client_default-home-page_ladingContentWrapper .zimbra-client_default-home-page_columnWrapper{
  display:flex;
  flex-wrap:wrap;
  margin-top:14px;
}
.zimbra-client_default-home-page_ladingContentWrapper .zimbra-client_default-home-page_columnWrapper .zimbra-client_default-home-page_columns{
  min-width:400px;
  padding-right:80px;
  margin-bottom:20px;
  width:50%;
}
.zimbra-client_default-home-page_ladingContentWrapper .zimbra-client_default-home-page_columnWrapper .zimbra-client_default-home-page_columns p{
  margin-top:0;
}
.zimbra-client_default-home-page_ladingContentWrapper .zimbra-client_default-home-page_columnWrapper .zimbra-client_default-home-page_columns:not(:first-child){
  margin-top:14px;
}
@media (max-width: 1024px){
  .zimbra-client_default-home-page_ladingContentWrapper .zimbra-client_default-home-page_columnWrapper .zimbra-client_default-home-page_columns{
    min-width:100%;
    padding:0;
  }
}
.zimbra-client_default-home-page_descriptionList{
  margin-left:20px;
}
