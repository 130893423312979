@media (max-width: 480px){
  .zimbra-client_text-input_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_text-input_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_text-input_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_text-input_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_text-input_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_text-input_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_text-input_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_text-input_input{
  padding:var(--spacing-sm) calc(var(--spacing-sm) + var(--spacing-xs));
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:3px;
  background-color:var(--body-bg);
  border:solid 1px var(--gray-lighter);
}
.zimbra-client_text-input_input.zimbra-client_text-input_wide{
  flex:1;
  width:100%;
}
.zimbra-client_text-input_input.zimbra-client_text-input_invalid{
  border:solid 1px var(--brand-danger-500) !important;
  box-shadow:0 0 4px 0 rgba(0, 0, 0, 0.3);
  outline:none;
}
.zimbra-client_text-input_input::-moz-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_text-input_input:-ms-input-placeholder{
  color:var(--placeholder-color);
}
.zimbra-client_text-input_input::placeholder,
.zimbra-client_text-input_input[disabled]{
  color:var(--placeholder-color);
}
.zimbra-client_text-input_input[disabled]{
  background-color:var(--gray-lightest);
}
