div.zimbra-client_settings_writing-email-settings_delegates_tokenInput[class]{
  height:32px;
  border:solid 1px var(--gray-lighter);
  border-radius:3px;
  margin:0;
  padding:0 var(--spacing-xs);
}
.zimbra-client_settings_writing-email-settings_delegates_checkboxes input{
  vertical-align:middle;
}
.zimbra-client_settings_writing-email-settings_delegates_checkboxes label{
  padding:0 0 0 var(--spacing-xs);
}
.zimbra-client_settings_writing-email-settings_delegates_listItem{
  align-items:center;
  display:flex;
  border-bottom:1px solid var(--gray-lighter);
  padding-bottom:5px;
  margin-right:var(--spacing-sm);
}
.zimbra-client_settings_writing-email-settings_delegates_listItem:last-child{
  margin-bottom:var(--spacing-md);
}
.zimbra-client_settings_writing-email-settings_delegates_listItem .zimbra-client_settings_writing-email-settings_delegates_contact{
  flex:1;
  font-size:var(--font-size-base);
  margin:0 var(--spacing-md) 0 var(--spacing-sm);
  overflow:hidden;
  white-space:nowrap;
}
.zimbra-client_settings_writing-email-settings_delegates_listItem .zimbra-client_settings_writing-email-settings_delegates_contact .zimbra-client_settings_writing-email-settings_delegates_name{
  font-weight:bold;
  color:var(--text-color);
}
.zimbra-client_settings_writing-email-settings_delegates_listItem .zimbra-client_settings_writing-email-settings_delegates_contact .zimbra-client_settings_writing-email-settings_delegates_address{
  color:var(--gray-light);
}
.zimbra-client_settings_writing-email-settings_delegates_listItem .zimbra-client_settings_writing-email-settings_delegates_contact .zimbra-client_settings_writing-email-settings_delegates_name,
.zimbra-client_settings_writing-email-settings_delegates_listItem .zimbra-client_settings_writing-email-settings_delegates_contact .zimbra-client_settings_writing-email-settings_delegates_address{
  overflow:hidden;
  text-overflow:ellipsis;
}
.zimbra-client_settings_writing-email-settings_delegates_avatar{
  width:36px;
  height:36px;
  background:var(--gray-lighter);
  text-align:center;
  color:white;
  font-size:var(--font-size-h3);
}
.zimbra-client_settings_writing-email-settings_delegates_addDelegates{
  display:block;
  margin:0 0 var(--spacing-sm) 0;
  color:var(--link-color);
}
