.zimbra-client_login_container{
  height:100%;
  background-color:var(--login-background-color);
  background-size:cover;
}
.zimbra-client_login_login{
  background-color:var(--login-inner-background-color);
  border-radius:2px;
  box-shadow:0 4px 6px 0 rgba(0, 0, 0, 0.4);
  margin:0 auto;
  padding:40px;
  position:relative;
  top:50%;
  width:340px;
  z-index:1;
}
.zimbra-client_login_login h1{
  font-size:20px;
}
.zimbra-client_login_container .zimbra-client_login_login{
  transform:translateY(-50%);
}
@media (max-width: 769px){
  .zimbra-client_login_container .zimbra-client_login_login{
    width:52%;
  }
}
@media (max-width: 480px){
  .zimbra-client_login_container .zimbra-client_login_login{
    width:95%;
  }
}
.zimbra-client_login_footer{
  position:absolute;
  bottom:10px;
  width:100%;
  color:white;
  text-align:center;
}
.zimbra-client_login_footer .markup{
  position:static !important;
}
.zimbra-client_login_footer h6,
.zimbra-client_login_footer small,
.zimbra-client_login_footer li{
  font-size:var(--font-size-base);
}
.zimbra-client_login_footer h6{
  margin:0 0 13px 0;
}
.zimbra-client_login_footer ol{
  color:rgba(255, 255, 255, 0.8);
  list-style:none;
  margin:0;
  padding:0;
}
.zimbra-client_login_footer ol li{
  display:inline-block;
}
.zimbra-client_login_footer ol li:not(:last-child):after{
  content:'|';
  padding:0 5px;
}
.zimbra-client_login_error{
  -webkit-animation:zimbra-client_login_errorIn 250ms ease forwards 1;
          animation:zimbra-client_login_errorIn 250ms ease forwards 1;
  background-color:var(--brand-danger-100);
  border-bottom:1px solid var(--brand-danger-500);
  color:var(--gray-base);
  display:none;
  margin:0 -40px;
  padding:10px 40px;
}
.zimbra-client_login_error .zimbra-client_login_icon{
  margin-right:10px;
}
.zimbra-client_login_error.zimbra-client_login_showing{
  display:block;
}
.zimbra-client_login_warning{
  -webkit-animation:zimbra-client_login_errorIn 250ms ease forwards 1;
          animation:zimbra-client_login_errorIn 250ms ease forwards 1;
  background-color:var(--brand-warning-100);
  border-bottom:1px solid var(--brand-warning-500);
  color:var(--gray-base);
  display:none;
  margin:0 -40px;
  padding:10px 40px;
}
.zimbra-client_login_warning .zimbra-client_login_icon{
  margin-right:10px;
}
.zimbra-client_login_warning.zimbra-client_login_showing{
  display:block;
}
.zimbra-client_login_form{
  display:block;
  margin:auto;
  max-width:300px;
}
.zimbra-client_login_form label{
  display:block;
  margin-top:20px;
  margin-left:1px;
}
.zimbra-client_login_form label.zimbra-client_login_invalid{
  color:var(--brand-danger-500);
}
.zimbra-client_login_form input{
  display:block;
  width:100%;
  padding:10px;
  font-size:100%;
  border:1px solid var(--gray-lighter);
  border-radius:2px;
  outline:none;
}
.zimbra-client_login_form input.zimbra-client_login_urlInput{
  background:none;
  padding:10px 0;
  color:var(--gray-darker);
  border:none;
}
.zimbra-client_login_form input:focus{
  border-color:var(--brand-primary-500);
}
.zimbra-client_login_form input.zimbra-client_login_invalid{
  border-color:var(--brand-danger-500);
}
@media (max-width: 480px){
  .zimbra-client_login_form input{
    font-size:var(--font-size-med);
  }
}
.zimbra-client_login_buttons{
  display:flex;
  margin-top:15px;
}
.zimbra-client_login_buttons:not(.zimbra-client_login_withCancel){
  justify-content:space-between;
}
.zimbra-client_login_buttons button{
  margin:0;
}
.zimbra-client_login_buttons.zimbra-client_login_withCancel{
  grid-row-gap:10px;
  row-gap:10px;
  flex-wrap:wrap;
}
.zimbra-client_login_buttons.zimbra-client_login_withCancel button{
  margin-right:5px;
}
div.zimbra-client_login_buttonsContainer button{
  display:inline-block;
}
div.zimbra-client_login_buttonsContainer button:first-child{
  margin-left:0;
}
div.zimbra-client_login_buttonsContainer button.zimbra-client_login_continue{
  margin-top:32px;
}
div.zimbra-client_login_forgotPassPopoverButton{
  display:flex;
  align-items:center;
}
.zimbra-client_login_forgotPassPopoverTitle{
  color:var(--link-color);
}
.zimbra-client_login_forgotPassPopover{
  max-width:275px;
  padding:20px;
}
.zimbra-client_login_forgotPassPopover h4{
  font-size:var(--font-size-base);
  margin:0;
}
.zimbra-client_login_forgotPassPopover p{
  margin:10px 0 0 0;
}
.zimbra-client_login_rules{
  list-style:none;
  padding:var(--spacing-md) 0 0;
}
.zimbra-client_login_rules li{
  padding-bottom:var(--spacing-xs);
  padding-left:20px;
  position:relative;
}
.zimbra-client_login_rules li span{
  color:var(--gray-lighter);
  position:absolute;
  left:0;
  top:0;
  width:20px;
  height:20px;
  line-height:20px;
  text-align:center;
  font-size:20px;
}
.zimbra-client_login_rules li span.zimbra-client_login_check{
  color:var(--brand-success-500);
}
.zimbra-client_login_rules li span[class*='spinner']{
  font-size:var(--font-size-base);
  top:3px;
}
.zimbra-client_login_fullArea{
  width:100%;
  height:100%;
}
.zimbra-client_login_fullArea .markup{
  width:100%;
  height:100%;
  position:fixed;
  left:0;
  top:0;
}
.zimbra-client_login_fullArea [zmLoginPanel]{
  transform:translateY(-50%);
}
.zimbra-client_login_inputContainer{
  padding:0 var(--spacing-lg) 0 10px;
  margin:var(--spacing-xs) 0 var(--spacing-md);
  border:1px solid var(--border-color);
  border-radius:2px;
  position:relative;
  display:flex;
}
.zimbra-client_login_inputContainer::before{
  content:"https://";
  padding:10px 1px;
}
.zimbra-client_login_inputContainer .zimbra-client_login_urlInput::-moz-placeholder{
  color:var(--gray-light);
}
.zimbra-client_login_inputContainer .zimbra-client_login_urlInput:-ms-input-placeholder{
  color:var(--gray-light);
}
.zimbra-client_login_inputContainer .zimbra-client_login_urlInput::placeholder{
  color:var(--gray-light);
}
.zimbra-client_login_inputContainer span[role="button"].zimbra-client_login_clear{
  padding:0;
  width:20px;
  height:20px;
  border-radius:50%;
  background:var(--gray-light);
  color:var(--text-color-light);
  outline:none;
  float:right;
  margin:6px 0 0;
  border:none;
  position:absolute;
  right:10px;
  top:3px;
  font-size:var(--font-size-med);
  line-height:18px;
  text-align:center;
  opacity:0;
}
.zimbra-client_login_inputContainer .zimbra-client_login_urlInput:focus + span[role="button"].zimbra-client_login_clear{
  opacity:1;
}
