.zimbra-client_help-tooltip_tooltipContainer{
  position:relative;
  margin:8px;
  display:inline-block;
}
.zimbra-client_help-tooltip_tooltipContainer:hover{
  cursor:pointer;
}
.zimbra-client_help-tooltip_tooltipContainer button{
  padding:0;
}
.zimbra-client_help-tooltip_tooltipContainer .zimbra-client_help-tooltip_tooltip{
  margin-right:15px;
  padding:17px 24px;
  word-wrap:break-word;
  min-width:200px;
  max-width:350px;
  cursor:default;
}
.zimbra-client_help-tooltip_tooltipContainer .zimbra-client_help-tooltip_tooltip *{
  width:auto;
}
.zimbra-client_help-tooltip_close{
  position:absolute;
  top:6px;
  right:6px;
}
