@media (max-width: 480px){
  .zimbra-client_folder-input_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_folder-input_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_folder-input_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_folder-input_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder-input_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_folder-input_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_folder-input_hideMdUp{
    display:none !important;
  }
}
button.zimbra-client_folder-input_folderInputClose,
.zimbra-client_folder-input_folderInputIcon{
  color:var(--gray-light);
  padding:4px;
}
button.zimbra-client_folder-input_folderInputClose{
  color:var(--gray-light);
  padding:2px 6px;
  flex-shrink:0;
}
.zimbra-client_folder-input_folderInputContainer{
  display:flex;
}
.zimbra-client_folder-input_folderInput{
  min-width:0;
  flex:1;
  padding:0 4px;
  line-height:22px;
  font-family:var(--branding-font-family-regular);
  border-radius:2px;
  outline:none;
  border:1px solid var(--brand-primary-500);
  box-shadow:0 0 4px 0 rgba(0, 0, 0, 0.3);
}
.zimbra-client_folder-input_folderInputWithIcon{
  padding-left:22px;
}
.zimbra-client_folder-input_folderInputIcon{
  position:absolute;
  padding:4px 0 0 4px;
  height:24px;
  line-height:24px;
}
