@media (max-width: 480px){
  .zimbra-client_search-input_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_search-input_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_search-input_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_search-input_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_search-input_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_search-input_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_search-input_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_search-input_container{
  flex:1;
  position:relative;
}
@media (min-width: 1025px){
  .zimbra-client_search-input_container.zimbra-client_search-input_showAdvanced{
    height:40px;
  }
  .zimbra-client_search-input_container.zimbra-client_search-input_showAdvanced .zimbra-client_search-input_form{
    width:600px;
    position:absolute;
    right:0;
  }
}
.zimbra-client_search-input_form{
  height:100%;
  display:flex;
  align-items:center;
  border:1px solid var(--gray-lighter);
  background-color:var(--body-bg);
  position:relative;
}
@media (min-width: 1025px){
  .zimbra-client_search-input_form{
    border-radius:4px;
    z-index:1;
  }
  .zimbra-client_search-input_form.zimbra-client_search-input_focus{
    border-color:var(--brand-primary-500);
  }
}
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions{
  padding:0 var(--spacing-sm);
  border:0;
  margin:0;
  background:none;
  color:var(--gray-darkest);
  align-self:stretch;
}
@media (max-width: 1024px){
  .zimbra-client_search-input_form button.zimbra-client_search-input_searchActions{
    padding:0 0 0 14px;
  }
}
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions.zimbra-client_search-input_activeButton{
  color:var(--brand-primary-500);
}
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions.zimbra-client_search-input_toggleAdvance{
  color:var(--gray-light);
  margin-right:var(--spacing-xs);
}
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions.zimbra-client_search-input_clearBtn{
  padding-right:0;
}
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions.zimbra-client_search-input_hide{
  visibility:hidden;
}
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions:hover,
.zimbra-client_search-input_form button.zimbra-client_search-input_searchActions:focus{
  background:none;
}
.zimbra-client_search-input_input{
  flex:1;
  height:38px;
  border:0;
  border-radius:2px;
  outline:none;
}
.zimbra-client_search-input_input::-moz-placeholder{
  color:var(--gray-light);
}
.zimbra-client_search-input_input:-ms-input-placeholder{
  color:var(--gray-light);
}
.zimbra-client_search-input_input::placeholder{
  color:var(--gray-light);
}
@media (max-width: 1024px){
  .zimbra-client_search-input_input{
    padding-left:11px;
  }
}
.zimbra-client_search-input_suggestions{
  position:absolute;
  z-index:11;
  top:100%;
  width:100%;
  background-color:#fff;
  color:var(--text-color);
  box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.5);
}
.zimbra-client_search-input_contactSuggestion:hover{
  background-color:var(--gray-lightest);
}
.zimbra-client_search-input_contactSuggestion .zimbra-client_search-input_contactSuggestionName{
  font-weight:normal;
}
.zimbra-client_search-input_contactSuggestion strong{
  font-weight:bold;
}
.zimbra-client_search-input_contactSuggestion.zimbra-client_search-input_contactSuggestionSelected{
  background-color:var(--list-active-bg);
}
.zimbra-client_search-input_contactTag{
  display:flex;
  align-items:center;
  justify-content:space-between;
  color:var(--gray-dark);
  border-radius:56px;
  height:30px;
  padding:4px;
  margin-left:4px;
  background-color:var(--gray-lighter);
}
.zimbra-client_search-input_contactTag.zimbra-client_search-input_focused{
  background-color:var(--list-active-bg);
}
@media (max-width: 320px){
  .zimbra-client_search-input_contactTag{
    margin-left:-1px;
    padding:1px 1px 1px 0px;
  }
}
.zimbra-client_search-input_contactTagName{
  padding:2px 8px;
  justify-content:left;
  display:inline-block;
  height:22px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width:150px;
}
@media (max-width: 1300px) and (max-width: 769px){
  .zimbra-client_search-input_contactTagName{
    max-width:150px;
  }
}
@media (max-width: 480px){
  .zimbra-client_search-input_contactTagName{
    max-width:45px;
  }
}
.zimbra-client_search-input_contactTagAvatar{
  width:24px;
  height:24px;
}
.zimbra-client_search-input_contactDropdown{
  padding:0 var(--spacing-md);
  color:var(--gray-light);
  height:38px;
  border-right:1px solid var(--gray-lighter);
}
