.zimbra-client_aligned-form_alignedForm{
  display:flex;
  flex-direction:column;
  align-items:center;
}
.zimbra-client_aligned-form_alignedLabel{
  display:block;
  text-align:right;
  padding-right:8px;
  padding-bottom:0;
}
.zimbra-client_aligned-form_alignedLabel.zimbra-client_aligned-form_right{
  text-align:right;
}
.zimbra-client_aligned-form_alignedLabel.zimbra-client_aligned-form_left{
  text-align:left;
}
