.blocks_card_card{
  background:transparent;
  border:none;
  cursor:pointer;
  padding:0;
  position:relative;
  overflow:hidden;
}
button.blocks_card_card{
  text-align:left;
  text-align:start;
}
.blocks_card_card > img{
  border:solid 1px var(--gray-darkest);
  max-width:100%;
}
.blocks_card_card.blocks_card_square:before{
  content:'';
  float:left;
  padding-top:100%;
}
.blocks_card_card.blocks_card_square > img{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  min-height:100%;
}
