@media (max-width: 480px){
  .zimbra-client_settings_add-more_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_settings_add-more_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_settings_add-more_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_settings_add-more_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_add-more_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_settings_add-more_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_settings_add-more_hideMdUp{
    display:none !important;
  }
}
button.zimbra-client_settings_add-more_addMore{
  padding:0 !important;
  margin-top:calc(var(--spacing-sm) + var(--spacing-xs));
}
button.zimbra-client_settings_add-more_addMore:disabled{
  opacity:0.37;
}
