@media (max-width: 480px){
  .zimbra-client_language-modal_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_language-modal_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_language-modal_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_language-modal_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_language-modal_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_language-modal_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_language-modal_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageModalContent{
  margin:var(--spacing-xs) 0 0;
  padding-left:0;
  padding-right:0;
}
.zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList{
  overflow:auto;
  padding:0 var(--spacing-lg);
  list-style:none;
  flex:1;
}
@media (min-width: 1025px){
  .zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList{
    max-height:285px;
    border-top:1px solid var(--gray-lightest);
    border-bottom:1px solid var(--gray-lightest);
  }
  .zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList .zimbra-client_language-modal_loaderWrapper{
    height:100px;
  }
  .zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList .zimbra-client_language-modal_labelText{
    text-transform:capitalize;
  }
  .zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList .zimbra-client_language-modal_labelTextBeta:after{
    content:"BETA";
    position:relative;
    right:0;
    top:-8px;
    color:red;
    font-size:8px;
  }
}
.zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList li{
  padding:var(--spacing-xs) 0;
}
.zimbra-client_language-modal_languageModal .zimbra-client_language-modal_languageList li label{
  cursor:pointer;
}
