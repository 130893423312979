@media (max-width: 480px){
  .zimbra-client_external-header_hideBelowXs{
    display:none !important;
  }
}
@media (max-width: 768px){
  .zimbra-client_external-header_hideXsDown{
    display:none !important;
  }
}
@media (min-width: 480px){
  .zimbra-client_external-header_hideXsUp{
    display:none !important;
  }
}
@media (min-width: 769px){
  .zimbra-client_external-header_hideSmUp{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_external-header_hideSmDown{
    display:none !important;
  }
}
@media (max-width: 1024px){
  .zimbra-client_external-header_hideMdDown{
    display:none !important;
  }
}
@media (min-width: 1025px){
  .zimbra-client_external-header_hideMdUp{
    display:none !important;
  }
}
.zimbra-client_external-header_externalHeader{
  background-color:var(--external-header-bg);
}
.zimbra-client_external-header_externalLogo{
  display:inline-block;
  margin-right:0.4rem;
  position:relative;
  top:-1px;
}
.zimbra-client_external-header_externalLogo img{
  height:2em;
}
.zimbra-client_external-header_externalHeaderRight{
  float:right;
}
